import ListIcon from '@material-ui/icons/List';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DevicesIcon from '@material-ui/icons/Devices';

interface SolicitationItemList {
  id: string;
  title: string;
  icon: any;
}

export const solicitationsList: SolicitationItemList[] = [
  {
    id: 'solicitated',
    title: 'Solicitações de Atendimento',
    icon: <ListIcon />,
  },
  {
    id: 'ongoing',
    title: 'Solicitações em Atendimento',
    icon: <DevicesIcon />,
  },
  {
    id: 'forwarded',
    title: 'Solicitações Encaminhadas',
    icon: <PhoneForwardedIcon />,
  },
  {
    id: 'finalized',
    title: 'Solicitações Finalizadas',
    icon: <PlaylistAddCheckIcon />,
  },
];
