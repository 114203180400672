export default function maskNumber(val: string, pattern: string) {
  if (!val || !pattern) {
    return '';
  }

  const numbers = val.replace(/[^\d]/g, '');
  const formatted = [];

  for (let v = 0, p = 0; v < numbers.length && p < pattern.length; p++) {
    formatted.push(pattern[p] === '#' ? numbers[v++] : pattern[p]);
  }

  return formatted.join('');
}
