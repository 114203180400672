import axios, { AxiosResponse } from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import { alphabeticalCompare } from '../../utils/sort';
import headers from './headers';

interface MunicipioResponse {
  [x: string]: string;
}

interface Municipio {
  id: number;
  name: string;
}

export default function useMunicipios() {
  const [municipios, setMunicipios] = useState<Municipio[]>([]);
  const [loading, setLoading] = useState(false);
  const message = useRef(useMessage());
  const [uf, setUf] = useState('');

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (uf) {
      (async () => {
        try {
          setLoading(true);
          const response: AxiosResponse<MunicipioResponse> = await axiosInstance(
            API_ROUTES.PROXY,
            {
              cancelToken: source.token,
              headers,
              params: {
                url: `${API_ROUTES.CNES_MUNICIPIOS}?estado=${uf}`,
              },
            },
          );

          const municipios: Municipio[] = Object.entries(response.data).map(
            (municipio) => ({
              id: +municipio[0],
              name: municipio[1],
            }),
          );

          const sorted = municipios.sort((a, b) =>
            alphabeticalCompare(Object.values(a)[1], Object.values(b)[1]),
          );

          setMunicipios(sorted);
          setLoading(false);
        } catch (error: any) {
          if (axios.isCancel(error)) {
            return;
          }

          setLoading(false);

          message.current.setMessage({
            type: 'error',
            text:
              'Ocorreu um erro ao carregar ao buscar os municipios do CNES.',
          });
        }
      })();
    }

    return () => {
      source.cancel();
    };
  }, [uf]);

  return { municipios, setUf, loading };
}
