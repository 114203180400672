import { lazy, Suspense } from 'react';

/**
 * This component was wrapped because it needs to be lazy-loaded to prevent Agora from running in other pages
 * This removes the Microphone/Camera check when the website loads
 */
function VideoCallWrapper() {
  const VideoCall = lazy(() => import('./VideoCall'));

  return (
    <Suspense fallback={null}>
      <VideoCall />
    </Suspense>
  );
}

export default VideoCallWrapper;
