import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import LockIcon from '@material-ui/icons/Lock';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import Solicitation from '../utils/solicitation.interface';
import FileInput from '../../../components/FileInput';
import { useStyles } from './prescribeModal.styles';
import downloadBlob from '../../../utils/downloadBlob';
import axios from 'axios';
import { useAuth } from '../../../context/auth';

interface PrescribeModalProps {
  open: boolean;
  solicitation: Solicitation;
  handleClose: () => void;
  handleSuccess: () => void;
}

interface Establishment {
  id: number;
  nome_fanta: string;
}

function PrescribeModal(props: PrescribeModalProps) {
  const [prescription, setPrescription] = useState('');
  const [certPass, setCertPass] = useState('');
  const [signPdf, setSignPdf] = useState(1);
  const [cert, setCert] = useState<File | null>(null);
  const [prescPdf, setPrescPdf] = useState<File | null>(null);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState('');

  const auth = useAuth();
  const message = useRef(useMessage());
  const classes = useStyles();

  function validateFields() {
    if (signPdf) {
      if (!prescription.trim()) {
        throw new Error('Preencha o campo de prescrição.');
      }

      if (selectedEstablishment === '') {
        throw new Error('Selecione o local de atendimento');
      }

      if (!cert) {
        throw new Error('Selecione o certificado.');
      }

      if (!certPass) {
        throw new Error('Digite a senha do certificado');
      }
    } else if (!prescPdf) {
      throw new Error('Selecione uma receita');
    }

    return true;
  }

  async function getSignedPdf(): Promise<Blob> {
    const formData = new FormData();

    formData.append('solicitation', props.solicitation.id.toString());
    formData.append('establishment', selectedEstablishment);
    formData.append('prescription', prescription);
    formData.append('certificate_password', certPass);
    formData.append('certificate', cert!);

    const response = await axiosInstance.post(
      API_ROUTES.SIGN_PRESCRIPTION,
      formData,
      { responseType: 'blob' },
    );

    return response.data;
  }

  async function handleSubmit() {
    try {
      validateFields();

      const pdf = signPdf
        ? new File([await getSignedPdf()], 'receita.pdf')
        : prescPdf;

      const formData = new FormData();

      formData.append('solicitation', props.solicitation.id.toString());
      formData.append('file', pdf!);

      await axiosInstance.post(API_ROUTES.SOLICITATIONS_PRESCRIBE, formData);

      message.current.setMessage({
        type: 'success',
        text: 'Prescrição finalizada com sucesso',
      });

      props.handleSuccess();
    } catch (error: any) {
      let msg = '';

      // The error from getSignedPdf() returns a response with type application/json
      try {
        if (error.response?.data?.type === 'application/json') {
          msg = JSON.parse(await error.response.data.text()).message;
        }
      } catch (error) {}

      message.current.setMessage({
        type: 'error',
        text:
          msg ||
          error.response?.data?.message ||
          'Ocorreu um erro ao finalizar a prescrição',
      });
    }
  }

  async function downloadPrescription() {
    try {
      validateFields();

      const blob = await getSignedPdf();
      downloadBlob(blob, 'receita.pdf');
    } catch (error: any) {
      let msg = 'Ocorreu um erro ao gerar a prescrição';

      // The error from getSignedPdf() returns a response with type application/json
      try {
        if (error.response?.data?.type === 'application/json') {
          msg = JSON.parse(await error.response.data.text()).message || msg;
        }
      } catch (error) {}

      message.current.setMessage({
        type: 'error',
        text: msg,
      });
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!props.open) {
      return;
    }

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.ESTABLISHMENTS, {
          cancelToken: source.token,
          params: {
            profissional: auth.user?.association?.id,
          },
        });

        setEstablishments(response.data);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.message ||
            'Ocorreu um erro ao carregar a lista de estabelecimentos.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [auth.user?.association, props.open]);

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h6">Prescrição</Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <Select
          variant="outlined"
          className={classes.prescriptionTypeSelect}
          value={signPdf}
          onChange={(e) => {
            setSignPdf(e.target.value as number);
          }}
        >
          <MenuItem value={1}>Gerar uma receita assinada</MenuItem>
          <MenuItem value={0}>Selecionar uma receita assinada</MenuItem>
        </Select>

        {signPdf ? (
          <>
            <TextField
              variant="outlined"
              label="Digite os medicamentos a serem receitados"
              rows={5}
              value={prescription}
              onChange={(e) => setPrescription(e.target.value)}
              multiline
              fullWidth
            />

            <FormControl variant="outlined">
              <InputLabel>Local de Atendimento</InputLabel>
              <Select
                label="Local de Atendimento"
                value={selectedEstablishment}
                onChange={(e) => {
                  setSelectedEstablishment(e.target.value as string);
                }}
              >
                <MenuItem value="">Selecione o Local de Atendimento</MenuItem>
                {establishments.map((establishment) => (
                  <MenuItem
                    key={establishment.id}
                    value={establishment.id.toString()}
                  >
                    {establishment.nome_fanta}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FileInput
              label="Selecione o certificado"
              accept=".p12,.pfx"
              file={cert}
              setFile={setCert}
            />

            <TextField
              variant="outlined"
              label="Digite a senha do certificado"
              type="password"
              value={certPass}
              fullWidth
              onChange={(e) => setCertPass(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />

            <div className={classes.buttonsContainer}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<GetAppIcon />}
                onClick={downloadPrescription}
              >
                Baixar receita
              </Button>
            </div>
          </>
        ) : (
          <FileInput
            label="Selecione a receita"
            accept=".pdf"
            file={prescPdf}
            setFile={setPrescPdf}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>

        <Button color="primary" onClick={handleSubmit}>
          Prescrever
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PrescribeModal;
