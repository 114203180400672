import {
  gestantes,
  gestantesOdonto,
  gestantesSifHiv,
  exameCito,
  percentualDiabete,
  percentualHipertensa,
  vacinaPolPen,
} from './indicators';

export interface Indicator {
  title: string;
  tableData: {
    title: string;
    key: string;
    transform?: (value: any) => any;
  }[];
}

export function floatToInt(value: number | string) {
  return Math.floor(+value);
}

export function intToSimOrNao(value: number) {
  return value === 0 ? 'NAO' : 'SIM';
}

const indicatorsList: Indicator[] = [
  gestantes,
  gestantesSifHiv,
  gestantesOdonto,
  exameCito,
  vacinaPolPen,
  percentualHipertensa,
  percentualDiabete,
];

export default indicatorsList;
