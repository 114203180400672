export interface AccountProps {
  open: boolean;
  setOpenAccountDialog: (open: boolean) => void;
}

export interface FieldsError {
  name: string;
  birth_date: string;
  gender: string;
  email: string;
  phone: string;
  neighborhood: string;
  state: string;
}

export function getClearFieldsErrorState(): FieldsError {
  return {
    name: '',
    birth_date: '',
    gender: '',
    email: '',
    phone: '',
    neighborhood: '',
    state: '',
  };
}
