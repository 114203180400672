import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../../api';
import { useMessage } from '../../../../../context/message';
import useCsvExporter from '../../../../../hooks/useCsvExporter';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { convertDateFromISO8601ToPtBR } from '../../../../../utils/date_converter';
import CitizenRow from './CitizenRow';
import TablePaginationActions from 'components/TablePaginationActions';

const rowsPerPage = 15;

interface DuplicatedCitizen {
  total: number;
  no_cidadao__unaccent: string;
  no_mae__unaccent: string | null;
  dt_nascimento: string | null;
}

export interface DuplicatedCitizenFilter {
  name: boolean;
  mother: boolean;
  birthDate: boolean;
}

function DuplicatedCitizens() {
  const message = useRef(useMessage());
  const exporter = useCsvExporter();
  const source = useRef<CancelTokenSource | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [columns, setColumns] = useState<string[]>(['Total', 'Nome']);
  const [rows, setRows] = useState<string[][]>([]);
  const [loading, setLoading] = useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [nameFilter, setNameFilter] = useState('');
  const [filters, setFilters] = useState<DuplicatedCitizenFilter>({
    name: true,
    mother: false,
    birthDate: false,
  });
  const [usedFilters, setUsedFilters] = useState<DuplicatedCitizenFilter>(
    filters,
  );

  function getFilterString(filters: DuplicatedCitizenFilter) {
    return [
      filters.name && 'no_cidadao__unaccent',
      filters.mother && 'no_mae__unaccent',
      filters.birthDate && 'dt_nascimento',
    ]
      .filter(Boolean)
      .join(',');
  }

  async function exportToCsv() {
    exporter.exportToCsv(
      API_ROUTES.CSV_EXPORTER_CITIZENS_DUPLICATED,
      'cidadaos_duplicados.csv',
      {
        filter: getFilterString(filters),
        no_cidadao__icontains: nameFilter || undefined,
      },
    );
  }

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    setFilters((filters) => ({
      ...filters,
      [e.target.value]: e.target.checked,
    }));
  }

  const fetchDuplicatedCitizens = useCallback(async () => {
    source.current?.cancel();
    source.current = axios.CancelToken.source();

    setRows([]);
    setColumns([]);
    setLoading(true);
    setInitialLoad(false);
    setUsedFilters(filters);

    try {
      const res = await axiosInstance.get(API_ROUTES.ESUS_CITIZENS_DUPLICATED, {
        cancelToken: source.current!.token,
        params: {
          page: page + 1,
          page_size: rowsPerPage,
          filter: getFilterString(filters),
          no_cidadao__unaccent__icontains: nameFilter || undefined,
        },
      });

      const results: DuplicatedCitizen[] = res.data.results;
      if (filters.birthDate) {
        results.forEach((row) => {
          row.dt_nascimento = convertDateFromISO8601ToPtBR(row.dt_nascimento!);
        });
      }

      const cols =
        results.length > 0
          ? [
              ...Object.keys(results[0])
                .filter((col) =>
                  ['total', ...getFilterString(filters).split(',')].includes(
                    col,
                  ),
                )
                .map((col) =>
                  col
                    .replace('total', 'Total')
                    .replace('no_cidadao__unaccent', 'Nome')
                    .replace('dt_nascimento', 'Data de nascimento')
                    .replace('no_mae__unaccent', 'Nome da mãe'),
                ),
              'Detalhes',
            ]
          : [];

      const rows: string[][] = results
        .map((r) => Object.values(r))
        .map((r) => r.filter((val) => val !== null));

      setColumns(cols);
      setRows(rows);
      setCount(res.data.count);
      setLoading(false);
      setCanFetch(false);
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      }

      setRows([]);
      setLoading(false);
      setCanFetch(false);

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.message ||
          'Ocorreu um erro ao carregar os cidadões.',
      });
    }
  }, [nameFilter, filters, page]);

  useEffect(() => {
    if (canFetch) {
      fetchDuplicatedCitizens();
    }
  }, [canFetch, fetchDuplicatedCitizens]);

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  return (
    <>
      <TextField
        variant="outlined"
        label="Nome"
        style={{ marginRight: 16, marginBottom: 16 }}
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        disabled={loading}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Button
        style={{
          height: 56,
          marginRight: 16,
          marginBottom: 16,
        }}
        color="primary"
        variant="outlined"
        disabled={loading}
        startIcon={<SearchIcon />}
        onClick={() => {
          setPage(0);
          setCanFetch(true);
        }}
      >
        Pesquisar
      </Button>

      <Button
        style={{
          height: 56,
          marginBottom: 16,
        }}
        color="primary"
        variant="outlined"
        startIcon={
          exporter.isExporting ? (
            <CircularProgress size={14} disableShrink />
          ) : (
            <GetAppIcon />
          )
        }
        onClick={exportToCsv}
        disabled={exporter.isExporting}
      >
        Exportar
      </Button>

      <FormControl
        style={{
          width: '100%',
          marginBottom: 16,
        }}
      >
        <FormLabel focused={false}>Selecione as colunas</FormLabel>
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={filters.birthDate}
                value="birthDate"
                onChange={handleCheckboxChange}
                disabled={loading}
              />
            }
            label="Data de nascimento"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={filters.mother}
                value="mother"
                onChange={handleCheckboxChange}
                disabled={loading}
              />
            }
            label="Nome da mãe"
          />
        </FormGroup>
      </FormControl>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 360px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col, idx) => (
                <TableCell
                  key={idx}
                  align="center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <CitizenRow key={idx} data={row} filters={usedFilters} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(loading || rows.length === 0) && (
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            !initialLoad && 'Nenhum cidadão encontrado'
          )}
        </div>
      )}
      <TablePagination
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
          setCanFetch(true);
        }}
      />
    </>
  );
}

export default DuplicatedCitizens;
