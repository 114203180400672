import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  prescriptionTypeSelect: {
    marginBottom: theme.spacing(1),
  },
  pdfContainer: {
    width: '100%',
  },
  pdfViewer: {
    width: '100%',
    border: 0,
    height: '500px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
