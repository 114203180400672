import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { ImportedOnlineEstablishment } from '../importedEstablishment.interface';
import { CenteredCell } from '../ImportedEstablishments';

interface Props {
  establishments: ImportedOnlineEstablishment[];
}

function OnlineEstablishments(props: Props) {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 'calc(100vh - 280px)' }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <CenteredCell>CNES</CenteredCell>
            <CenteredCell>Nome Fantasia</CenteredCell>
            <CenteredCell>CEP</CenteredCell>
            <CenteredCell>Telefone 1</CenteredCell>
            <CenteredCell>Telefone 2</CenteredCell>
            <CenteredCell>E-mail</CenteredCell>
            <CenteredCell>CNPJ</CenteredCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.establishments.map((establishment) => (
            <TableRow key={establishment.id}>
              <CenteredCell>{establishment.cnes}</CenteredCell>
              <CenteredCell>{establishment.nm_fanta}</CenteredCell>
              <CenteredCell>{establishment.co_cep}</CenteredCell>
              <CenteredCell>{establishment.telefone1}</CenteredCell>
              <CenteredCell>{establishment.telefone2}</CenteredCell>
              <CenteredCell>{establishment.e_mail}</CenteredCell>
              <CenteredCell>{establishment.cnpj}</CenteredCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OnlineEstablishments;
