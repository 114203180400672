import { useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import PageHeader from '../../../components/PageHeader';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { useAuth } from '../../../context/auth';
import { useStyles } from './informativeText.styles';

const MAX_SIZE = 2000;

function InformativeText() {
  const message = useMessage();
  const auth = useAuth();
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [informativeText, setInformativeText] = useState(
    auth.user?.profile.living_city.informative_text || '',
  );

  const isValid = informativeText.length <= 2000;

  //TODO cancel token
  async function saveText() {
    try {
      setIsSaving(true);
      const response = await axiosInstance.put(API_ROUTES.CITY_UPDATE_DETAILS, {
        informative_text: informativeText,
      });

      await auth.refreshProfile();
      setInformativeText(response.data?.informative_text || '');

      message.setMessage({
        type: 'success',
        text: 'Descrição atualizada com sucesso',
      });
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          error.response?.data?.informative_text ||
          'Ocorreu um erro ao atualizar a descrição',
      });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <PageHeader title="Descreva a Rede de Saúde do seu Município" />

      <div className={classes.textFieldContainer}>
        <TextField
          value={informativeText}
          onChange={(e) => setInformativeText(e.target.value)}
          fullWidth
          multiline
          disabled={isSaving}
          rows={18}
          classes={{
            root: classes.textFieldRoot,
          }}
          error={!isValid}
          variant="outlined"
          placeholder="Descreva a Rede de Saúde do seu Município"
        />
        <Typography
          className={[
            classes.charCountLabel,
            !isValid ? classes.charCountLabelError : '',
          ].join(' ')}
        >
          {`${informativeText.length}/${MAX_SIZE}`}
        </Typography>
      </div>

      <Button
        variant="contained"
        color="primary"
        disabled={
          isSaving ||
          !isValid ||
          auth.user?.profile.living_city.informative_text === informativeText
        }
        className={classes.saveBtn}
        onClick={saveText}
      >
        Salvar
      </Button>
    </>
  );
}

export default InformativeText;
