import { Drawer as MuiDrawer, Divider, List } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import { useAuth } from '../../../context/auth';
import { privateRoutes, userCanAccessRoute } from '../../Routes';
import PageLink from './Links/PageLink';
import NestedPageLink from './Links/NestedPageLink';
import Logo from '../../Logo';
import { Fragment } from 'react';

function Drawer(props: { open: boolean; setOpen: (val: boolean) => void }) {
  const classes = useStyles();
  const auth = useAuth();

  const pages = privateRoutes.filter(
    (page) =>
      (page.separator || (page.title && page.icon)) &&
      userCanAccessRoute(auth.user, page)
  );

  return (
    <MuiDrawer
      variant="permanent"
      style={{ height: '100vh' }}
      classes={{
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerClose]: !props.open,
        }),
      }}
    >
      <div className={classes.appBarSpacer} />
      <List>
        {pages.map((page) => {
          const nest = (page.nestedPages || []).filter(
            (page) =>
              page.title &&
              page.icon &&
              userCanAccessRoute(auth.user, page)
          );

          if (nest.length > 0) {
            return (
              <Fragment key={page.title}>
                {page.separator && <Divider />}
                <NestedPageLink
                  key={page.title}
                  drawerOpen={props.open}
                  page={page}
                  nest={nest}
                  setDrawerOpen={props.setOpen}
                />
              </Fragment>
            );
          }

          return (
            <Fragment key={page.title}>
              {page.separator && <Divider />}
              <PageLink page={page} setDrawerOpen={props.setOpen} />
            </Fragment>
          );
        })}
      </List>
      <Divider />
      {auth.user?.profile.living_city.logo_website && (
        <>
          <div className={classes.cityLogoSpacer} />
          <div className={classes.cityLogoContainer}>
            <Logo mode="small" className={classes.cityLogo} />
          </div>
        </>
      )}
    </MuiDrawer>
  );
}

export default Drawer;
