export function convertDateFromISO8601ToPtBR(date: string): string {
  return date ? date.split('-').reverse().join('/') : '';
}

export function convertTimestamp(timestamp: string) {
  try {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const p1 = [day, month, year].join('/');
    const p2 = [hour, minutes, seconds].join(':');

    return [p2, p1].join(' ');
  } catch (error: any) {
    return '';
  }
}

export function convertDateFromPtBRToISO8601(date: string | null): string {
  return date ? date.split('/').reverse().join('-') : '';
}
