import { Professional } from '../../../../hooks/useProfessionals';
import { Team } from '../../../../hooks/useTeams';
import AttendancesTypes from './attendanceTypes';

export default function getFilters(
  competence: string,
  attendanceType: AttendancesTypes,
  profName: string,
  team: Team | null,
  professional: Professional | null,
) {
  const params: any = {
    competence: competence || undefined,
  };

  if (attendanceType === AttendancesTypes.Procedures) {
    params.professional = professional?.co_seq_dim_profissional;
    params.team = team?.co_seq_dim_equipe;
  }

  if (attendanceType === AttendancesTypes.Consolidated) {
    params.team = team?.co_seq_dim_equipe;
  }

  if (attendanceType === AttendancesTypes.DaysWorked) {
    params.no_profissional__icontains = profName || undefined;
  }

  return params;
}
