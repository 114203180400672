import {
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance, API_ROUTES } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { useMessage } from '../../../context/message';
import useDebounce from '../../../hooks/useDebounce';
import {
  formatCompetence,
  getCurrentCompetence,
} from '../../../utils/competence';
import maskNumber from '../../../utils/maskNumber';
import Procedure from './procedure.interface';
import ProceduresDetails from './ProcedureDetails';

const rowsPerPage = 15;

function Procedures() {
  const [competence, setCompetence] = useState(getCurrentCompetence());
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [selectedProc, setSelectedProc] = useState<Procedure | null>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const debouncedFilter = useDebounce(searchFilter, 500);

  const message = useRef(useMessage());

  useEffect(() => {
    const comp = competence.split('/').reverse().join('').replace(/[\D]/g, '');

    if (comp.length > 0 && comp.length !== 6) {
      return;
    }

    const source = axios.CancelToken.source();
    setLoading(true);
    setProcedures([]);

    (async () => {
      try {
        const filter = debouncedFilter.trim();
        const strippedFilter = filter.replace(/[.-]/g, '');
        const isCod = !isNaN(+strippedFilter);

        const response = await axiosInstance.get(
          API_ROUTES.IMPORTS_SIGTAP_PROCEDURES,
          {
            cancelToken: source.token,
            params: {
              page: page + 1,
              competence: comp || undefined,
              [isCod ? 'co_procedimento' : 'no_procedimento__icontains']:
                (isCod ? strippedFilter : filter) || undefined,
            },
          },
        );

        setProcedures(response.data.results);
        setCount(response.data.count);

        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.message ||
            'Ocorreu um erro ao carregar a lista de procedimentos',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, competence, debouncedFilter]);

  return (
    <>
      <ProceduresDetails
        procedure={selectedProc}
        close={() => setSelectedProc(null)}
      />

      <PageHeader title="Procedimentos" />

      <div style={{ marginBottom: '24px', display: 'flex', gap: '8px' }}>
        <TextField
          variant="outlined"
          label="Competência"
          placeholder="mm/aaaa"
          style={{ width: 180 }}
          value={competence}
          onChange={(e) => {
            setPage(0);
            setCompetence(maskNumber(e.target.value, '##/####'));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          label="Procedimento"
          placeholder="Filtrar por Código/Nome"
          value={searchFilter}
          onChange={(e) => {
            setPage(0);
            setSearchFilter(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Cod. Procedimento</TableCell>
              <TableCell>Nome do Procedimento</TableCell>
              <TableCell align="center">Competência</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procedures.map((procedure) => (
              <TableRow key={procedure.co_procedimento}>
                <TableCell
                  align="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedProc(procedure)}
                >
                  <SearchIcon />
                </TableCell>
                <TableCell>{procedure.co_procedimento}</TableCell>
                <TableCell>{procedure.no_procedimento}</TableCell>
                <TableCell align="center">
                  {formatCompetence(procedure.dt_competencia)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {(loading || procedures.length === 0) && (
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading ? <CircularProgress /> : 'Nenhum procedimento encontrado'}
        </div>
      )}

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default Procedures;
