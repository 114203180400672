import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { createContext, useContext, useState } from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Localization, ptBR } from '@material-ui/core/locale';

// The default ptBR locale still has the labelDisplayedRows in English
// This was fixed in V5
const fixedLocale: Localization = {
  ...ptBR,
};
if (fixedLocale.props?.MuiTablePagination) {
  fixedLocale.props.MuiTablePagination.labelDisplayedRows = ({
    from,
    to,
    count,
  }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`;
}

interface ContextProps {
  darkMode: boolean;
  enableDarkMode: (value: boolean) => void;
}

const ThemeContext = createContext<ContextProps>({} as ContextProps);

export function useProvideTheme() {
  return useContext(ThemeContext);
}

function getInitialDarkModeActivated() {
  const darkMode = localStorage.getItem('darkMode');

  switch (true) {
    case darkMode === 'true':
      return true;
    case darkMode === null:
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    default:
      return false;
  }
}

function ProvideTheme({ children }: any) {
  const [darkMode, setDarkMode] = useState(getInitialDarkModeActivated());

  function enableDarkMode(val: boolean) {
    setDarkMode(val);
    localStorage.setItem('darkMode', String(val));
  }

  // unstable_createMuiStrictModeTheme is used to prevent errors with the drawer
  // the errors should be fixed in Material-UI V5
  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      // TODO: create a separate theme for dark and light, or find a way to set different colors for them
      primary: {
        main: darkMode ? '#009AFA' : '#266DD3',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    ...fixedLocale,
  });

  return (
    <ThemeContext.Provider value={{ darkMode, enableDarkMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ProvideTheme;
