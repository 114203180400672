import { Indicator } from '../indicatorsList';

const gestantes: Indicator = {
  title: 'Gestantes com pelo menos 6 consultas pré-natal realizadas',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Idade', key: 'idade' },
    { title: 'Celular', key: 'celular' },
    { title: '12º semana', key: 'primeira_consulta_20' },
    { title: 'DUM', key: 'data_dum' },
    { title: 'Consultas', key: 'consultas' },
    { title: 'Semana atual', key: 'semana_atual_gestacao' },
    { title: 'Consistente', key: 'consistente' },
    {
      title: 'Meta',
      key: 'meta',
      transform: (val) => (val ? `${val}%` : '--'),
    },
    { title: 'Válido', key: 'valido' },
  ],
};

export default gestantes;
