import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  dataContainer: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: 'auto',
    padding: theme.spacing(1),
    maxHeight: 'calc(100vh - 360px)',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'auto auto',
      padding: theme.spacing(2),
    },
  },
}));
