import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import headers from './headers';

export interface CnesEstabelecimento {
  id: string | null;
  cnes: string | null;
  noFantasia: string | null;
  noEmpresarial: string | null;
  uf: string | null;
  noMunicipio: string | null;
  gestao: string | null;
  natJuridica: string | null;
  atendeSus: string | null;
}

export default function useEstabelecimento() {
  const message = useRef(useMessage());
  const [estabelecimentos, setEstabelecimentos] = useState<
    CnesEstabelecimento[]
  >([]);
  const [municipio, setMunicipio] = useState('');
  const [nome, setNome] = useState('');
  const [loading, setLoading] = useState(false);

  function getFilterType(val: string) {
    const isNumber = !isNaN(+val);
    if (isNumber) {
      switch (val.length) {
        case 7:
          return 'cnes';
        case 11:
          return 'cpf';
        case 8:
        case 14:
          return 'cnpj';
      }
    }
    return 'nome';
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (municipio || nome) {
      setLoading(true);

      (async () => {
        try {
          const filterType = getFilterType(nome);
          const filters = Object.entries({
            municipio: municipio || undefined,
            [filterType]: nome || undefined,
          })
            .filter((entry) => entry[1])
            .map((entry) => entry.join('='))
            .join('&');
          const response = await axiosInstance.get(API_ROUTES.PROXY, {
            cancelToken: source.token,
            headers,
            params: {
              url: `${API_ROUTES.CNES_ESTABELECIMENTOS}?${filters}`,
            },
          });

          const estabelecimentos = response.data as CnesEstabelecimento[];

          setEstabelecimentos(estabelecimentos);
          setLoading(false);
        } catch (error: any) {
          if (axios.isCancel(error)) {
            return;
          }

          setLoading(false);

          message.current.setMessage({
            type: 'error',
            text:
              'Ocorreu um erro ao carregar ao buscar os estabelecimentos do CNES.',
          });
        }
      })();
    } else {
      setEstabelecimentos([]);
    }

    return () => {
      source.cancel();
    };
  }, [municipio, nome]);

  return { estabelecimentos, setMunicipio, setNome, loading };
}
