import {
  TableRow,
  TableCell,
  IconButton,
  makeStyles,
  Collapse,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import Solicitation from '../utils/solicitation.interface';

export interface SolicitationsRowProps {
  solicitation: Solicitation;
  setSelected: (solicitation: Solicitation) => void;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function SolicitationsRow(props: SolicitationsRowProps) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingRight: 0 }}>
          <IconButton
            aria-label="mostrar detalhes"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.solicitation.patient.name}
        </TableCell>
        <TableCell align="center">{props.solicitation.patient.age}</TableCell>
        <TableCell align="center">{props.solicitation.priority}</TableCell>
        <TableCell align="center">{props.solicitation.status}</TableCell>
        <TableCell align="center">
          {props.solicitation.solicitationDate}
        </TableCell>
        <TableCell align="left">
          <IconButton
            aria-label="visualizar"
            size="small"
            onClick={() => props.setSelected(props.solicitation)}
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginX={4} marginBottom={2}>
              <Typography variant="h6" component="div">
                Sintomas
              </Typography>
              <List dense={true}>
                {props.solicitation.symptoms.map((symptom, idx) => (
                  <ListItem key={idx}>
                    <ListItemText primary={symptom} />
                  </ListItem>
                ))}
              </List>

              <Typography variant="h6" component="div">
                Condições
              </Typography>
              <List dense={true}>
                {props.solicitation.conditions.map((condition, idx) => (
                  <ListItem key={idx}>
                    <ListItemText primary={condition} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SolicitationsRow;
