import {
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@material-ui/core';
import { Close, Visibility } from '@material-ui/icons';
import { API_ROUTES, axiosInstance } from 'api';
import axios, { CancelTokenSource } from 'axios';
import { useMessage } from 'context/message';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  convertDateFromISO8601ToPtBR,
  convertDateFromPtBRToISO8601,
} from 'utils/date_converter';
import { DuplicatedCitizenFilter } from './DuplicatedCitizens';

interface CitizenRowProps {
  data: string[];
  filters: DuplicatedCitizenFilter;
}

interface CitizenDetail {
  no_cidadao: string;
  no_equipe: string | null;
  no_mae: string | null;
  dt_nascimento: string | null;
  nu_cpf: string | null;
  nu_cns: string | null;
}

const rowsPerPage = 15;

function CitizenRow(props: CitizenRowProps) {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<CitizenDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const source = useRef<CancelTokenSource | null>(null);
  const message = useRef(useMessage());

  function convertFilters(filters: DuplicatedCitizenFilter, data: string[]) {
    // The order of the data values are: count, name, mother, birth date

    const keys: string[] = [
      'no_cidadao',
      filters.mother && 'no_mae',
      filters.birthDate && 'dt_nascimento',
    ].filter((v): v is string => !!v);

    const values = [
      data[1],
      filters.mother && data[2],
      filters.birthDate && convertDateFromPtBRToISO8601(data[data.length - 1]),
    ].filter((v): v is string => !!v);

    const params: { [key: string]: string } = {};

    keys.forEach((v, i) => {
      params[v] = values[i];
    });

    return params;
  }

  useEffect(() => {
    if (open && rows.length === 0) {
      source.current?.cancel();
      source.current = axios.CancelToken.source();
      setLoading(true);

      (async () => {
        try {
          const params = convertFilters(props.filters, props.data);

          const response = await axiosInstance.get(
            API_ROUTES.ESUS_CITIZENS_DUPLICATED_DETAILS,
            {
              params,
              cancelToken: source.current?.token,
            },
          );

          setRows(response.data.results);
          setLoading(false);
        } catch (error: any) {
          if (axios.isCancel(error)) {
            return;
          }

          setLoading(false);

          message.current.setMessage({
            type: 'error',
            text:
              error.response?.data.message ||
              'Ocorreu um erro ao carregar a lista de cidadãos duplicados.',
          });
        }
      })();
    }
  }, [open, props.data, props.filters, rows.length]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          Detalhes do Cidadão
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            style={{ position: 'absolute', top: 8, right: 16 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 'calc(100vh - 360px)' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Equipe</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>CNS</TableCell>
                  <TableCell>Nome da mãe</TableCell>
                  <TableCell>Data de nascimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, idx) => {
                      const noWrap: CSSProperties = { whiteSpace: 'nowrap' };

                      return (
                        <TableRow key={idx}>
                          <TableCell style={noWrap}>{row.no_equipe}</TableCell>
                          <TableCell style={noWrap}>{row.no_cidadao}</TableCell>
                          <TableCell style={noWrap}>{row.nu_cpf}</TableCell>
                          <TableCell style={noWrap}>{row.nu_cns}</TableCell>
                          <TableCell style={noWrap}>{row.no_mae}</TableCell>
                          <TableCell style={noWrap}>
                            {row.dt_nascimento
                              ? convertDateFromISO8601ToPtBR(row.dt_nascimento)
                              : ''}
                          </TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper style={{ marginBottom: 16 }}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(_e: any, page: number) => {
                setPage(page);
              }}
            />
          </Paper>
        </DialogContent>
      </Dialog>

      <TableRow>
        {props.data.map((data, idx) => (
          <TableCell key={idx} align="center" style={{ whiteSpace: 'nowrap' }}>
            {data}
          </TableCell>
        ))}
        <TableCell align="center">
          <IconButton
            aria-label="mostrar detalhes"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <Visibility />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CitizenRow;
