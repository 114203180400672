import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import useDebounce from '../../../hooks/useDebounce';
import headers from '../headers';
import { CnesEstabelecimento } from '../useEstabelecimentos';

interface ViewProfessionalsProps {
  establishment: CnesEstabelecimento | null;
  close: () => void;
}

interface CnesProfissional {
  tpSusNaoSus: string | null;
  cbo: string | null;
  dsCbo: string | null;
  chOutros: number | null;
  chAmb: number | null;
  chHosp: number | null;
  vinculacao: string | null;
  vinculo: string | null;
  subVinculo: string | null;
  nome: string | null;
  cns: string | null;
  cnsMaster: string | null;
  artigo2: string | null;
  artigo3: string | null;
  artigo5: string | null;
  dtEntrada: string | null;
}

function ViewProfessionals({ establishment, close }: ViewProfessionalsProps) {
  const [professionals, setProfessionals] = useState<CnesProfissional[]>([]);
  const [filteredProfessionals, setFiltered] = useState<CnesProfissional[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);

  const message = useRef(useMessage());

  const pageSize = 10;

  useEffect(() => {
    setPage(0);
    setFiltered(
      professionals.filter(
        (prof) =>
          debouncedFilter === '' ||
          prof.nome?.includes(debouncedFilter) ||
          prof.cns === debouncedFilter ||
          prof.dsCbo?.includes(debouncedFilter) ||
          prof.cbo === debouncedFilter,
      ),
    );
  }, [professionals, debouncedFilter]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      if (!establishment) {
        return;
      }

      setLoading(true);
      try {
        const id = establishment.id;
        const response: AxiosResponse<
          CnesProfissional[]
        > = await axiosInstance.get(API_ROUTES.PROXY, {
          cancelToken: source.token,
          headers,
          params: {
            url: `${API_ROUTES.CNES_ESTABELECIMENTOS_PROFISSIONAIS}/${id}`,
          },
        });

        setProfessionals(response.data);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar os profissionais',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [establishment]);

  return (
    <Dialog open={!!establishment} onClose={close} maxWidth="lg" fullWidth>
      <DialogTitle>Profissionais</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexWrap: 'wrap',
            marginBottom: '1em',
          }}
        >
          <TextField
            label="Filtrar por Nome/CNS/CBO"
            variant="outlined"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value.toUpperCase());
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <TableContainer
          component={Paper}
          style={{ maxHeight: 'calc(100vh - 400px)' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">CNS</TableCell>
                <TableCell align="center">CBO</TableCell>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">SUS</TableCell>
                <TableCell align="center">Vinculação</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">Subtipo</TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  CH Outros
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  CH Amb.
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  CH Hosp.
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredProfessionals
                  .slice(page * pageSize, page * pageSize + 10)
                  .map((professional, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.nome}
                      </TableCell>
                      <TableCell align="center">{professional.cns}</TableCell>
                      <TableCell align="center">{professional.cbo}</TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.dsCbo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.tpSusNaoSus}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.vinculacao}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.vinculo}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {professional.subVinculo}
                      </TableCell>
                      <TableCell align="center">
                        {professional.chOutros}
                      </TableCell>
                      <TableCell align="center">{professional.chAmb}</TableCell>
                      <TableCell align="center">
                        {professional.chHosp}
                      </TableCell>
                      <TableCell align="center">
                        {(professional.chOutros || 0) +
                          (professional.chAmb || 0) +
                          (professional.chHosp || 0)}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filteredProfessionals.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={(_e: any, page: number) => {
            setPage(page);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ViewProfessionals;
