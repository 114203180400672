import { BrowserRouter } from 'react-router-dom';
import ProvideAuth from './context/auth';
import ProvideMessage from './context/message';
import ProvideTheme from './context/theme';
import { Routes } from './components/Routes';

function App() {
  return (
    <ProvideTheme>
      <ProvideMessage>
        <ProvideAuth>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ProvideAuth>
      </ProvideMessage>
    </ProvideTheme>
  );
}

export default App;
