import { Box, Container, makeStyles } from '@material-ui/core';

import Drawer from './Drawer';
import AppBar from './AppBar';
import { useState } from 'react';
import { MaxWidth } from '../Routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
  },
  content: {
    overflow: 'auto',
    flex: 1,
    width: '100%',
  },
}));

interface MainLayoutProps {
  children: any;
  maxWidth: MaxWidth;
  renderLayout: boolean;
}

function MainLayout(props: MainLayoutProps) {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  if (!props.renderLayout) {
    return props.children;
  }

  return (
    <div className={classes.root}>
      <AppBar setDrawerOpen={setDrawerOpen} />

      <Drawer open={isDrawerOpen} setOpen={setDrawerOpen} />

      {/* TODO: create a breadcrumb to show where the user is */}
      <Box component="main" className={classes.main}>
        <div className={classes.appBarSpacer} />
        <div className={classes.content}>
          <Container maxWidth={props.maxWidth}>
            <div>{props.children}</div>
          </Container>
        </div>
      </Box>
    </div>
  );
}

export default MainLayout;
