import {
  MenuItem,
  Paper,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import { useEffect, useState } from 'react';
import useEstabelecimento, { CnesEstabelecimento } from './useEstabelecimentos';
import useMunicipios from './useMunicipios';
import useUfs from './useUfs';
import ViewProfessionals from './ViewProfessionals';
import useDebounce from '../../hooks/useDebounce';
import PageHeader from '../../components/PageHeader';

export default function Cnes() {
  const { ufs, loading: ufsLoading } = useUfs();
  const { municipios, setUf, loading: municipiosLoading } = useMunicipios();
  const {
    estabelecimentos,
    setMunicipio,
    setNome,
    loading,
  } = useEstabelecimento();
  const pageSize = 10;

  const [ufFilter, setUfFilter] = useState('');
  const [municipioFilter, setMunicipioFilter] = useState('');
  const [page, setPage] = useState(0);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState('');

  const debouncedEstabelecimento = useDebounce(filtroEstabelecimento, 500);

  const [
    selectedEstabelecimento,
    setSelectedEstabelecimento,
  ] = useState<CnesEstabelecimento | null>(null);

  function clearSelectedEstablishment() {
    setSelectedEstabelecimento(null);
  }

  useEffect(() => {
    setPage(0);
    setNome(debouncedEstabelecimento);
  }, [debouncedEstabelecimento, setNome]);

  useEffect(() => {
    setUf(ufFilter);
    if (!ufFilter) {
      setMunicipioFilter('');
    }
  }, [ufFilter, setMunicipio, setUf]);

  useEffect(() => {
    setMunicipio(municipioFilter);
  }, [municipioFilter, setMunicipio]);

  return (
    <>
      <ViewProfessionals
        establishment={selectedEstabelecimento}
        close={clearSelectedEstablishment}
      />

      <PageHeader title="CNES | Estabelecimentos e Profissionais" />

      <div
        style={{
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
          marginBottom: '1rem',
        }}
      >
        <FormControl variant="outlined">
          <InputLabel id="role-filter-label">UF</InputLabel>
          <Select
            labelId="role-filter-label"
            label="UF"
            value={ufFilter}
            disabled={ufsLoading}
            onChange={(e) => setUfFilter(e.target.value as string)}
            style={{
              minWidth: '180px',
            }}
          >
            <MenuItem value="">Não filtrar</MenuItem>
            {ufs.map((uf) => (
              <MenuItem key={uf.id} value={uf.id}>
                {uf.name}
              </MenuItem>
            ))}
          </Select>
          {ufsLoading && <LinearProgress />}
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel id="role-filter-label">Município</InputLabel>
          <Select
            labelId="role-filter-label"
            label="Município"
            native
            value={municipioFilter}
            disabled={ufFilter === '' || municipiosLoading}
            onChange={(e) => setMunicipioFilter(e.target.value as string)}
            style={{
              minWidth: '180px',
            }}
          >
            <option value=""></option>
            {municipios.map((municipio) => (
              <option key={municipio.id} value={municipio.id}>
                {municipio.name}
              </option>
            ))}
          </Select>
          {municipiosLoading && <LinearProgress />}
        </FormControl>

        <TextField
          label="Nome/CNES/CNPJ"
          variant="outlined"
          style={{ minWidth: '270px' }}
          value={filtroEstabelecimento}
          onChange={(e) => {
            setFiltroEstabelecimento(e.target.value.toUpperCase());
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={0} />
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                CNES
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Nome Fantasia
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Nome Empresarial
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                UF
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Nome Município
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Gestão
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Natureza Jurídica
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                Atende Sus
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              estabelecimentos
                .slice(page * pageSize, page * pageSize + 10)
                .map((estabelecimento, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Tooltip title="Visualizar profissionais">
                        <IconButton
                          onClick={() =>
                            setSelectedEstabelecimento(estabelecimento)
                          }
                        >
                          <GroupIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.cnes}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.noFantasia}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.noEmpresarial}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.uf}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.noMunicipio}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.gestao}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.natJuridica}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {estabelecimento.atendeSus}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={estabelecimentos.length}
        rowsPerPage={pageSize}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}
