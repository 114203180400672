import { TableRow, TableCell } from '@material-ui/core';

interface IndicatorsRowProps {
  columns: string[];
}

function IndicatorsRow(props: IndicatorsRowProps) {
  return (
    <TableRow>
      {props.columns.map((col, idx) => (
        <TableCell key={idx} align="center" style={{ whiteSpace: 'nowrap' }}>
          {col}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default IndicatorsRow;
