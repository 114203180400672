import {
  MenuItem,
  Paper,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  FormHelperText,
  FormControl,
  InputLabel,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { GetApp, Close, Search, Delete } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import TablePaginationActions from 'components/TablePaginationActions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { useMessage } from '../../../context/message';
import useCsvExporter from '../../../hooks/useCsvExporter';
import useTeams from '../../../hooks/useTeams';

import indicatorsList from './indicatorsList';
import IndicatorsRow from './IndicatorsRow';

const rowsPerPage = 10;

const initialFilters = function () {
  return {
    equipe: '',
    valido: '',
    microArea: '',
    meta: '',
  };
};

function Indicators() {
  const [indicatorType, setIndicatorType] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rows, setRows] = useState<string[][]>([]);
  const [loading, setLoading] = useState(false);
  const [didInitialLoad, setDidInitialLoad] = useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const [searchFilters, setSearchFilters] = useState(initialFilters());
  const teams = useTeams();
  const exporter = useCsvExporter();

  const message = useRef(useMessage());
  const source = useRef<CancelTokenSource | null>(null);

  async function exportToCsv() {
    exporter.exportToCsv(
      API_ROUTES.CSV_EXPORTER_INDICATORS,
      `indicador-${indicatorType + 1}.csv`,
      {
        id: indicatorType + 1,
        valido: searchFilters.valido || undefined,
        equipe: searchFilters.equipe || undefined,
        micro_area: searchFilters.microArea || undefined,
      },
    );
  }

  const fetchIndicator = useCallback(async () => {
    source.current?.cancel();
    source.current = axios.CancelToken.source();
    setLoading(true);

    try {
      const response = await axiosInstance.get(API_ROUTES.ESUS_INDICATORS, {
        cancelToken: source.current.token,
        params: {
          id: indicatorType + 1,
          page: page + 1,
          page_size: rowsPerPage,
          valido: searchFilters.valido || undefined,
          equipe: searchFilters.equipe || undefined,
          micro_area: searchFilters.microArea || undefined,
          meta: searchFilters.meta || undefined,
        },
      });

      const colsList = indicatorsList[indicatorType].tableData.map((data) => ({
        key: data.key,
        transform: data.transform,
      }));

      const newRows = response.data.results.map((result: any) => {
        const cols: string[] = [];
        colsList.forEach((col) => {
          const val = result[col.key];
          cols.push(String((col.transform ? col.transform(val) : val) || '--'));
        });
        return cols;
      });

      setRows(newRows);
      setCount(response.data.count);
      setLoading(false);
      setDidInitialLoad(true);
      setCanFetch(false);
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      }

      setRows([]);
      setLoading(false);
      setCanFetch(false);

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.message ||
          'Ocorreu um erro ao carregar o indicador.',
      });
    }
  }, [
    indicatorType,
    page,
    searchFilters.equipe,
    searchFilters.microArea,
    searchFilters.valido,
    searchFilters.meta,
  ]);

  useEffect(() => {
    if (canFetch) {
      fetchIndicator();
    }
  }, [fetchIndicator, canFetch]);

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  return (
    <>
      <PageHeader title="Indicadores" />

      <div
        style={{
          display: 'flex',
          gap: 16,
          marginBottom: '1rem',
          flexWrap: 'wrap',
        }}
      >
        <FormControl>
          <Select
            variant="outlined"
            value={indicatorType}
            disabled={loading}
            onChange={(e) => {
              setPage(0);
              setCount(-1);
              setRows([]);
              setDidInitialLoad(false);
              setIndicatorType(e.target.value as number);
              setSearchFilters(initialFilters());
            }}
          >
            {indicatorsList.map((indicator, idx) => (
              <MenuItem key={idx} value={idx}>
                {`${idx + 1}º indicador - ${indicator.title}`}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Selecione um indicador</FormHelperText>
        </FormControl>

        <Button
          style={{
            height: 56,
          }}
          color="primary"
          variant="outlined"
          startIcon={
            exporter.isExporting ? (
              <CircularProgress size={14} disableShrink />
            ) : (
              <GetApp />
            )
          }
          onClick={exportToCsv}
          disabled={exporter.isExporting}
        >
          Exportar
        </Button>
      </div>

      <div
        style={{
          marginBottom: '24px',
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        <FormControl variant="outlined">
          <InputLabel>Filtrar por validade</InputLabel>
          <Select
            label="Filtrar por validade"
            value={searchFilters.valido}
            disabled={loading}
            onChange={(e) => {
              setSearchFilters((filters) => ({
                ...filters,
                valido: e.target.value as string,
              }));
            }}
            style={{ minWidth: '180px' }}
          >
            <MenuItem value="">Não filtrar</MenuItem>
            <MenuItem value="sim">Válido</MenuItem>
            <MenuItem value="nao">Inválido</MenuItem>
          </Select>
        </FormControl>

        {indicatorType === 0 && (
          <FormControl variant="outlined">
            <InputLabel>Filtrar por meta</InputLabel>
            <Select
              label="Filtrar por meta"
              value={searchFilters.meta}
              disabled={loading}
              onChange={(e) => {
                setSearchFilters((filters) => ({
                  ...filters,
                  meta: e.target.value as string,
                }));
              }}
              style={{ minWidth: '180px' }}
            >
              <MenuItem value="">Não filtrar</MenuItem>
              <MenuItem value="sim">Alcançada</MenuItem>
              <MenuItem value="nao">Não Alcançada</MenuItem>
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined">
          <InputLabel>Filtrar por equipe</InputLabel>
          <Select
            label="Filtrar por equipe"
            value={searchFilters.equipe}
            disabled={loading}
            onChange={(e) => {
              setSearchFilters((filters) => ({
                ...filters,
                equipe: e.target.value as string,
              }));
            }}
            style={{
              minWidth: '180px',
            }}
          >
            <MenuItem value="">Não filtrar</MenuItem>
            {teams.map((team, idx) => (
              <MenuItem key={idx} value={team.no_equipe}>
                {team.no_equipe}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label="Filtrar por Micro Área"
          placeholder="Digite a micro área"
          value={searchFilters.microArea}
          disabled={loading}
          onChange={(e) => {
            setSearchFilters((filters) => ({
              ...filters,
              microArea: e.target.value as string,
            }));
          }}
          style={{ width: 192 }}
          InputProps={{
            endAdornment: searchFilters.microArea ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchFilters((filters) => ({
                      ...filters,
                      microArea: '',
                    }));
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          inputProps={{ maxLength: 3 }}
        />

        <Button
          style={{
            height: 56,
          }}
          startIcon={<Search />}
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={() => {
            setCanFetch(true);
          }}
        >
          Pesquisar
        </Button>

        <Button
          style={{
            height: 56,
          }}
          startIcon={<Delete />}
          color="default"
          variant="outlined"
          disabled={loading}
          onClick={() => {
            setSearchFilters(initialFilters());
          }}
        >
          Limpar
        </Button>
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 370px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {indicatorsList[indicatorType].tableData.map((data, idx) => (
                <TableCell
                  key={idx}
                  align="center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {data.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || rows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={
                    Object.keys(indicatorsList[indicatorType].tableData).length
                  }
                  align="center"
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    didInitialLoad && 'Nenhum resultado encontrado'
                  )}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, idx) => <IndicatorsRow key={idx} columns={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
          setCanFetch(true);
        }}
        nextIconButtonProps={
          count === -1
            ? {
                disabled: true,
              }
            : undefined
        }
        labelDisplayedRows={({ from, to, count }) =>
          count === -1 ? '' : `${from}-${to} de ${count}`
        }
      />
    </>
  );
}

export default Indicators;
