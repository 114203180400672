import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { axiosInstance, API_ROUTES } from '../../api';
import { useMessage } from '../../context/message';
import useDebounce from '../../hooks/useDebounce';
import Vaccine from './interfaces';

interface VaccineFilter {
  name__icontains?: string;
  acronym__icontains?: string;
  active?: string;
}

export function useVaccines() {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<VaccineFilter>({});
  const [vaccines, setVaccines] = useState<Vaccine[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const message = useRef(useMessage());
  const [refreshCount, setRefresh] = useState(0);

  const debouncedFilters = useDebounce(filters, 500);

  function refresh() {
    setRefresh((refresh) => refresh + 1);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setVaccines([]);

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.VACCINES, {
          cancelToken: source.token,
          params: { ...debouncedFilters, page: page + 1 },
        });
        const vaccines = response.data?.results as Vaccine[];
        setVaccines(vaccines);
        setCount(response.data?.count);

        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.detail ||
            'Ocorreu um erro ao carregar a lista de vacinas.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [debouncedFilters, page, refreshCount]);

  return {
    vaccines,
    loading,
    filters,
    setFilters,
    page,
    setPage,
    count,
    refresh,
  };
}
