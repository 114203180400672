import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';

interface Props {
  open: boolean;
  onClose: () => void;
}

function CreateCityDialog (props: Props) {
  const [name, setName] = useState('');
  const [connectionName, setConnectionName] = useState('');
  const [sigusConnectionName, setSigusConnectionName] = useState('');
  const message = useMessage();

  function getErrorType (key: string) {
    switch (key) {
      case 'name':
        return 'Nome: ';
      case 'connection_name':
        return 'Conexão ESUS: ';
      case 'sigus_connection_name':
        return 'Conexão SIGUS: ';
      default:
        return '';
    }
  }

  async function onSubmit () {
    try {
      await axiosInstance.post(API_ROUTES.ADMINISTRATION_CITIES_CREATE, {
        name,
        connection_name: connectionName,
        sigus_connection_name: sigusConnectionName
      });

      props.onClose();
    } catch (error: any) {
      // TODO refactor this, maybe do validations in the frontend instead of only using the backend
      let msg = 'Ocorreu um erro ao criar o usuário';
      if (error.response?.data) {
        const err = Object.entries(error.response.data)[0];
        const begin = getErrorType(err[0]);
        if (begin) {
          msg = `${begin} ${(err[1] as string)[0]}`;
        }
      }
      message.setMessage({
        type: 'error',
        text: msg,
      });
    }
  }

  useEffect(() => {
    setName('');
    setConnectionName('');
    setSigusConnectionName('');
  }, [props.open]);


  return (
    <Dialog open={props.open}>
      <DialogTitle>Cadastrar Usuário</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 1000 }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <TextField
            variant="outlined"
            label="Nome"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            label="Conexão ESUS"
            value={connectionName}
            onChange={(e) => {
              setConnectionName(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            label="Conexão SIGUS"
            value={sigusConnectionName}
            onChange={(e) => {
              setSigusConnectionName(e.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button variant="outlined" color="primary" onClick={onSubmit}>
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCityDialog;
