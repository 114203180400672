import { PublicRoute } from '../types';
import { APP_ROUTES } from '..';
import {
  Login,
  ForgotPassword,
  ConfirmPassword,
  ConfirmAccount,
} from '../../../screens';

export const publicRoutes: PublicRoute[] = [
  { path: APP_ROUTES.LOGIN, component: Login },
  { path: APP_ROUTES.FORGOT_PASSWORD, component: ForgotPassword },
  { path: APP_ROUTES.CONFIRM_PASSWORD, component: ConfirmPassword },
  { path: APP_ROUTES.CONFIRM_ACCOUNT, component: ConfirmAccount },
];
