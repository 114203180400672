import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Tooltip,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance, API_ROUTES } from '../../../api';
import { useMessage } from '../../../context/message';
import CloseIcon from '@material-ui/icons/Close';
import { Vaccine, Dose } from '../interfaces';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';

interface VaccineFormProps {
  open: boolean;
  selectedVaccine: Vaccine | null;
  handleClose: () => void;
  handleRefresh: () => void;
}

function VaccineForm(props: VaccineFormProps) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [active, setActive] = useState(true);
  const [doses, setDoses] = useState<Dose[]>([]);
  const [targetGroups, setTargetGroups] = useState<string[]>([]);
  const message = useRef(useMessage());

  function validate() {
    let hasError = !name || !acronym;

    for (const dose of doses) {
      if (!dose.description || !dose.target_group) {
        hasError = true;
      }
    }

    if (hasError) {
      message.current.setMessage({
        type: 'warning',
        text: 'Preencha todos os campos para continuar',
      });
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    if (!validate()) {
      return;
    }

    try {
      setLoading(true);
      const id = props.selectedVaccine?.id.toString() || null;
      const url = id
        ? API_ROUTES.VACCINES_EDIT.replace(':id', id)
        : API_ROUTES.VACCINES_CREATE;

      await axiosInstance[id ? 'patch' : 'post'](url, {
        name,
        acronym,
        active,
        doses,
      });

      message.current.setMessage({
        type: 'success',
        text: `Vacina ${id ? 'editada' : 'cadastrada'} com sucesso`,
      });
      setLoading(false);
      props.handleRefresh();
      props.handleClose();
    } catch (error: any) {
      setLoading(false);

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.detail ||
          'Ocorreu um erro ao tentar cadastrar a vacina.',
      });
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (props.open) {
      // deep copy to avoid issues when editing and closing
      const selected = props.selectedVaccine
        ? JSON.parse(JSON.stringify(props.selectedVaccine))
        : undefined;

      setName(selected?.name ?? '');
      setAcronym(selected?.acronym ?? '');
      setActive(selected?.active ?? true);
      setDoses(selected?.doses ?? []);
      setLoading(false);

      try {
        (async () => {
          const response = await axiosInstance.get(
            API_ROUTES.VACCINES_TARGET_GROUPS,
          );
          setTargetGroups(response.data);
        })();
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          message.current.setMessage({
            type: 'error',
            text: 'Ocorreu um erro ao carregar a lista de grupos alvos',
          });
        }
      }
    }

    return () => {
      source.cancel();
    };
  }, [props.open, props.selectedVaccine]);

  return (
    <Dialog open={props.open}>
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Cadastrar Vacina</Typography>

        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            !loading && props.handleClose();
          }}
        />
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 1000 }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            marginBottom: 16,
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            <TextField
              disabled={loading}
              fullWidth
              variant="outlined"
              label="Nome da Vacina"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              inputProps={{
                maxLength: 100,
              }}
            />

            <FormControlLabel
              disabled={loading}
              control={
                <Checkbox
                  checked={active}
                  onChange={(e) => {
                    setActive(e.target.checked);
                  }}
                  color="primary"
                />
              }
              label="Ativo"
            />
          </div>

          <TextField
            disabled={loading}
            variant="outlined"
            style={{ width: '50%' }}
            label="Sigla"
            value={acronym}
            onChange={(e) => {
              setAcronym(e.target.value);
            }}
            inputProps={{
              maxLength: 10,
            }}
          />
        </div>

        <TableContainer component={Paper} style={{ height: '254px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', minWidth: '33%' }}>
                  Grupo Alvo
                </TableCell>
                <TableCell>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    Idade
                    <Tooltip
                      title={
                        <>
                          <Typography variant="caption">
                            A idade deve ser informada em meses.
                          </Typography>
                          <br />
                          <Typography variant="caption">
                            O texto será ajustado automaticamente no aplicativo.
                          </Typography>
                        </>
                      }
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell style={{ width: 0 }}>
                  <IconButton
                    disabled={loading}
                    style={{ padding: 0 }}
                    onClick={() => {
                      setDoses([
                        ...doses,
                        {
                          age: null,
                          target_group: '',
                          description: `${doses.length + 1}° Dose`,
                        },
                      ]);
                    }}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doses.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    Cadastre as doses desta vacina aqui!
                  </TableCell>
                </TableRow>
              )}
              {doses.map((dose, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <TextField
                      disabled={loading}
                      value={dose.description}
                      onChange={(e) => {
                        setDoses((doses) => {
                          const newDoses = [...doses];
                          newDoses[idx].description = e.target.value;
                          return newDoses;
                        });
                      }}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {/* TODO: add max length of 50 */}
                    <Autocomplete
                      disabled={loading}
                      value={dose.target_group}
                      onChange={(_e, value) => {
                        setDoses((doses) => {
                          const newDoses = [...doses];
                          newDoses[idx].target_group = value;
                          return newDoses;
                        });
                      }}
                      disableClearable
                      freeSolo
                      autoSelect
                      options={targetGroups}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={loading}
                      value={dose.age === null ? '' : dose.age}
                      onChange={(e) => {
                        setDoses((doses) => {
                          const newDoses = [...doses];
                          const newAge = e.target.value.replace(/\D/g, '');
                          newDoses[idx].age =
                            newAge === '' || isNaN(+newAge) ? null : +newAge;
                          return newDoses;
                        });
                      }}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={loading}
                      style={{ padding: 0 }}
                      onClick={() => {
                        setDoses(doses.filter((_dose, index) => index !== idx));
                      }}
                    >
                      <DeleteForeverIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.handleClose}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {!!props.selectedVaccine ? 'Salvar' : 'Criar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VaccineForm;
