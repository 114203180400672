import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(38),
  },
  logoContainer: {
    height: theme.spacing(25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoImg: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    objectFit: 'contain',
    transition: 'all 0.1s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  noLogoImg: {
    fontSize: theme.spacing(16),
  },
  fileInput: {
    display: 'none',
  },
  hints: {
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));
