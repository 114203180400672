import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { ImportedLocalEstablishment } from '../importedEstablishment.interface';
import { CenteredCell } from '../ImportedEstablishments';

interface Props {
  establishments: ImportedLocalEstablishment[];
}

function LocalEstablishments(props: Props) {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 'calc(100vh - 280px)' }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <CenteredCell>CNES</CenteredCell>
            <CenteredCell>Razão Social</CenteredCell>
            <CenteredCell>Nome Fantasia</CenteredCell>
            <CenteredCell>CEP</CenteredCell>
            <CenteredCell>Telefone</CenteredCell>
            <CenteredCell>E-mail</CenteredCell>
            <CenteredCell>CNPJ</CenteredCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.establishments.map((establishment) => (
            <TableRow key={establishment.id}>
              <CenteredCell>{establishment.cnes}</CenteredCell>
              <CenteredCell>{establishment.r_social}</CenteredCell>
              <CenteredCell>{establishment.nome_fanta}</CenteredCell>
              <CenteredCell>{establishment.cod_cep}</CenteredCell>
              <CenteredCell>{establishment.telefone}</CenteredCell>
              <CenteredCell>{establishment.e_mail}</CenteredCell>
              <CenteredCell>{establishment.cnpj}</CenteredCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LocalEstablishments;
