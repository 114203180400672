import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import ConfirmationDialog from './ConfirmationDialog';
import { useStyles } from './security.styles';

interface SecurityProps {
  open: boolean;
  setOpenSecurityDialog: (open: boolean) => void;
}

function Security(props: SecurityProps) {
  const classes = useStyles();
  const auth = useAuth();
  const [enable2fa, setEnable2fa] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const newPwError = newPassword !== '' && !isPasswordValid();
  const confirmPwError =
    confirmPassword !== '' && confirmPassword !== newPassword;
  const passwordHasError = newPwError || confirmPwError;
  const userChanged2fa = auth.user?.uses2fa !== enable2fa;

  useEffect(() => {
    if (props.open) {
      if (auth.user) {
        setEnable2fa(auth.user.uses2fa);
      }

      setNewPassword('');
      setConfirmPassword('');
    }
  }, [auth.user, props.open]);

  function isSaveEnabled() {
    if (passwordHasError) {
      return false;
    }

    return userChanged2fa || !!newPassword;
  }

  function isPasswordValid() {
    const regexs = [
      /([A-Z]+[a-z]+)|([a-z]+[A-Z]+)/,
      /[0-9]+/,
      /[#?!@$%^&*-]+/,
      /^.{8,}$/,
    ];

    return regexs.every((re) => re.test(newPassword));
  }

  function handleClose() {
    props.setOpenSecurityDialog(!props.open);
  }

  function handleSuccess() {
    setConfirmationDialogOpen(false);
    handleClose();
    auth.refreshProfile();
  }

  return (
    <Dialog
      open={props.open}
      disableBackdropClick
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <ConfirmationDialog
        open={confirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        handleSuccess={handleSuccess}
        newPassword={newPassword}
        new2fa={userChanged2fa ? enable2fa : undefined}
      />

      <DialogTitle>Segurança da Conta</DialogTitle>

      <DialogContent dividers className={classes.content}>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={enable2fa}
                onChange={(e) => {
                  setEnable2fa(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Usar autenticação em duas etapas"
          />
        </div>
        <Divider />

        <Typography variant="h6">Alterar senha</Typography>

        <div className={classes.content}>
          <div>
            <TextField
              fullWidth
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label="Digite sua nova senha (opcional)"
              type="password"
              autoComplete="new-password"
              error={newPwError}
            />
            <ul className={classes.passwordRequirements}>
              <li>Use pelo menos 8 caracteres.</li>
              <li>Pelo menos um caracter precisa ser maiúsculo.</li>
              <li>Pelo menos um caracter precisa ser minúsculo.</li>
              <li>Inclua pelo menos um número e um símbolo (#?!@$%^&*-).</li>
            </ul>
          </div>

          <TextField
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            label="Confirme a sua nova senha"
            type="password"
            autoComplete="new-password"
            error={confirmPwError}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancelar
        </Button>

        <Button
          color="primary"
          onClick={() => setConfirmationDialogOpen(true)}
          disabled={!isSaveEnabled()}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Security;
