import { Card, CardContent, Typography, Divider } from '@material-ui/core';
import CardWithCircleSkeleton from './CardWithCircleSkeleton';
import { useStyles } from './CardWithCircles.styles';
import CardWithCircle from './CardWithCircle';

interface CardWithCirclesProps {
  title: string;
  subtitle?: string;
  data: ({
    label: string[];
    value: number;
  } | null)[];
  loading?: boolean;
}

function CardWithCircles(props: CardWithCirclesProps) {
  const { loading = false } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" component="h2">
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography color="textSecondary" variant="caption">
            {props.subtitle}
          </Typography>
        )}

        <Divider className={classes.divider} />

        <div className={classes.dataContainer}>
          {loading
            ? Array.from(Array(8)).map((_, idx) => (
                <CardWithCircleSkeleton key={idx} />
              ))
            : props.data.map((data, idx) => (
                <CardWithCircle key={idx} data={data} />
              ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default CardWithCircles;
