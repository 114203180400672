import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import { useState, FormEvent } from 'react';
import { useAuth } from '../../../context/auth';
import { Redirect, useHistory } from 'react-router-dom';
import { API_ROUTES, axiosInstance } from '../../../api';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import cpfIsValid from '../../../utils/check_cpf';
import { APP_ROUTES } from '../../../components/Routes';
import Logo from '../../../components/Logo';
import maskNumber from '../../../utils/maskNumber';

function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  const [cpf, setCpf] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const cleanCpf = cpf.replace(/[^0-9]+/g, '');
  const isCpfValid = cpfIsValid(cleanCpf);

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    if (!isCpfValid) return;

    try {
      await axiosInstance.post(API_ROUTES.PASSWORD_RECOVERY_TOKEN, {
        cpf: cleanCpf,
      });
      history.push(APP_ROUTES.CONFIRM_PASSWORD);
    } catch (error: any) {
      setErrorMessage(
        error.response?.data?.message ||
          'Ocorreu um erro ao recuperar a senha. Tente novamente.',
      );
    }
  }

  return (
    <>
      {auth.user && (
        <Redirect
          to={{
            pathname: APP_ROUTES.DASHBOARD,
            state: { from: APP_ROUTES.FORGOT_PASSWORD },
          }}
        />
      )}

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Logo className={classes.avatar} />

          <Typography component="h1" variant="h5">
            Esqueceu sua senha?
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
            <Collapse in={errorMessage.length > 0}>
              <Alert
                severity="error"
                onClose={() => {
                  setErrorMessage('');
                }}
              >
                {errorMessage}
              </Alert>
            </Collapse>

            <TextField
              value={cpf}
              margin="normal"
              fullWidth
              label="CPF"
              variant="outlined"
              autoFocus
              error={cpf.length > 0 && !isCpfValid}
              onChange={(e) => {
                setCpf(maskNumber(e.target.value, '###.###.###-##'));
              }}
              helperText={
                cpf.length > 0 && !isCpfValid
                  ? 'Informe um CPF válido'
                  : 'Digite seu CPF'
              }
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<SendIcon>send</SendIcon>}
              disabled={!isCpfValid}
            >
              Próxima
            </Button>
          </form>
          <p style={{ color: 'gray' }}>
            Enviaremos um e-mail para você, contendo o código de confirmação.
          </p>
        </div>
      </Container>
    </>
  );
}

export default ForgotPassword;
