import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  userCard: {
    maxWidth: theme.spacing(64),
  },
  userInfoTitle: {
    marginBottom: theme.spacing(3),
  },
  userInfoContainer: {
    display: 'grid',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
  dateField: {
    width: theme.spacing(24),
  },
  cpfField: {
    width: theme.spacing(22),
  },
  cnsField: {
    width: theme.spacing(28),
  },
  filterSelect: {
    minWidth: theme.spacing(24),
  },
  listHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listContainer: {
    display: 'grid',
    rowGap: theme.spacing(2),
    gridTemplateColumns: '1fr',
  },
}));
