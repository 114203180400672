import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './styles';
import { useState, useEffect, FormEvent } from 'react';
import { useAuth } from '../../../context/auth';
import { Redirect } from 'react-router-dom';
import { API_ROUTES, axiosInstance } from '../../../api';
import { Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { APP_ROUTES } from '../../../components/Routes';
import Logo from '../../../components/Logo';

function ConfirmPassword() {
  const classes = useStyles();
  const auth = useAuth();

  const [token, setToken] = useState('');
  const [disabled, setNextButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [password, setPassword] = useState('');
  const [passwordHasError, setPasswordHasError] = useState(true);

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmHasError, setPasswordConfirmHasError] = useState(true);

  function handlePasswordChange(password: string) {
    setPassword(password);
    if (passwordIsValid(password)) {
      setPasswordHasError(false);
      return;
    }
    setPasswordHasError(true);
  }

  function handlePasswordConfirmChange(value: string) {
    setPasswordConfirm(value);
    if (passwordConfirmIsValid(value)) {
      setPasswordConfirmHasError(false);
      return;
    }
    setPasswordConfirmHasError(true);
  }

  function passwordIsValid(password: string) {
    const regexs = [
      /([A-Z]+[a-z]+)|([a-z]+[A-Z]+)/,
      /[0-9]+/,
      /[#?!@$%^&*-]+/,
      /^.{8,}$/,
    ];
    return regexs.every((re) => re.test(password));
  }

  function passwordConfirmIsValid(passwordConfirm: string) {
    return passwordConfirm === password;
  }

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      await axiosInstance.post(API_ROUTES.PASSWORD_RECOVERY, {
        token,
        password,
        passwordConfirm,
      });
      window.location.replace(API_ROUTES.LOGIN);
    } catch (error: any) {
      setErrorMessage(
        error.response?.data?.message ||
          'Ocorreu um erro inesperado, desculpe-nos pelo transtorno. Tente novamente.',
      );
    }
  }

  useEffect(() => {
    const isValid = token && !passwordHasError && !passwordConfirmHasError;
    setNextButton(!isValid);
  }, [token, passwordHasError, passwordConfirmHasError]);

  return (
    <>
      {auth.user && (
        <Redirect
          to={{
            pathname: APP_ROUTES.DASHBOARD,
          }}
        />
      )}

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Logo className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Esqueceu sua senha?
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
            <Collapse in={errorMessage.length > 0}>
              <Alert
                severity="error"
                onClose={() => {
                  setErrorMessage('');
                }}
              >
                {errorMessage}
              </Alert>
            </Collapse>

            <TextField
              variant="outlined"
              margin="normal"
              required={true}
              fullWidth
              label="Código"
              value={token}
              onChange={(e) => {
                setToken(e.target.value);
              }}
              autoFocus
              autoComplete="new-password"
              inputProps={{ maxLength: 6 }}
              helperText="Digite o código que você recebeu por e-mail."
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Senha"
              value={password}
              onChange={(e) => {
                handlePasswordChange(e.target.value);
              }}
              inputProps={{ maxLength: 64 }}
              type="password"
              autoComplete="new-password"
              error={password.length > 0 && passwordHasError}
            />
            <ul>
              <li className={classes.password}>Use pelo menos 8 caracteres.</li>
              <li className={classes.password}>
                Pelo menos um caracter precisa ser maiúsculo.
              </li>
              <li className={classes.password}>
                Pelo menos um caracter precisa ser minúsculo.
              </li>
              <li className={classes.password}>
                Inclua pelo menos um número e um símbolo (#?!@$%^&*-).
              </li>
            </ul>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Confirmação de Senha"
              value={passwordConfirm}
              onChange={(e) => {
                handlePasswordConfirmChange(e.target.value);
              }}
              helperText="Confirmação de senha"
              inputProps={{ maxLength: 64 }}
              type="password"
              autoComplete="new-password"
              error={password.length > 0 && passwordConfirmHasError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<CheckCircleIcon>send</CheckCircleIcon>}
              disabled={disabled}
            >
              Cadastrar Senha
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}

export default ConfirmPassword;
