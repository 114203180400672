import { useEffect, useRef, useState, SetStateAction } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  TablePagination,
  Paper,
  Select,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import SolicitationsRow from './SolicitationsRow';
import axios, { CancelTokenSource } from 'axios';
import SolicitationModal from './SolicitationModal';
import { convertDateFromISO8601ToPtBR } from '../../utils/date_converter';
import Solicitation from './utils/solicitation.interface';
import SearchIcon from '@material-ui/icons/Search';
import TodayIcon from '@material-ui/icons/Today';
import PriorityRange from './utils/priorityRange';
import Status from './utils/statusList';
import PageHeader from '../../components/PageHeader';
import useDebounce from '../../hooks/useDebounce';
import { useStyles } from './solicitations.styles';

const rowsPerPage = 25;

type Filters = {
  name: string;
  date_gte: string;
  date_lte: string;
  status: string;
  priority: string;
};

export default function Solicitations() {
  const [rows, setRows] = useState<Solicitation[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [selected, setSelected] = useState<Solicitation | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchFilters, setFilters] = useState<Filters>({
    name: '',
    date_gte: '',
    date_lte: '',
    status: '',
    priority: '',
  });

  const debouncedFilters = useDebounce(searchFilters, 500);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const message = useRef(useMessage());
  const classes = useStyles();

  function setSearchFilters(setState: SetStateAction<Filters>) {
    setFilters(setState);
    setPage(0);
  }

  async function fetchSolicitations(
    page: number,
    filters: Filters,
    silent: boolean = false,
  ) {
    try {
      cancelToken.current?.cancel();
      if (!silent) {
        setLoading(true);
      }

      cancelToken.current = axios.CancelToken.source();
      const request = await axiosInstance.get(API_ROUTES.SOLICITATIONS, {
        cancelToken: cancelToken.current.token,
        params: {
          page: page + 1,
          page_size: rowsPerPage,
          patient__name__icontains: filters.name || undefined,
          status: filters.status || undefined,
          priority: filters.priority || undefined,
          created_at__gte: filters.date_gte || undefined,
          created_at__lte: filters.date_lte || undefined,
        },
      });

      const solicitations: Solicitation[] = [];

      request.data.results.forEach((el: any) => {
        const solicitation: Solicitation = {
          id: el.id,
          patient: {
            name: el.patient.name,
            age: el.patient.age,
            picture: el.patient.picture,
            fcmToken: el.patient.fcm_token,
          },
          priority: el.priority,
          status: el.status,
          symptomsDate: convertDateFromISO8601ToPtBR(el.symptom_begin_date),
          solicitationDate: convertDateFromISO8601ToPtBR(
            el.created_at.split('T')[0],
          ),
          observations: el.informations,
          finalization: el.finalized,
          symptoms: el.symptoms.length
            ? el.symptoms.map((symptom: any) => symptom.title)
            : ['Nenhuma condição'],
          conditions: el.conditions.length
            ? el.conditions.map((condition: any) => condition.title)
            : ['Nenhuma condição'],
        };
        solicitations.push(solicitation);
      });

      setCount(request.data.count);
      setRows(solicitations);
      setLoading(false);

      cancelToken.current = null;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      }

      setRows([]);
      setLoading(false);
      cancelToken.current = null;

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.detail ||
          'Ocorreu um erro ao carregar as solicitações.',
      });
    }
  }

  useEffect(() => {
    fetchSolicitations(page, debouncedFilters);

    const interval = setInterval(() => {
      fetchSolicitations(page, debouncedFilters, true);
    }, 10000);

    return () => clearInterval(interval);
  }, [debouncedFilters, page]);

  useEffect(() => () => cancelToken.current?.cancel(), []);

  return (
    <>
      <SolicitationModal
        handleClose={() => {
          setModalOpen(false);
        }}
        handleExited={() => {
          setSelected(null);
        }}
        open={modalOpen}
        solicitation={selected}
        forceRefresh={() => fetchSolicitations(0, debouncedFilters)}
      />

      <PageHeader title="Solicitações de Atendimento" />

      <div className={classes.filtersContainer}>
        <TextField
          variant="outlined"
          label="Filtrar por nome"
          value={searchFilters.name}
          onChange={(e) => {
            setSearchFilters((filters) => ({
              ...filters,
              name: e.target.value,
            }));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <FormControl variant="outlined">
          <InputLabel>Filtrar por Status</InputLabel>
          <Select
            label="Filtrar por Status"
            className={classes.statusSelect}
            value={searchFilters.status}
            onChange={(e) =>
              setSearchFilters((filters) => ({
                ...filters,
                status: e.target.value as string,
              }))
            }
          >
            <MenuItem value="">Nenhum</MenuItem>
            {Object.keys(Status).map((status) => (
              <MenuItem key={status} value={Status[status]}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <InputLabel>Filtrar por Prioridade</InputLabel>
          <Select
            label="Filtrar por Prioridade"
            className={classes.prioritySelect}
            value={searchFilters.priority}
            onChange={(e) =>
              setSearchFilters((filters) => ({
                ...filters,
                priority: e.target.value as string,
              }))
            }
          >
            <MenuItem value="">Nenhuma</MenuItem>
            {Object.keys(PriorityRange).map((priority) => (
              <MenuItem key={priority} value={PriorityRange[priority]}>
                {priority}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label="Filtrar por data (início)"
          value={searchFilters.date_gte}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSearchFilters((filters) => ({
              ...filters,
              date_gte: e.target.value,
            }));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="outlined"
          label="Filtrar por data (fim)"
          value={searchFilters.date_lte}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSearchFilters((filters) => ({
              ...filters,
              date_lte: e.target.value,
            }));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* set a small width to keep the cell small */}
              <TableCell width={1} />
              <TableCell>Paciente</TableCell>
              <TableCell align="center">Idade</TableCell>
              <TableCell align="center">Prioridade</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Data de solicitação</TableCell>
              <TableCell width={80} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row) => (
                <SolicitationsRow
                  key={row.id}
                  solicitation={row}
                  setSelected={(solicitation: Solicitation) => {
                    setSelected(solicitation);
                    setModalOpen(true);
                  }}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="body2">
                    Nenhuma solicitação encontrada
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}
