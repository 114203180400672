interface RiskGroup {
  id: string;
  title: string;
}

const riskGroupsList: RiskGroup[] = [
  { id: 'ges', title: 'Gestantes' },
  { id: 'has', title: 'Hipertensos' },
  { id: 'dia', title: 'Diabéticos' },
  { id: 'han', title: 'Hanseníase' },
  { id: 'tub', title: 'Tuberculosos' },
  { id: 'fum', title: 'Fumantes' },
  { id: 'alc', title: 'Alcóolatras' },
  { id: 'aca', title: 'Acamados' },
  { id: 'defi', title: 'Deficientes' },
  { id: 'nis', title: 'Baixa renda' },
  { id: 'rua', title: 'Moradores de rua' },
  { id: 'inc', title: 'Cadastros com erro' },
];

export default riskGroupsList;
