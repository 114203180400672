import {
  Dashboard,
  Group,
  Assessment,
  SupervisedUserCircle,
} from '@material-ui/icons';
import {
  Attendances,
  Citizens,
  Indicators,
  PrevineDashboard,
} from '../../../screens';
import { APP_ROUTES, PrivateRoute } from '..';

const routes: PrivateRoute[] = [
  {
    title: 'Previne Brasil',
    path: '/previne/',
    exact: true,
    icon: Assessment,
    permissions: ['previne'],
    separator: true,
    nestedPages: [
      {
        title: 'Painel de Informações',
        path: APP_ROUTES.PREVINE_DASHBOARD,
        component: PrevineDashboard,
        icon: Dashboard,
        permissions: ['previne.info_panel'],
      },
      {
        title: 'Indicadores',
        path: APP_ROUTES.INDICATORS,
        component: Indicators,
        icon: Assessment,
        permissions: ['previne.indicators'],
        maxWidth: 'xl',
      },
      {
        title: 'Atendimentos',
        path: APP_ROUTES.ATTENDANCES,
        component: Attendances,
        icon: SupervisedUserCircle,
        permissions: ['previne.attendances'],
      },
      {
        title: 'Cidadãos',
        path: APP_ROUTES.CITIZENS,
        component: Citizens,
        icon: Group,
        permissions: ['previne.citizens'],
      },
    ],
  },
];

export default routes;
