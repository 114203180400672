import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface CardWithTableProps {
  title: string;
  subtitle?: string;
  headers: string[];
  data: (string | number)[][];
  loading?: boolean;
}

function CardWithTable(props: CardWithTableProps) {
  const { loading = false } = props;

  return (
    <Card style={{ borderRadius: 0 }}>
      <CardContent>
        <Typography variant="h6" component="h2">
          {props.title}
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
          style={
            props.subtitle
              ? undefined
              : {
                  opacity: 0,
                  userSelect: 'none',
                }
          }
        >
          {props.subtitle || 'Placeholder'}
        </Typography>

        <TableContainer
          component={Paper}
          style={{
            marginTop: '16px',
            height: 'calc(100vh - 343px)',
            overflowY: 'auto',
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {props.headers.map((header, idx) => (
                  <TableCell
                    key={idx}
                    style={{ fontWeight: 'bold', padding: '6px' }}
                    align={
                      props.headers.length - 1 === idx ? 'right' : undefined
                    }
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(Array(8)).map((_, idx) => {
                    return (
                      <Row
                        key={idx}
                        row={Array(props.headers.length).fill(null)}
                        headersLength={props.headers.length}
                      />
                    );
                  })
                : props.data.map((row, idx) => {
                    return (
                      <Row
                        key={idx}
                        row={row}
                        headersLength={props.headers.length}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default CardWithTable;

interface RowProps {
  row: (string | number | null)[];
  headersLength: number;
}

function Row({ row, headersLength }: RowProps) {
  return (
    <TableRow>
      {row.map((value, idx) => {
        return (
          <TableCell
            key={idx}
            align={headersLength - 1 === idx ? 'right' : undefined}
            style={{ padding: '6px' }}
          >
            {value === null ? (
              <Skeleton
                width={100}
                height={30}
                style={headersLength - 1 === idx ? { marginLeft: 'auto' } : {}}
              />
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
