import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import PageHeader from '../../components/PageHeader';
import { useMessage } from '../../context/message';
import maskNumber from '../../utils/maskNumber';
import EditProfessional from './EditProfessional';
import Professional from './professional.interface';

const rowsPerPage = 15;

function Professionals() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<Professional[]>([]);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [searchName, setName] = useState('');
  const [refreshCount, setRefresh] = useState(0);
  const [
    selectedProfessional,
    setSelectedProfessional,
  ] = useState<Professional | null>(null);

  const message = useRef(useMessage());

  function getFilterType(val: string) {
    const isNumber = !isNaN(+val);
    if (isNumber) {
      switch (val.length) {
        case 11:
          return 'cpf_prof';
        case 15:
          return 'cod_cns';
      }
    }
    return 'nome_prof__icontains';
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.PROFESSIONALS, {
          cancelToken: source.token,
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            [getFilterType(searchName)]: searchName || undefined,
          },
        });

        setCount(response.data.count);
        setLoading(false);
        setRows(
          response.data.results.map(
            (professional: any): Professional => ({
              id: professional.id,
              name: professional.nome_prof,
              cpf: professional.cpf_prof,
              cns: professional.cod_cns,
              cbo: professional.cod_cbo,
            }),
          ),
        );
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setRows([]);
        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.detail ||
            'Ocorreu um erro ao carregar a lista de profissionais.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, searchName, refreshCount]);

  function refresh() {
    setRefresh((refresh) => refresh + 1);
    setSelectedProfessional(null);
    setPage(0);
  }

  return (
    <>
      <PageHeader title="Profissionais" />

      <EditProfessional
        professional={selectedProfessional}
        refresh={refresh}
        onClose={() => {
          setSelectedProfessional(null);
        }}
      />

      <div style={{ marginBottom: '24px' }}>
        <TextField
          label="Filtrar por Nome/CPF/CNS"
          variant="outlined"
          value={searchName}
          onChange={(e) => {
            setPage(0);
            setName(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 0 }} />
              <TableCell>Nome</TableCell>
              <TableCell align="center">CPF</TableCell>
              <TableCell align="center">CNS</TableCell>
              <TableCell align="center">CBO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedProfessional(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="center">
                    {maskNumber(row.cpf, '###.###.###-##')}
                  </TableCell>
                  <TableCell align="center">{row.cns}</TableCell>
                  <TableCell align="center">{row.cbo}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default Professionals;
