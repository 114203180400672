import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import { GetApp, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import TablePaginationActions from 'components/TablePaginationActions';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { axiosInstance } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { useMessage } from '../../../context/message';
import useCsvExporter from '../../../hooks/useCsvExporter';
import useProfessionals, {
  Professional,
} from '../../../hooks/useProfessionals';
import useTeams, { Team } from '../../../hooks/useTeams';
import maskNumber from '../../../utils/maskNumber';
import AttendancesTypes from './utils/attendanceTypes';
import { formatHeaders, formatRows } from './utils/format';
import getFilters from './utils/getFilters';
import { getURLByType, getExportURLByType } from './utils/getURL';

function Attendances() {
  const [attendanceType, setAttendanceType] = useState(
    AttendancesTypes.Consolidated,
  );
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rows, setRows] = useState<string[][]>([]);
  const [loading, setLoading] = useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const [professional, setSelectedProf] = useState<Professional | null>(null);
  const [team, setSelectedTeam] = useState<Team | null>(null);
  const [competence, setCompetence] = useState('');
  const [profFilter, setProfFilter] = useState('');
  const [headers, setHeaders] = useState<string[]>([
    'Equipe',
    'Profissional',
    'Procedimento',
    'Total',
  ]);

  const source = useRef<CancelTokenSource | null>(null);
  const teams = useTeams();
  const exporter = useCsvExporter();
  const message = useRef(useMessage());
  const { professionals, setTeam: setFilterTeam } = useProfessionals();

  async function exportToCsv() {
    const comp = competence.split('/').reverse().join('').replace(/[\D]/g, '');
    const filename =
      attendanceType === AttendancesTypes.Consolidated
        ? 'atendimentos-consolidado.csv'
        : attendanceType === AttendancesTypes.DaysWorked
        ? 'atendimentos-dias-trabalhados.csv'
        : 'atendimentos-procedimentos.csv';

    exporter.exportToCsv(
      getExportURLByType(attendanceType),
      filename,
      getFilters(comp, attendanceType, profFilter, team, professional),
    );
  }

  const fetchAttendances = useCallback(async () => {
    const comp = competence.split('/').reverse().join('').replace(/[\D]/g, '');

    if (comp.length > 0 && comp.length !== 6) {
      return;
    }

    source.current?.cancel();
    source.current = axios.CancelToken.source();

    setLoading(true);
    setRows([]);
    setHeaders([]);

    try {
      const params = getFilters(
        comp,
        attendanceType,
        profFilter,
        team,
        professional,
      );
      params.page = page + 1;

      const response = await axiosInstance.get(getURLByType(attendanceType), {
        cancelToken: source.current!.token,
        params,
      });

      const results = response.data.results || response.data;

      if (results.length === 0) {
        setLoading(false);
        setCount(0);
        setCanFetch(false);
        return;
      }

      const headers = formatHeaders(Object.keys(results[0]), attendanceType);
      const rows = formatRows(
        results.map((item: any) => Object.values(item)),
        attendanceType,
      );
      const count =
        attendanceType === AttendancesTypes.DaysWorked
          ? results.length
          : response.data.count || -1;

      setHeaders(headers);
      setRows(rows);
      setCount(count);

      setLoading(false);
      setCanFetch(false);
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return;
      }

      setLoading(false);
      setCanFetch(false);

      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data.detail ||
          'Ocorreu um erro ao carregar os atendimentos.',
      });
    }
  }, [attendanceType, competence, profFilter, page, professional, team]);

  useEffect(() => {
    if (canFetch) {
      fetchAttendances();
    }
  }, [canFetch, fetchAttendances]);

  useEffect(() => {
    return () => {
      source.current?.cancel();
    };
  }, []);

  // I'm not sure if this is the best/correct way to do this,
  // but there were lots of performance issues when the table was being re-rendered
  // after scrolling the side menu or changing the filters.
  // This seems to solve the issue.
  const tableContent = useMemo(
    () =>
      rows.map((row, idx) => (
        <TableRow key={idx}>
          {row.map((cell, idx) => (
            <TableCell
              key={idx}
              align="center"
              style={{ whiteSpace: 'nowrap' }}
            >
              {cell}
            </TableCell>
          ))}
        </TableRow>
      )),
    [rows],
  );

  return (
    <>
      <PageHeader title="Atendimentos" />

      <div
        style={{
          display: 'flex',
          gap: '16px',
          marginBottom: '1rem',
          flexWrap: 'wrap',
        }}
      >
        <FormControl variant="outlined">
          <InputLabel>Listagem</InputLabel>
          <Select
            label="Listagem"
            value={attendanceType}
            onChange={(e) => {
              setAttendanceType(e.target.value as number);
              setRows([]);
              setHeaders(['Equipe', 'Profissional', 'Procedimento', 'Total']);
              setPage(0);
              setCount(0);
            }}
          >
            <MenuItem value={AttendancesTypes.Consolidated}>
              Consolidado
            </MenuItem>
            <MenuItem value={AttendancesTypes.Procedures}>
              Procedimentos
            </MenuItem>
            <MenuItem value={AttendancesTypes.DaysWorked}>
              Dias Trabalhados
            </MenuItem>
          </Select>
        </FormControl>

        {/* TODO: add autocomplete */}
        <TextField
          variant="outlined"
          label="Competência"
          placeholder="mm/aaaa"
          value={competence}
          style={{ width: 125 }}
          onChange={(e) => {
            setCompetence(maskNumber(e.target.value, '##/####'));
          }}
        />

        {[AttendancesTypes.Procedures, AttendancesTypes.Consolidated].includes(
          attendanceType,
        ) && (
          <Autocomplete
            autoHighlight
            options={teams}
            getOptionLabel={(option) => option.no_equipe}
            style={{ width: 200 }}
            noOptionsText="Nenhuma equipe encontrada"
            onChange={(_e, val) => {
              setSelectedTeam(val);
              setSelectedProf(null);
              setFilterTeam(val);
            }}
            value={team}
            getOptionSelected={(option, value) =>
              option.no_equipe === value.no_equipe
            }
            renderInput={(params) => (
              <TextField label="Equipe" variant="outlined" {...params} />
            )}
          />
        )}

        {attendanceType === AttendancesTypes.Procedures && (
          <Autocomplete
            autoHighlight
            options={professionals}
            getOptionLabel={(option) => option.no_profissional}
            noOptionsText="Nenhum profissional encontrado"
            style={{ width: 300 }}
            onChange={(_e, val) => {
              setSelectedProf(val);
            }}
            value={professional}
            getOptionSelected={(option, value) =>
              option.no_profissional === value.no_profissional
            }
            renderInput={(params) => (
              <TextField label="Profissional" variant="outlined" {...params} />
            )}
          />
        )}

        {/* TODO: add autocomplete */}
        {attendanceType === AttendancesTypes.DaysWorked && (
          <TextField
            variant="outlined"
            label="Profissional"
            value={profFilter}
            onChange={(e) => setProfFilter(e.target.value)}
          />
        )}

        <Button
          color="primary"
          variant="outlined"
          disabled={loading}
          startIcon={<Search />}
          onClick={() => {
            setPage(0);
            setCanFetch(true);
          }}
        >
          Pesquisar
        </Button>

        <Button
          color="primary"
          variant="outlined"
          startIcon={
            exporter.isExporting ? (
              <CircularProgress size={14} disableShrink />
            ) : (
              <GetApp />
            )
          }
          onClick={exportToCsv}
          disabled={exporter.isExporting}
        >
          Exportar
        </Button>
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {loading ? (
                <TableCell align="center">
                  <CircularProgress />
                </TableCell>
              ) : (
                (headers.length > 0
                  ? headers
                  : ['Nenhum atendimento encontrado']
                ).map((header) => (
                  <TableCell
                    key={header}
                    style={{
                      textTransform: 'capitalize',
                      whiteSpace: 'nowrap',
                    }}
                    align="center"
                  >
                    {header}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ActionsComponent={TablePaginationActions}
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={
          attendanceType === AttendancesTypes.DaysWorked ? count : 15
        }
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
          setCanFetch(true);
        }}
      />
    </>
  );
}

export default Attendances;
