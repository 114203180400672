import { API_ROUTES } from '../../../../api';
import AttendancesTypes from './attendanceTypes';

export function getURLByType(type: AttendancesTypes) {
  switch (type) {
    case AttendancesTypes.Consolidated:
      return API_ROUTES.ESUS_ATTENDANCE_CONSOLIDATED;
    case AttendancesTypes.DaysWorked:
      return API_ROUTES.ESUS_ATTENDANCE_DAYS_WORKED;
    case AttendancesTypes.Procedures:
      return API_ROUTES.ESUS_ATTENDANCE_PROCEDURES;
  }
}

export function getExportURLByType(type: AttendancesTypes) {
  switch (type) {
    case AttendancesTypes.Consolidated:
      return API_ROUTES.CSV_EXPORTER_ATTENDANCE_CONSOLIDATED;
    case AttendancesTypes.DaysWorked:
      return API_ROUTES.CSV_EXPORTER_ATTENDANCE_DAYS_WORKED;
    case AttendancesTypes.Procedures:
      return API_ROUTES.CSV_EXPORTER_ATTENDANCE_PROCEDURES;
  }
}
