import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import axios from 'axios';
import { useRef, useState, useEffect } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../api';
import { useAuth } from '../../../../context/auth';
import { useMessage } from '../../../../context/message';
import { useStyles } from './logoPicker.styles';

interface Props {
  title: string;
  imageName: string;
  currentImage: string | null;
}

function LogoPicker(props: Props) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const message = useMessage();
  const auth = useAuth();
  const classes = useStyles();

  useEffect(() => {
    if (selectedImage) {
      const url = URL.createObjectURL(selectedImage);
      setImageURL(url);

      return () => {
        URL.revokeObjectURL(url);
        setImageURL(null);
      };
    }
  }, [selectedImage]);

  // TODO: cleanup the axios request
  async function saveImage() {
    try {
      if (selectedImage) {
        setIsUploading(true);
        const form = new FormData();
        const arrayBuffer = (await selectedImage.arrayBuffer()) as ArrayBufferLike;
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: selectedImage.type,
        });
        form.append(props.imageName, blob, selectedImage.name || '');
        await axiosInstance.put(API_ROUTES.CITY_CHANGE_PICTURE, form);

        await auth.refreshProfile();
        setSelectedImage(null);
        message.setMessage({
          type: 'success',
          text: 'Logo atualizada com sucesso',
        });
      }
    } catch (error) {
      let errorMessage = 'Ocorreu um erro ao salvar a logo';
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data.message || errorMessage;
      }

      message.setMessage({ type: 'error', text: errorMessage });
    } finally {
      setIsUploading(false);
    }
  }

  // TODO: cleanup the axios request
  async function removeImage() {
    try {
      const form = new FormData();
      form.append(props.imageName, 'null');
      await axiosInstance.put(API_ROUTES.CITY_CHANGE_PICTURE, form);

      await auth.refreshProfile();
      message.setMessage({
        type: 'success',
        text: 'Logo removida com sucesso',
      });
    } catch (error) {
      let errorMessage = 'Ocorreu um erro ao remover a logo';
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data.message || errorMessage;
      }

      message.setMessage({ type: 'error', text: errorMessage });
    }
  }

  function cancelImagePick() {
    setSelectedImage(null);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5">{props.title}</Typography>
        <div
          onClick={() => {
            inputRef.current?.click();
          }}
          className={classes.logoContainer}
        >
          {imageURL ? (
            <img src={imageURL} alt={props.title} className={classes.logoImg} />
          ) : props.currentImage ? (
            <img
              src={props.currentImage}
              alt={props.title}
              className={classes.logoImg}
            />
          ) : (
            <IconButton className={classes.noLogoImg}>
              <WallpaperIcon fontSize="inherit" />
            </IconButton>
          )}
        </div>
        <input
          type="file"
          className={classes.fileInput}
          accept=".jpg,.jpeg,.png"
          ref={(r) => (inputRef.current = r)}
          onChange={(e) => {
            setSelectedImage(e.target.files?.[0] || null);
          }}
        />
        <div className={classes.hints}>
          {['Clique para selecionar uma imagem', 'Tamanho máximo 1 MB'].map(
            (msg, idx) => (
              <Typography key={idx} variant="caption" component="h6">
                {msg}
              </Typography>
            ),
          )}
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        {selectedImage ? (
          <>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              disabled={isUploading}
              onClick={cancelImagePick}
            >
              Cancelar
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled={isUploading}
              onClick={saveImage}
            >
              Salvar
            </Button>
          </>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={removeImage}
            disabled={!props.currentImage}
          >
            Remover
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default LogoPicker;
