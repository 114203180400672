import { Paper, Tab, useMediaQuery } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { axiosInstance, API_ROUTES } from '../../../api';
import { useMessage } from '../../../context/message';
import riskGroupsList from './utils/riskGroups';
import CardWithCircles from './CardWithCircles';
import CardWithTable from './CardWithTable';
import Capitacao from './utils/capitacao.interface';
import { useStyles } from './previneDashboard.styles';
import PageHeader from '../../../components/PageHeader';

function PrevineDashboard() {
  const message = useRef(useMessage());
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [capitacao, setCapitacao] = useState<Capitacao[]>([]);
  const [riskGroups, setRiskGroups] = useState<string[][]>([]);
  const [procedures, setProcedures] = useState<string[][]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string>('1');

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.ESUS_DASHBOARD, {
          cancelToken: source.token,
        });

        setCapitacao(response.data.capacitacao);

        const groups: string[][] = [];
        Object.keys(response.data.grupos_risco[0]).forEach((group: string) => {
          const riskGroupItem = riskGroupsList.find((g) => g.id === group);

          if (riskGroupItem) {
            groups.push([
              riskGroupItem.title,
              response.data.grupos_risco[0][group],
            ]);
          }
        });
        setRiskGroups(groups);

        setProcedures(
          response.data.procedimento_mes.map((proc: any) => [
            proc.ds_proced,
            proc.total,
          ]),
        );

        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar as estatísticas',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  function capitacaoTotal() {
    const r = capitacao.reduce(
      (prev, cur) => {
        return {
          total: prev.total + cur.total,
          meta: prev.meta + cur.meta,
          valor: prev.valor + +cur.valor,
        };
      },
      { total: 0, meta: 0, valor: 0 },
    );

    return {
      label: [
        'TOTAL DE CADASTROS',
        `Meta ${r.meta} (${r.total} cadastros realizados)`,
        `Estimativa em valores: R$ ${(+r.valor).toLocaleString()} mensais`,
      ],
      value: Math.round((r.total / r.meta) * 100),
    };
  }

  return (
    <>
      <PageHeader title="Painel de Informações" />

      <TabContext value={selectedTab}>
        <Paper square elevation={4}>
          <TabList
            onChange={(event, value) => setSelectedTab(value)}
            aria-label="Informações"
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            scrollButtons="on"
            indicatorColor="primary"
          >
            {[
              'CAPITAÇÃO PONDERADA (URBANO)',
              'GRUPOS DE RISCO',
              isMobile
                ? 'PROCEDIMENTOS/MÊS'
                : 'PROCEDIMENTOS REALIZADOS NO MÊS',
            ].map((label, index) => (
              <Tab
                key={`tab-${index}`}
                label={label}
                value={`${index + 1}`}
                className={classes.tabBgColor}
              />
            ))}
          </TabList>
        </Paper>
        <TabPanel value="1" className={classes.tabPanel}>
          <CardWithCircles
            title="CAPITAÇÃO PONDERADA (URBANO)"
            subtitle="*valores apenas da base local, sendo necessário consultar o SISAB
              pra ter informação mais precisa."
            loading={loading}
            data={[
              ...capitacao.map((cap) => {
                return {
                  label: [
                    `${cap.ine} - ${cap.equipe}`,
                    `Meta ${cap.meta} (${cap.total} cadastros realizados)`,
                    `Estimativa em valores: R$ ${(+cap.valor).toLocaleString()} mensais`,
                  ],
                  value: Math.round((cap.total / cap.meta) * 100),
                };
              }),
              capitacao.length > 0 ? capitacaoTotal() : null,
            ]}
          />
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <CardWithTable
            title="GRUPOS DE RISCO"
            subtitle="Número de cidadãos que estão enquadrados nos grupos de risco."
            headers={['Grupo de risco', 'Quantidade']}
            data={riskGroups}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value="3" className={classes.tabPanel}>
          <CardWithTable
            title="PROCEDIMENTOS REALIZADOS NO MÊS"
            headers={['Procedimento', 'Quantidade']}
            data={procedures}
            loading={loading}
          />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default PrevineDashboard;
