import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Tabs,
  Tab,
  AppBar,
  TableHead,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Procedure from '../procedure.interface';
import { useStyles } from './styles';

interface Props {
  procedure: Procedure | null;
  close: () => void;
}

function ProceduresDetails(props: Props) {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  function getComplexity(complexity: string) {
    switch (complexity) {
      case '1':
        return 'Atenção Básica';
      case '2':
        return 'Média Complexidade';
      case '3':
        return 'Alta Complexidade';
      default:
        return complexity;
    }
  }

  function getGender(gender: string) {
    switch (gender) {
      case 'N':
        return 'N/A';
      case 'I':
        return 'Ambos';
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Feminino';
      default:
        return gender;
    }
  }

  function getFormattedAge(age: number) {
    return age === 9999
      ? 'Não se aplica'
      : age < 12
      ? `${age} meses`
      : `${Math.floor(age / 12)} anos`;
  }

  function getFinancing(financing: string) {
    switch (financing) {
      case '01':
        return 'Piso de Atenção Básica (PAB)';
      case '02':
        return 'Assistência de Média e Alta Complexidade (MAC)';
      case '04':
        return 'Fundo de Ações Estratégicas e Compensação (FAEC)';
      case '05':
        return 'Incentivo MAC';
      case '06':
        return 'Assistência Farmacêutica';
      case '07':
        return 'Vigilância em Saúde';
      case '08':
        return 'Gestão do SUS';
      default:
        return financing;
    }
  }

  useEffect(() => {
    setTab(0);
  }, [props.procedure]);

  // TODO: use a loop to generate the details table
  return (
    <Dialog
      open={!!props.procedure}
      onClose={props.close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{`${props.procedure?.co_procedimento} - ${props.procedure?.no_procedimento}`}</DialogTitle>
      <DialogContent>
        <AppBar position="static" color="default">
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, tab) => setTab(tab)}
            variant="fullWidth"
          >
            <Tab label="Detalhes" />
            <Tab label="CID" disabled={props.procedure?.cids.length === 0} />
            <Tab label="CBO" disabled={props.procedure?.cbos.length === 0} />
          </Tabs>
        </AppBar>

        <TableContainer
          component={Paper}
          className={classes.detailsTableContainer}
        >
          <Table size="small" stickyHeader>
            {/* DETAILS */}
            {tab === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Complexidade
                  </TableCell>
                  <TableCell>
                    {getComplexity(props.procedure?.tp_complexidade || '')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Financiamento
                  </TableCell>
                  <TableCell>
                    {getFinancing(props.procedure?.co_financiamento || '')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>Sexo</TableCell>
                  <TableCell>
                    {getGender(props.procedure?.tp_sexo || '')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Tempo de Permanência
                  </TableCell>
                  <TableCell>
                    {props.procedure?.qt_tempo_permanencia === 9999
                      ? ''
                      : props.procedure?.qt_tempo_permanencia}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Quantidade Máxima
                  </TableCell>
                  <TableCell>
                    {props.procedure?.qt_maxima_execucao === 9999
                      ? ''
                      : props.procedure?.qt_maxima_execucao}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Idade Mínima
                  </TableCell>
                  <TableCell>
                    {getFormattedAge(props.procedure?.vl_idade_minima || 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Idade Máxima
                  </TableCell>
                  <TableCell>
                    {getFormattedAge(props.procedure?.vl_idade_maxima || 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTitle}>
                    Pontos
                  </TableCell>
                  <TableCell>{props.procedure?.qt_pontos}</TableCell>
                </TableRow>
              </TableBody>
            )}

            {/* CID */}
            {tab === 1 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.procedure?.cids.map((cid) => (
                    <TableRow key={cid.co_cid}>
                      <TableCell>{cid.co_cid}</TableCell>
                      <TableCell>{cid.no_cid}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}

            {/* CBO */}
            {tab === 2 && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.procedure?.cbos.map((cbo) => (
                    <TableRow key={cbo.co_ocupacao}>
                      <TableCell>{cbo.co_ocupacao}</TableCell>
                      <TableCell>{cbo.no_ocupacao}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

export default ProceduresDetails;
