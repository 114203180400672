import { Button, TextField } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import Solicitation from '../utils/solicitation.interface';

interface FinishSolicitationModalProps {
  open: boolean;
  solicitation: Solicitation;
  handleClose: () => void;
  handleSuccess: () => void;
}

function FinishSolicitationModal(props: FinishSolicitationModalProps) {
  const [reason, setReason] = useState('');
  const message = useMessage();

  async function handleSubmit() {
    if (!reason.trim()) {
      return;
    }

    try {
      await axiosInstance.post(API_ROUTES.SOLICITATIONS_FINISH, {
        solicitation: props.solicitation?.id,
        reason: reason.trim(),
      });

      message.setMessage({
        type: 'success',
        text: 'Solicitação finalizada com sucesso',
      });

      props.handleSuccess();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          'Ocorreu um erro ao finalizar a solicitação',
      });
    }
  }

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h6">Finalizar Solicitação</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Digite o motivo para a finalização"
          rows={5}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          fullWidth
          required
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>

        <Button color="primary" onClick={handleSubmit}>
          Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FinishSolicitationModal;
