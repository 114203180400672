import { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useMessage } from '../../context/message';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import maskNumber from '../../utils/maskNumber';
import { API_ROUTES, axiosInstance } from '../../api';
import downloadBlob from '../../utils/downloadBlob';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';

interface City {
  nome: string;
  sigla_estado: string;
  codigo: string;
}

function ExportBpa() {
  const [filename, setFilename] = useState('PA');
  const [competence, setCompetence] = useState('');
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [cities, setCities] = useState<City[]>([]);

  const [cnesOrigem, setCnesOrigem] = useState('');
  const [cnesDestino, setCnesDestino] = useState('');
  const [rsp, setRsp] = useState('');
  const [sgl, setSgl] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [munSolicitante, setMunSolicitante] = useState<City | null>(null);
  const [dst, setDst] = useState('');
  const [dstIn, setDstIn] = useState<'' | 'E' | 'M'>('');
  const [versao, setVersao] = useState('');

  const message = useMessage();

  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const responseCities = await axiosInstance.get<{ cities: City[] }>(
          API_ROUTES.EXPORTER_BPA_CITIES_LIST,
        );
        setCities(responseCities.data.cities);

        // const responseFormData = await axiosInstance.get(
        //   API_ROUTES.EXPORTER_BPA_FORM_DATA,
        // );
        // const data = responseFormData.data;

        // setCnesOrigem(data.cnes_origem);
        // setCnesDestino(data.cnes_destino);
        // setRsp(data.cbc_rsp);
        // setSgl(data.cbc_sgl);
        // setCNPJ(data.cbc_cgccpf);
        // setDst(data.cbc_dst);
        // setDstIn(data.cbc_dst_in);
        // setVersao(data.cbc_versao);
        // setMunSolicitante(
        //   responseCities.data.cities.find(
        //     (c) => c.codigo.toString() === data.mun_solicitante,
        //   ) || null,
        // );
      } catch (error) {
        // TODO: show a warning if there is an error while loading previous form data or city list
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  function getExtension() {
    const err =
      'Certifique-se de que você preencheu a competência corretamente.';

    if (competence.length !== 7) {
      throw new Error(err);
    }

    const month = +competence.split('/')[0];
    const extensions = [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ];

    const extension = extensions[month - 1];

    if (!extension) {
      throw Error(err);
    }

    return extension;
  }

  function validate() {
    const valid = ![competence, rsp, sgl, cnpj, dst, dstIn, versao]
      .map(Boolean)
      .includes(false);

    if (!valid) {
      throw new Error('Certifique-se de que você preencheu todos os campos');
    }
  }

  async function handleDownloadFile() {
    try {
      setExporting(true);

      validate();

      // TODO add cancel token
      const response = await axiosInstance.post(
        API_ROUTES.EXPORTER_BPA,
        {
          competence,
          cnes_origem: cnesOrigem || undefined,
          cnes_destino: cnesDestino || undefined,
          cbc_rsp: rsp,
          cbc_sgl: sgl,
          cbc_cgccpf: cnpj,
          cbc_dst: dst,
          cbc_dst_in: dstIn,
          cbc_versao: versao,
          mun_solicitante: munSolicitante?.codigo || undefined,
        },
        { responseType: 'blob' },
      );

      const extension = getExtension();
      const downloadName = `${filename.padEnd(8, '-')}.${extension}`;

      downloadBlob(response.data, downloadName);
    } catch (error: any) {
      let msg = error.message || 'Ocorreu um erro ao exportar o arquivo.';

      if (error.response?.data?.type === 'application/json') {
        const err = JSON.parse(await error.response.data.text());
        msg = err.message || Object.values(err)[0] || msg;
      }

      message.setMessage({
        type: 'warning',
        text: msg,
      });
    } finally {
      setExporting(false);
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}>
        <TextField
          required
          variant="outlined"
          label="Nome do Arquivo"
          value={filename}
          disabled={exporting}
          onChange={(e) => {
            // The filename must always start with PA and have a maximum of 8 characters
            const name = e.target.value;
            const newName = 'PA' + (name.match(/^PA(\w{1,6})/)?.[1] || '');

            setFilename(newName.toUpperCase());
          }}
        />

        <TextField
          required
          variant="outlined"
          label="Competência"
          placeholder="mm/aaaa"
          value={competence}
          disabled={exporting}
          onChange={(e) => {
            setCompetence(maskNumber(e.target.value, '##/####'));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={handleDownloadFile}
          disabled={exporting}
        >
          Exportar BPA
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          gap: 12,
          flexWrap: 'wrap',
          marginTop: 12,
          maxWidth: 900,
        }}
      >
        <Autocomplete
          autoHighlight
          disabled={loading || exporting}
          options={cities}
          getOptionLabel={(option) => `${option.nome}/${option.sigla_estado}`}
          noOptionsText="Nenhum município encontrado"
          style={{ width: 300 }}
          onChange={(_e, val) => {
            setMunSolicitante(val);
          }}
          value={munSolicitante}
          getOptionSelected={(option, value) => option.nome === value.nome}
          renderInput={(params) => (
            <TextField label="Município" variant="outlined" {...params} />
          )}
        />

        <TextField
          variant="outlined"
          label="CNES (Origem)"
          value={cnesOrigem}
          disabled={loading || exporting}
          onChange={(e) =>
            setCnesOrigem(e.target.value.replace(/[^\d]/, '').substring(0, 7))
          }
        />

        <TextField
          variant="outlined"
          label="CNES (Destino)"
          value={cnesDestino}
          disabled={loading || exporting}
          onChange={(e) =>
            setCnesDestino(e.target.value.replace(/[^\d]/, '').substring(0, 7))
          }
        />

        <TextField
          required
          variant="outlined"
          label="Nome (Órgão de origem)"
          value={rsp}
          disabled={loading || exporting}
          onChange={(e) =>
            setRsp(
              e.target.value.replace(/[^a-zA-Z0-9. ]/g, '').substring(0, 30),
            )
          }
        />

        <TextField
          required
          variant="outlined"
          label="Sigla (Órgão de origem)"
          value={sgl}
          disabled={loading || exporting}
          onChange={(e) =>
            setSgl(
              e.target.value.replace(/[^a-zA-Z0-9. ]/g, '').substring(0, 6),
            )
          }
        />

        <TextField
          required
          variant="outlined"
          label="CNPJ"
          value={cnpj}
          disabled={loading || exporting}
          onChange={(e) =>
            setCNPJ(e.target.value.replace(/[^\d]/, '').substring(0, 14))
          }
        />

        <TextField
          required
          variant="outlined"
          label="Nome (Órgão de destino)"
          value={dst}
          disabled={loading || exporting}
          onChange={(e) =>
            setDst(
              e.target.value.replace(/[^a-zA-Z0-9. ]/g, '').substring(0, 40),
            )
          }
        />

        <TextField
          required
          variant="outlined"
          label="Versão do sistema"
          value={versao}
          disabled={loading || exporting}
          onChange={(e) =>
            setVersao(
              e.target.value.replace(/[^a-zA-Z0-9. ]/g, '').substring(0, 10),
            )
          }
        />

        <FormControl variant="outlined">
          <InputLabel required>Indicador (Órgão de destino)</InputLabel>
          <Select
            required
            label="Indicador (Órgão de destino)"
            variant="outlined"
            value={dstIn}
            disabled={loading || exporting}
            onChange={(event) => {
              const dstIn = event.target.value as '' | 'E' | 'M';
              setDstIn(dstIn);
            }}
            style={{ minWidth: 260 }}
          >
            <MenuItem value="">Selecione</MenuItem>
            <MenuItem value="E">Estadual</MenuItem>
            <MenuItem value="M">Municipal</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}

export default ExportBpa;
