import { API_ROUTES, axiosInstance } from '../../api';
import SolicitationCard, {
  SkeletonCard,
  SolicitationCardProps,
} from './SolicitationCard';
import { useEffect, useRef, useState } from 'react';
import { useMessage } from '../../context/message';
import axios from 'axios';
import useStyles from './dashboard.styles';
import { solicitationsList } from './utils/solicitationsList';
import MapComponent from './MapComponent';
import PageHeader from '../../components/PageHeader';

function Dashboard() {
  const classes = useStyles();
  const message = useRef(useMessage());
  const [solicitations, setSolicitations] = useState<SolicitationCardProps[]>(
    [],
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const request = await axiosInstance.get(
          API_ROUTES.SOLICITATIONS_STATISTICS,
          { cancelToken: source.token },
        );

        const data = request.data.totalPerStatus;
        const solicitations: SolicitationCardProps[] = [];

        Object.keys(data).forEach((stat: string) => {
          const solicitationItem = solicitationsList.find((s) => s.id === stat);
          const solicitation: SolicitationCardProps = {
            title: solicitationItem!.title,
            icon: solicitationItem!.icon,
            value: data[stat],
          };

          solicitations.push(solicitation);
        });

        setSolicitations(solicitations);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          text: 'Ocorreu um erro ao carregar as estatisticas.',
          type: 'error',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <PageHeader title="Painel de Controle de Solicitações" />

      <div className={classes.cardsContainer}>
        {solicitations.length === 0
          ? Array.from(Array(4).keys()).map((pos) => <SkeletonCard key={pos} />)
          : solicitations.map((solicitation) => (
              <SolicitationCard key={solicitation.title} {...solicitation} />
            ))}
      </div>

      <MapComponent />
    </>
  );
}

export default Dashboard;
