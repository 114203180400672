import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputAdornment,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { useVaccines } from './hooks';
import VaccineForm from './VaccineForm';
import { useState } from 'react';
import Vaccine from './interfaces';
import DeleteVaccine from './DeleteVaccine';
import { convertTimestamp } from '../../utils/date_converter';
import PageHeader from '../../components/PageHeader';

const rowsPerPage = 15;

function CalendarVaccine() {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedVaccine, setSelectedVaccine] = useState<Vaccine | null>(null);
  const {
    vaccines,
    loading,
    filters,
    setFilters,
    page,
    setPage,
    count,
    refresh,
  } = useVaccines();

  return (
    <>
      <PageHeader title="Calendário de Vacinas" />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
      >
        <div style={{ display: 'flex', gap: 10 }}>
          <TextField
            label="Nome"
            variant="outlined"
            value={filters.name__icontains || ''}
            onChange={(e) => {
              setPage(0);
              setFilters((filter) => ({
                ...filter,
                name__icontains: e.target.value || undefined,
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Sigla"
            variant="outlined"
            value={filters.acronym__icontains || ''}
            onChange={(e) => {
              setPage(0);
              setFilters((filter) => ({
                ...filter,
                acronym__icontains: e.target.value || undefined,
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ width: 160 }}
          />

          <FormControl variant="outlined">
            <InputLabel>Ativo</InputLabel>
            <Select
              style={{ minWidth: 120 }}
              value={filters.active || ''}
              onChange={(e) => {
                setPage(0);
                setFilters((filter) => ({
                  ...filter,
                  active: (e.target.value as string) || undefined,
                }));
              }}
              label="Ativo"
            >
              <MenuItem value="">SEM FILTRO</MenuItem>
              <MenuItem value="True">SIM</MenuItem>
              <MenuItem value="False">NÃO</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setEditOpen(true);
          }}
        >
          Nova Vacina
        </Button>
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center" style={{ width: 200 }}>
                Última Modificação
              </TableCell>
              <TableCell align="center" style={{ width: 110 }}>
                Sigla
              </TableCell>
              <TableCell align="center" style={{ width: 60 }}>
                Ativa
              </TableCell>
              <TableCell style={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : vaccines.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nenhuma vacina cadastrada
                </TableCell>
              </TableRow>
            ) : (
              vaccines.map((vaccine) => (
                <TableRow key={vaccine.id}>
                  <TableCell>{vaccine.name}</TableCell>
                  <TableCell align="center">
                    {convertTimestamp(vaccine.updated_at)}
                  </TableCell>
                  <TableCell align="center">{vaccine.acronym}</TableCell>
                  <TableCell align="center">
                    {vaccine.active ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      onClick={() => {
                        setSelectedVaccine(vaccine);
                        setEditOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedVaccine(vaccine);
                        setDeleteOpen(true);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />

      <VaccineForm
        open={editOpen}
        handleRefresh={refresh}
        selectedVaccine={selectedVaccine}
        handleClose={() => {
          setEditOpen(false);
          setSelectedVaccine(null);
        }}
      />

      <DeleteVaccine
        open={deleteOpen}
        handleRefresh={refresh}
        selectedVaccine={selectedVaccine}
        handleClose={() => {
          setDeleteOpen(false);
          setSelectedVaccine(null);
        }}
      />
    </>
  );
}

export default CalendarVaccine;
