export function getCurrentCompetence() {
  const now = new Date();

  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear();

  return `${month}/${year}`;
}

export function formatCompetence(competence: string) {
  return competence.replace(/(\d{4})(\d{2})/, '$2/$1');
}
