import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#266DD3',
  },
  menuButton: {
    marginRight: 12,
  },
  userDetails: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  userPicture: {
    cursor: 'pointer',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  userMenuHelper: {
    position: 'absolute',
    bottom: '-18px',
    right: '0',
  },
}));
