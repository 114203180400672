import {
  LocalHospital,
  ImportExport,
  Business,
  SupervisedUserCircle,
  Backup,
  CreditCard,
  Apps,
  GetApp,
} from '@material-ui/icons';
import {
  Imports,
  ImportedEstablishments,
  ImportedProfessionals,
  CNS,
  Cnes,
  Procedures,
  Exports,
} from '../../../screens';
import { APP_ROUTES, PrivateRoute } from '..';

const routes: PrivateRoute[] = [
  {
    title: 'Integração MS',
    path: APP_ROUTES.IMPORTS,
    exact: true,
    icon: ImportExport,
    permissions: ['integration'],
    separator: true,
    nestedPages: [
      {
        title: 'Importar',
        path: APP_ROUTES.IMPORTS_IMPORT,
        component: Imports,
        icon: Backup,
        permissions: ['integration.import_sigtap'],
      },
      {
        title: 'Estabelecimentos',
        path: APP_ROUTES.IMPORTED_ESTABLISHMENTS,
        component: ImportedEstablishments,
        icon: Business,
        permissions: ['integration.establishments'],
      },
      {
        title: 'Profissionais',
        path: APP_ROUTES.IMPORTED_PROFESSIONALS,
        component: ImportedProfessionals,
        icon: SupervisedUserCircle,
        permissions: ['integration.professionals'],
      },
      {
        title: 'Procedimentos',
        path: APP_ROUTES.IMPORTED_PROCEDURES,
        component: Procedures,
        icon: LocalHospital,
        permissions: ['integration.procedures'],
      },
      {
        title: 'Exportações',
        path: APP_ROUTES.EXPORTS,
        component: Exports,
        icon: GetApp,
        permissions: ['integration.exports'],
      },
      {
        title: 'CNES Online',
        path: APP_ROUTES.CNES,
        component: Cnes,
        icon: Apps,
        permissions: ['integration.cnes_online'],
      },
      {
        title: 'CNS Online',
        path: APP_ROUTES.CNS,
        component: CNS,
        icon: CreditCard,
        permissions: ['integration.cns_online'],
      },
    ],
  },
];

export default routes;
