import { People, Security, LocationCity } from '@material-ui/icons';
import { UsersAdministration, CitiesAdministration } from '../../../screens';
import { APP_ROUTES, PrivateRoute } from '..';

const routes: PrivateRoute[] = [
  {
    title: 'Administração',
    path: APP_ROUTES.ADMINISTRATION,
    exact: true,
    permissions: ['administration'],
    icon: Security,
    nestedPages: [
      {
        title: 'Usuários',
        path: APP_ROUTES.ADMINISTRATION_USERS,
        component: UsersAdministration,
        icon: People,
        permissions: ['administration.users'],
      },
      {
        title: 'Cidades',
        path: APP_ROUTES.ADMINISTRATION_CITIES,
        component: CitiesAdministration,
        icon: LocationCity,
        permissions: ['administration.cities'],
      },
    ],
  },
];

export default routes;
