import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Button,
  DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { Disease } from '../Diseases';

interface DeleteDiseaseModalProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  type: 'symptom' | 'disease';
  disease?: Disease;
}

function DeleteDiseaseModal(props: DeleteDiseaseModalProps) {
  const message = useMessage();

  async function handleSubmit() {
    if (!props.disease) {
      return;
    }

    try {
      const isSymptom = props.type === 'symptom';

      const url = (isSymptom
        ? API_ROUTES.SYMPTOMS_EDIT
        : API_ROUTES.CONDITIONS_EDIT
      ).replace(':id', props.disease.id.toString());

      await axiosInstance.delete(url);

      const prefix = isSymptom ? 'Sintoma removido' : 'Condição removida';
      message.setMessage({
        type: 'success',
        text: `${prefix} com sucesso`,
      });
      props.handleSuccess();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text: 'Ocorreu um erro ao remover',
      });
    }
  }

  function getTitle() {
    if (props.type === 'symptom') {
      return 'Remover sintoma';
    }
    return 'Remover condição';
  }

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Você tem certeza que deseja remover
          {props.type === 'symptom' ? ' o sintoma ' : ' a condição '}
          <span style={{ fontWeight: 'bold' }}>{props.disease?.title}</span>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDiseaseModal;
