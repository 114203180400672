import { floatToInt, Indicator } from '../indicatorsList';

const vacinaPolPen: Indicator = {
  title: 'Vacina de Poliomielite inativada e de Pentavalente',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'Mês', key: 'mes', transform: floatToInt },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Celular', key: 'celular' },
    { title: 'VIP', key: 'vip' },
    { title: 'Penta', key: 'penta' },
    { title: 'Consistente', key: 'consistente' },
    { title: 'Válido', key: 'valido' },
  ],
};

export default vacinaPolPen;
