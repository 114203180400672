import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import {
  Autocomplete,
  createFilterOptions,
  TreeItem,
  TreeView,
} from '@material-ui/lab';
import React, { useState } from 'react';
import useCitizenMicroAreas from './useCitizenMicroAreas';
import useTeams from '../../../../../../hooks/useTeams';
import filters, { extraFilters } from '../utils/filters';
import { useStyles } from './filterModal.styles';
import maskNumber from 'utils/maskNumber';

interface FilterModalProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  searchFilters: any;
  setSearchFilters: React.Dispatch<any>;
}

const maFilter = createFilterOptions<string>();

function FilterModal(props: FilterModalProps) {
  const classes = useStyles();
  const teams = useTeams();
  const microAreas = useCitizenMicroAreas();
  const [searchFilters, setSearchFilters] = useState<any>({});
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const exactList = ['Sexo'];
  const exactFilters = filters.filter((filter) =>
    exactList.includes(filter.title),
  );
  const checkboxFilters = filters.filter(
    (filter) => !exactList.includes(filter.title),
  );

  function handleClose() {
    props.setOpen(false);
  }

  function resetFilters() {
    setSearchFilters({});
  }

  function setFilters() {
    props.setSearchFilters(searchFilters);
    handleClose();
  }

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Filtrar Cidadãos</DialogTitle>
      <DialogContent dividers className={classes.content}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Equipe</InputLabel>
          <Select
            label="Equipe"
            value={searchFilters.equipe || ''}
            onChange={(e) => {
              setSearchFilters((filters: object) => ({
                ...filters,
                equipe: e.target.value,
              }));
            }}
          >
            <MenuItem value="">Não filtrar</MenuItem>
            {teams.map((team, idx) => (
              <MenuItem key={idx} value={team.no_equipe}>
                {team.no_equipe}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          freeSolo
          autoHighlight
          fullWidth
          autoSelect
          noOptionsText=""
          options={microAreas}
          value={searchFilters.ma || ''}
          onChange={(_e, val) => {
            setSearchFilters((filters: object) => ({
              ...filters,
              ma: val,
            }));
          }}
          filterOptions={(options, params) => {
            const filtered = maFilter(options, params);
            if (
              params.inputValue &&
              !filtered.find((p) => p === params.inputValue)
            ) {
              return [...filtered, params.inputValue];
            }
            return ['', ...filtered];
          }}
          renderInput={(params) => (
            <TextField label="Micro Área" variant="outlined" {...params} />
          )}
        />

        {exactFilters.map((filter, idx) => (
          <FormControl key={idx} variant="outlined" fullWidth>
            <InputLabel>{filter.title}</InputLabel>
            <Select
              label={filter.title}
              value={
                searchFilters[filter.key || filter.title.toLowerCase()] || ''
              }
              onChange={(e) => {
                setSearchFilters((filters: object) => ({
                  ...filters,
                  [filter.key || filter.title.toLowerCase()]: e.target.value,
                }));
              }}
            >
              <MenuItem value="">Não filtrar</MenuItem>
              {filter.options.map((option, idx) => (
                <MenuItem key={idx} value={option.value || option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}

        <div className={classes.ageRange}>
          <TextField
            label="Idade mínima"
            variant="outlined"
            className={classes.ageField}
            value={searchFilters.idade__gte || ''}
            onChange={(e) =>
              setSearchFilters((filters: object) => ({
                ...filters,
                idade__gte: e.target.value.replace(/\D/g, ''),
              }))
            }
          />
          <TextField
            label="Idade máxima"
            variant="outlined"
            className={classes.ageField}
            value={searchFilters.idade__lte || ''}
            onChange={(e) =>
              setSearchFilters((filters: object) => ({
                ...filters,
                idade__lte: e.target.value.replace(/\D/g, ''),
              }))
            }
          />
        </div>

        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={showMoreFilters ? ['1'] : []}
          selected={[]}
          onNodeToggle={(_, nodeId) => {
            setShowMoreFilters(nodeId.length > 0);
          }}
        >
          <TreeItem nodeId="1" label="Mostrar mais filtros">
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingTop: 8,
              }}
            >
              {extraFilters.map((filter) => (
                <TextField
                  key={filter.key}
                  label={filter.title}
                  variant="outlined"
                  value={(() => {
                    const value = searchFilters[filter.key] || '';
                    return filter.mask ? maskNumber(value, filter.mask) : value;
                  })()}
                  onChange={(e) => {
                    setSearchFilters((filters: object) => ({
                      ...filters,
                      [filter.key]: filter.transform
                        ? filter.transform(e.target.value)
                        : e.target.value,
                    }));
                  }}
                />
              ))}
            </div>
          </TreeItem>
        </TreeView>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={searchFilters['nu_cpf__isnull'] === 'True'}
                onChange={(e) => {
                  setSearchFilters((filters: object) => ({
                    ...filters,
                    nu_cpf__isnull: e.target.checked ? 'True' : undefined,
                  }));
                }}
                color="primary"
              />
            }
            label="Sem CPF"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={searchFilters['nu_cns__isnull'] === 'True'}
                onChange={(e) => {
                  setSearchFilters((filters: object) => ({
                    ...filters,
                    nu_cns__isnull: e.target.checked ? 'True' : undefined,
                  }));
                }}
                color="primary"
              />
            }
            label="Sem CNS"
          />
          {checkboxFilters.map((filter, idx) => (
            <FormControlLabel
              key={idx}
              control={
                <Checkbox
                  checked={
                    searchFilters[filter.key || filter.title.toLowerCase()] ===
                    'SIM'
                  }
                  onChange={(e) => {
                    setSearchFilters((filters: object) => ({
                      ...filters,
                      [filter.key || filter.title.toLowerCase()]: e.target
                        .checked
                        ? 'SIM'
                        : undefined,
                    }));
                  }}
                  color="primary"
                />
              }
              label={filter.title}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="outlined" onClick={resetFilters}>
          Limpar
        </Button>
        <Button color="primary" variant="contained" onClick={setFilters}>
          Pesquisar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterModal;
