import { useEffect, useState, FormEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import AccountPicture from './AccountPicture';
import useStyles from './styles';
import { API_ROUTES, axiosInstance } from '../../../api';
import {
  convertDateFromISO8601ToPtBR,
  convertDateFromPtBRToISO8601,
} from '../../../utils/date_converter';
import { AccountProps, FieldsError, getClearFieldsErrorState } from './utils';
import { Profile, useAuth } from '../../../context/auth';
import maskNumber from '../../../utils/maskNumber';
import { useMessage } from '../../../context/message';
import CloseIcon from '@material-ui/icons/Close';

function Account(props: AccountProps) {
  const classes = useStyles();
  const auth = useAuth();
  const message = useMessage();
  const [profile, setProfile] = useState<Profile>({} as Profile);
  const [fieldsError, setFieldsError] = useState<FieldsError>(
    getClearFieldsErrorState(),
  );

  function handleClose() {
    props.setOpenAccountDialog(!props.open);
  }

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      await axiosInstance.put(API_ROUTES.PROFILE, {
        name: profile.name,
        birth_date: convertDateFromPtBRToISO8601(profile.birthDate || null),
        gender: profile.gender,
        email: profile.email,
        phone: profile.phone.replace(/[^\d]/g, ''),
        neighborhood: profile.district,
        state: profile.uf,
      });

      await auth.refreshProfile();
      setFieldsError(getClearFieldsErrorState());

      message.setMessage({
        type: 'success',
        text: 'Informações de perfil atualizadas com sucesso',
      });

      props.setOpenAccountDialog(false);
    } catch (error: any) {
      if (error.response.status !== 400) {
        const formErrors = Object.values(error.response.data).join('\n');
        message.setMessage({ type: 'error', text: formErrors });
        return;
      }
      handleFieldsErrors(error);
    }
  }

  function handleFieldsErrors(error: any) {
    const state = { ...fieldsError } as any;
    Object.keys(error.response.data).forEach((inputError) => {
      state[inputError] = error.response.data[inputError];
    });
    setFieldsError(state);
  }

  function checkIfFormChanged() {
    const fields = Object.keys(profile) as Array<keyof typeof profile>;
    const initial = getInitialState(auth.user!.profile);

    return !fields.every((field) => profile[field] === initial[field]);
  }

  function checkIfFormHasError() {
    return Object.values(fieldsError).some((error) => error !== '');
  }

  function getInitialState(profile: Profile) {
    return {
      ...profile,
      birthDate: convertDateFromISO8601ToPtBR(profile.birthDate),
      phone: maskNumber(profile.phone, '(##) # ####-####'),
    };
  }

  function fieldErrorMessage(field: string, message: string) {
    setFieldsError({ ...fieldsError, [field]: message });
  }

  function validateName(value: string) {
    if (!value) {
      fieldErrorMessage('name', 'Campo obrigatório');
    } else {
      fieldErrorMessage('name', '');
    }
  }

  function validateBirthDate(value: string) {
    // TODO: check if date is valid
    if (maskNumber(value, '##/##/####').length < 10) {
      fieldErrorMessage('birth_date', 'Data no formato dd/mm/yyyy');
    } else {
      fieldErrorMessage('birth_date', '');
    }
  }

  function validateEmail(value: string) {
    if (
      !value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      )
    ) {
      fieldErrorMessage('email', 'Digite um e-mail válido');
    } else {
      fieldErrorMessage('email', '');
    }
  }

  function validatePhone(value: string) {
    const maskedNumber = maskNumber(value, '(##) # ####-####');

    if (maskedNumber && maskedNumber.length < 16) {
      fieldErrorMessage('phone', 'Digite um telefone válido');
    } else {
      fieldErrorMessage('phone', '');
    }
  }

  useEffect(() => {
    if (props.open && auth.user) {
      const profile = getInitialState(auth.user.profile);
      setProfile(profile);
      setFieldsError(getClearFieldsErrorState());
    }
  }, [auth.user, props.open]);

  return (
    <Dialog
      open={props.open}
      disableBackdropClick={checkIfFormChanged()}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Informações da Conta</Typography>

        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <div className={classes.photoCanvas}>
          <AccountPicture />
        </div>

        <form id="accountForm" noValidate onSubmit={handleFormSubmit}>
          <fieldset className={classes.fieldset}>
            <legend>Dados Pessoais</legend>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              fullWidth
              value={profile.name || ''}
              onChange={(e) => {
                validateName(e.target.value);
                setProfile((profile) => ({ ...profile, name: e.target.value }));
              }}
              helperText={fieldsError.name}
              error={fieldsError.name.length > 0}
              required
            />

            <TextField
              margin="normal"
              fullWidth
              label="CPF"
              disabled={true}
              value={maskNumber(profile.cpf, '###.###.###-##')}
            />

            <TextField
              margin="normal"
              label="Data de Nascimento"
              fullWidth
              error={fieldsError.birth_date.length > 0}
              helperText={fieldsError.birth_date}
              value={profile.birthDate || ''}
              onChange={(e) => {
                validateBirthDate(e.target.value);
                setProfile((profile) => ({
                  ...profile,
                  birthDate: maskNumber(e.target.value, '##/##/####'),
                }));
              }}
              required
            />

            <FormControl
              className={classes.formControl}
              error={fieldsError.gender.length > 0}
            >
              <InputLabel shrink>Gênero</InputLabel>
              <Select
                value={profile.gender || 'n'}
                onChange={(event) => {
                  const gender: string = event.target.value as string;
                  setProfile((profile) => ({ ...profile, gender }));
                }}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value="n">Não Definido</MenuItem>
                <MenuItem value="m">Masculino</MenuItem>
                <MenuItem value="f">Feminino</MenuItem>
              </Select>
              <FormHelperText>{fieldsError.gender}</FormHelperText>
            </FormControl>
          </fieldset>

          <fieldset className={classes.fieldset}>
            <legend>Contato</legend>
            <TextField
              margin="dense"
              label="E-mail"
              type="email"
              fullWidth
              value={profile.email || ''}
              onChange={(e) => {
                validateEmail(e.target.value);
                setProfile((profile) => ({
                  ...profile,
                  email: e.target.value,
                }));
              }}
              helperText={fieldsError.email}
              error={fieldsError.email.length > 0}
              required
            />

            <TextField
              margin="dense"
              label="Telefone"
              fullWidth
              helperText={fieldsError.phone}
              error={fieldsError.phone.length > 0}
              value={profile.phone || ''}
              onChange={(e) => {
                validatePhone(e.target.value);
                setProfile((profile) => ({
                  ...profile,
                  phone: maskNumber(e.target.value, '(##) # ####-####'),
                }));
              }}
            />
          </fieldset>

          <fieldset className={classes.fieldset}>
            <legend>Endereço</legend>
            <TextField
              margin="dense"
              label="Cidade"
              type="text"
              fullWidth
              disabled
              value={profile.living_city?.name || ''}
            />
            {/* // TODO: validate UF */}
            <TextField
              margin="dense"
              label="UF"
              type="text"
              fullWidth
              value={profile.uf || ''}
              onChange={(e) => {
                setProfile((profile) => ({ ...profile, uf: e.target.value }));
              }}
              helperText={fieldsError.state}
              error={fieldsError.state.length > 0}
            />
            <TextField
              margin="dense"
              label="Bairro"
              type="text"
              fullWidth
              value={profile.district || ''}
              onChange={(e) => {
                setProfile((profile) => ({
                  ...profile,
                  district: e.target.value,
                }));
              }}
              helperText={fieldsError.neighborhood}
              error={fieldsError.neighborhood.length > 0}
            />
          </fieldset>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>

        <Button
          type="submit"
          form="accountForm"
          color="primary"
          disabled={!checkIfFormChanged() || checkIfFormHasError()}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Account;
