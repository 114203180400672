import {
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  Paper,
} from '@material-ui/core';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { Disease } from '../Diseases';
import useStyles from './diseaseModal.styles';

interface DiseaseModalProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  type: 'symptom' | 'disease';
  disease?: Disease;
}

function DiseaseModal(props: DiseaseModalProps) {
  const classes = useStyles();
  const message = useMessage();

  const [name, setName] = useState('');
  const [priority, setPriority] = useState('');
  const imgInputRef = useRef<HTMLInputElement | null>(null);
  const [imageURL, setImageURL] = useState<string | undefined>();
  const [image, setImage] = useState<File | undefined>();

  async function handleSubmit() {
    try {
      const isSymptom = props.type === 'symptom';

      const formData = new FormData();
      formData.set('title', name);

      formData.set('level', priority);

      if (image) {
        const arrayBuffer = (await image.arrayBuffer()) as ArrayBufferLike;
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: image.type,
        });

        formData.set('icon', blob, image.name);
      }

      // If props.disease is defined it means a disease was selected and is being edited
      // If it's undefined it means a new disease is being created
      if (props.disease) {
        const url = (isSymptom
          ? API_ROUTES.SYMPTOMS_EDIT
          : API_ROUTES.CONDITIONS_EDIT
        ).replace(':id', props.disease.id.toString());

        await axiosInstance.put(url, formData);
      } else {
        const url = isSymptom ? API_ROUTES.SYMPTOMS : API_ROUTES.CONDITIONS;
        await axiosInstance.post(url, formData);
      }

      message.setMessage({
        type: 'success',
        text: `${isSymptom ? 'Sintoma salvo' : 'Condição salva'} com sucesso`,
      });
      props.handleSuccess();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text: 'Ocorreu um erro ao salvar',
      });
    }
  }

  function getTitle() {
    if (props.disease) {
      if (props.type === 'symptom') {
        return 'Editar sintoma';
      }
      return 'Editar condição';
    }

    if (props.type === 'symptom') {
      return 'Adicionar um sintoma';
    }
    return 'Adicionar uma condição';
  }

  function handleImageChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (!file) {
      setImage(undefined);
      setImageURL(undefined);
      return;
    }

    if (file.size > 1024 * 1024) {
      message.setMessage({ type: 'error', text: 'O arquivo é muito grande' });
      return;
    }

    setImage(file);
    setImageURL(URL.createObjectURL(file));
  }

  useEffect(() => {
    if (props.open) {
      setName(props.disease?.title || '');
      setPriority(props.disease?.priority.toString() || '');
      setImageURL(props.disease?.icon);
      setImage(undefined);
    }
  }, [props.disease, props.open]);

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        <TextField
          fullWidth
          variant="outlined"
          label={`Nome ${
            props.type === 'symptom' ? 'do sintoma' : 'da condição'
          }`}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Nível de prioridade"
          value={priority}
          onChange={(e) => setPriority(e.target.value.replace(/[^\d]/g, ''))}
        />
        <Paper
          className={classes.iconSelector}
          onClick={() => {
            imgInputRef.current?.click();
          }}
        >
          <img src={imageURL} width="120" alt="" />
          <span>Clique para selecionar uma imagem</span>
        </Paper>
      </DialogContent>
      <input
        type="file"
        accept=".svg,.png,.jpg,.jpeg"
        style={{ display: 'none' }}
        multiple={false}
        ref={(ref) => (imgInputRef.current = ref)}
        onChange={handleImageChange}
      />
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!name}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DiseaseModal;
