import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import { useStyles } from './city.styles';
import CitiesTableRow from './CitiesTableRow';
import CreateCityDialog from './CreateCityDialog';
import PageHeader from '../../components/PageHeader';
import City from './city.interface';

const rowsPerPage = 10;

function CitiesAdministration () {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rows, setRows] = useState<City[]>([]);
  const [refreshCities, setRefreshCities] = useState(0);
  const [nameFilter, setNameFilter] = useState('');
  const [createCityOpen, setCreateCityOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        // Checks if the input is part of a valid cpf(contains numbers, dots, and dashes)
        // And then checks if there are 11 numbers in the input
        const response = await axiosInstance.get(API_ROUTES.ADMINISTRATION_CITIES, {
          cancelToken: source.token,
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            name__icontains: nameFilter || undefined
          },
        });

        const cities: City[] = response.data.results.map((data: any) => ({
          id: data.id,
          name: data.name,
          connection_name: data.connection_name,
          sigus_connection_name: data.sigus_connection_name,
        }));

        setRows(cities);
        setCount(response.data.count);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setRows([]);
        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.detail ||
            'Ocorreu um erro ao carregar a lista de cidades.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, nameFilter, refreshCities]);

  return (
    <>
      <CreateCityDialog
        open={createCityOpen}
        onClose={() => {
          setCreateCityOpen(false);
          setRefreshCities(refresh => refresh + 1) // Refresh cities
        }}
      />

      <PageHeader title="Cidades" />

      <div className={classes.actionsContainer}>
        <div className={classes.filtersContainer}>
          <TextField
            label="Filtrar por nome"
            variant="outlined"
            value={nameFilter}
            onChange={(e) => {
              setPage(0);
              setNameFilter(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <Button
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setCreateCityOpen(true);
          }}
        >
          Nova Cidade
        </Button>
      </div>

      {/* TODO fix the width of the cells */}
      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Conexão ESUS</TableCell>
              <TableCell align="center">Conexão SIGUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((city) => (
                <CitiesTableRow
                  key={city.id}
                  city={city}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2">
                    Nenhuma cidade encontrada
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default CitiesAdministration;
