import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { mapRole } from '../../../utils/roles';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import Solicitation from '../utils/solicitation.interface';

interface ForwardSolicitationModalProps {
  open: boolean;
  solicitation: Solicitation;
  handleClose: () => void;
  handleSuccess: () => void;
}

interface DoctorOrNurse {
  id: number;
  name: string;
  role: string;
}

const rowsPerPage = 10;

function ForwardSolicitationModal(props: ForwardSolicitationModalProps) {
  const message = useRef(useMessage());

  const [reason, setReason] = useState('');
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<DoctorOrNurse[]>([]);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(forwardTo: number) {
    try {
      if (!reason.trim()) {
        throw new Error('Digite um motivo para o encaminhamento');
      }

      await axiosInstance.post(API_ROUTES.SOLICITATIONS_FORWARD, {
        forwardTo,
        solicitation: props.solicitation.id,
        reason: reason.trim(),
      });

      message.current.setMessage({
        type: 'success',
        text: 'Solicitação encaminhada com sucesso',
      });

      props.handleSuccess();
    } catch (error: any) {
      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao encaminhar a solicitação',
      });
    }
  }

  useEffect(() => {
    if (!props.open) {
      return;
    }

    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        const response = await axiosInstance.get(
          API_ROUTES.DOCTORS_AND_NURSES,
          {
            cancelToken: source.token,
            params: {
              page: page + 1,
              page_size: rowsPerPage,
            },
          },
        );

        setRows(
          response.data.results.map(
            (user: any): DoctorOrNurse => ({
              id: user.id,
              name: user.name,
              role: user.role,
            }),
          ),
        );
        setCount(response.data.count);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setRows([]);
        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.detail ||
            'Ocorreu um erro ao carregar a lista de profissionais.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, props.open]);

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h6">Encaminhar Solicitação</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          variant="outlined"
          label="Digite o motivo do encaminhamento"
          rows={3}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          fullWidth
          required
        />

        <Typography
          variant="body1"
          style={{ marginTop: '16px', marginBottom: '8px' }}
        >
          Escolha um profissional
        </Typography>

        <TableContainer component={Paper} elevation={3}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Cargo</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center" width="1">
                      {mapRole(row.role)}
                    </TableCell>
                    <TableCell width="1">
                      <IconButton
                        onClick={() => {
                          handleSubmit(row.id);
                        }}
                      >
                        <PhoneForwardedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(_e: any, page: number) => {
            setPage(page);
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ForwardSolicitationModal;
