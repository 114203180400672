import { Indicator } from '../indicatorsList';

const percentualHipertensa: Indicator = {
  title: 'Pessoas hipertensas com Pressão Arterial aferida em cada semestre',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'Idade', key: 'idade' },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Celular', key: 'celular' },
    { title: 'Con. Ant.', key: 'co_ant' },
    { title: 'PA Ant.', key: 'pa_ant' },
    { title: 'Con. Atu.', key: 'co_atu' },
    { title: 'PA Atu.', key: 'pa_atu' },
    { title: 'Consistente', key: 'consistente' },
    { title: 'Válido', key: 'valido' },
  ],
};

export default percentualHipertensa;
