import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  header: {
    backgroundColor: '#266DD3',
    width: '100%',
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  headerLogo: {
    flex: 1,
  },
  headerText: {
    textAlign: 'right',
    flex: 1,
    color: 'white',
  },
  avatar: {
    margin: theme.spacing(1),
    objectFit: 'cover',
    width: '100px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitButtonPrimary: {
    color: 'white',
    backgroundColor: '#266DD3',
  },
  centerText: {
    textAlign: 'center',
  },
  loadingIndicator: {
    position: 'fixed',
    top: 0,
    width: '100%',
  },
}));
