import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: theme.spacing(100),
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  solicitationContainer: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userPicture: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  closeButton: {
    marginLeft: 'auto',
    minWidth: 0,
  },
  observations: {
    padding: theme.spacing(1),
  },
  dialogActions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  speedDialContainer: {
    marginTop: theme.spacing(8),
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
