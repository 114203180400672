import { Indicator } from '../indicatorsList';

const percentualDiabete: Indicator = {
  title: 'Diabéticos com solicitação de hemoglobina glicada',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'Idade', key: 'idade' },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Celular', key: 'celular' },
    { title: 'Solicitados', key: 'solicitados' },
    { title: 'Avaliados', key: 'avaliados' },
    { title: 'Válido', key: 'valido' },
    { title: 'Consistente', key: 'consistente' },
  ],
};

export default percentualDiabete;
