import { Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './styles';

function SkeletonCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardBody}>
        <div>
          <div className={classes.loading}>
            <Typography variant="body2" gutterBottom>
              Carregando estatísticas....
            </Typography>
          </div>
          <div className={classes.loading} style={{ width: 48 }}>
            <Typography variant="h5" component="h2">
              00
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default SkeletonCard;
