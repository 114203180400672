export enum Roles {
  Gestor = 'g',
  Medico = 'm',
  Enfermeiro = 'e',
  Padrao = 'p',
  Staff = 's',
}

export function mapRole(role: string | undefined): string {
  switch (role) {
    case Roles.Gestor:
      return 'Gestor';
    case Roles.Medico:
      return 'Médico';
    case Roles.Enfermeiro:
      return 'Enfermeiro';
    case Roles.Padrao:
      return 'Cidadão';
    default:
      return '';
  }
}
