import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../../../api';
import { useMessage } from '../../../../../../context/message';

export default function useCitizenMicroAreas() {
  const [microAreas, setMicroAreas] = useState<string[]>([]);
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance(
          API_ROUTES.ESUS_CITIZENS_MICRO_AREAS,
          {
            cancelToken: source.token,
          },
        );
        const microAreas = response.data.results;

        setMicroAreas(microAreas);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar o filtro de micro áreas',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return microAreas;
}
