import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useState, useRef } from 'react';
import { useAuth } from '../../../context/auth';
import { useStyles } from './styles';

import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import SecurityIcon from '@material-ui/icons/Security';
import Account from '../Account';
import UserProfileName from './UserProfileName';
import { useProvideTheme } from '../../../context/theme';

import Avatar from '../../../assets/avatar.png';
import Security from '../Security';
import Logo from '../../Logo';

function AppBar(props: { setDrawerOpen: (val: any) => void }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const auth = useAuth();
  const provideTheme = useProvideTheme();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const [openSecurityDialog, setOpenSecurityDialog] = useState(false);
  const userMenuHelper = useRef<Element | null>();

  function toggleUserMenu() {
    setUserMenuOpen(!isUserMenuOpen);
  }

  function toggleDarkMode() {
    provideTheme.enableDarkMode(!provideTheme.darkMode);
  }

  return (
    <>
      <Account
        open={openAccountDialog}
        setOpenAccountDialog={setOpenAccountDialog}
      />

      <Security
        open={openSecurityDialog}
        setOpenSecurityDialog={setOpenSecurityDialog}
      />

      <MuiAppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              props.setDrawerOpen((open: boolean) => !open);
            }}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Logo
            mode="light"
            height={45}
            logoOverride={
              auth.user?.profile.living_city.logo_website || undefined
            }
          />

          <div className={classes.userDetails}>
            {!isMobile && <UserProfileName />}
            <img
              className={classes.userPicture}
              onClick={toggleUserMenu}
              src={auth.user?.profile.image ?? Avatar}
              alt="Foto do usuário"
              height="48"
              width="48"
            />
            <span
              className={classes.userMenuHelper}
              ref={(ref) => {
                userMenuHelper.current = ref;
              }}
            />
          </div>
        </Toolbar>
      </MuiAppBar>
      <Menu
        anchorEl={userMenuHelper.current}
        keepMounted
        open={isUserMenuOpen}
        onClose={toggleUserMenu}
        disableAutoFocusItem={true}
      >
        {isMobile && (
          <MenuItem>
            <UserProfileName />
          </MenuItem>
        )}
        {isMobile && (
          <Divider
            variant="middle"
            style={{ marginTop: '8px', marginBottom: '8px' }}
          />
        )}
        <MenuItem onClick={toggleDarkMode}>
          <ListItemIcon>
            <Brightness6Icon />
          </ListItemIcon>
          Alternar tema escuro
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenAccountDialog(true);
            toggleUserMenu();
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          Perfil
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenSecurityDialog(true);
            toggleUserMenu();
          }}
        >
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          Segurança
        </MenuItem>

        <Divider
          variant="middle"
          style={{ marginTop: '8px', marginBottom: '8px' }}
        />

        <MenuItem onClick={auth.logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default AppBar;
