import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.5rem',
    },
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));
