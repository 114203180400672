import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance } from '../api';
import { useMessage } from '../context/message';
import downloadBlob from '../utils/downloadBlob';

function useCsvExporter() {
  const message = useRef(useMessage());
  const [url, setUrl] = useState('');
  const [filename, setFilename] = useState('');
  const [params, setParams] = useState<object | undefined>();
  const [isExporting, setExporting] = useState(false);

  function exportToCsv(url: string, filename: string, params?: object) {
    setUrl(url);
    setFilename(filename);
    setParams(params);
  }

  function resetState() {
    setUrl('');
    setFilename('');
    setParams(undefined);
    setExporting(false);
  }

  useEffect(() => {
    if (!url) {
      return;
    }

    setExporting(true);
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance.get(url, {
          responseType: 'blob',
          cancelToken: source.token,
          params,
        });
        const blob = response.data;
        downloadBlob(blob, filename);

        resetState();
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        resetState();

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao gerar o .csv',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [params, filename, url]);

  return { exportToCsv, isExporting };
}

export default useCsvExporter;
