import { TextField, InputAdornment, Button } from '@material-ui/core';
import { useState } from 'react';
import { useMessage } from '../../context/message';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import maskNumber from '../../utils/maskNumber';
import { ExportProps } from './ExportGeneric';

function ExportSISAIH01(props: ExportProps) {
  const [filename, setFilename] = useState('');
  const [competence, setCompetence] = useState('');
  const message = useMessage();

  function handleDownloadFile() {
    if (!filename || filename.match(/\W/)) {
      message.setMessage({
        type: 'warning',
        text:
          'Certifique-se de que você preencheu o nome do arquivo corretamente. Só são permitidos letras e números.',
      });
      return;
    }

    const a = document.createElement('a');
    a.href = props.fileurl;
    a.download = `${filename}.txt`;
    a.click();
  }

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <TextField
        variant="outlined"
        label="Nome do Arquivo"
        value={filename}
        onChange={(e) => setFilename(e.target.value?.toUpperCase())}
      />

      <TextField
        variant="outlined"
        label="Competência"
        placeholder="mm/aaaa"
        value={competence}
        onChange={(e) => {
          setCompetence(maskNumber(e.target.value, '##/####'));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
      />

      <Button variant="outlined" color="primary" onClick={handleDownloadFile}>
        {props.btnText}
      </Button>
    </div>
  );
}

export default ExportSISAIH01;
