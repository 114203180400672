import { Container, TextField, Button } from '@material-ui/core';
import { useState, FormEvent, useEffect, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { API_ROUTES, axiosInstance } from '../../../api';
import Logo from '../../../components/Logo';
import { APP_ROUTES } from '../../../components/Routes';
import { useAuth } from '../../../context/auth';
import { useMessage } from '../../../context/message';
import { useStyles } from './confirmAccount.styles';

function ConfirmAccount() {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const message = useRef(useMessage());

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  function passwordIsValid(password: string) {
    const regexs = [
      /([A-Z]+[a-z]+)|([a-z]+[A-Z]+)/,
      /[0-9]+/,
      /[#?!@$%^&*-]+/,
      /^.{8,}$/,
    ];

    return regexs.every((re) => re.test(password));
  }

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      await axiosInstance.post(API_ROUTES.USERS_CONFIRM, { token, password });
      message.current.setMessage({
        type: 'success',
        text:
          'Usuário confirmado com sucesso, você será redirecionado para a tela de login',
      });
      setTimeout(() => {
        window.location.replace(API_ROUTES.LOGIN);
      }, 5000);
    } catch (error: any) {
      message.current.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          error.response?.data?.token?.[0] ||
          error.response?.data?.password?.[0] ||
          'Ocorreu um erro ao confirmar o seu cadastro',
      });
    }
  }

  useEffect(() => {
    const params = location.search
      .substring(1)
      .split('&')
      .map((param) => {
        const entry = param.split('=');
        return { key: entry[0], value: entry[1] || '' };
      });

    const token = params.find((param) => param.key === 'token');

    if (!token?.value) {
      message.current.setMessage({ type: 'error', text: 'Token inválido' });
    } else {
      setToken(token.value);
    }
  }, [location]);

  return (
    <>
      {auth.user && (
        <Redirect
          to={{
            pathname: APP_ROUTES.DASHBOARD,
          }}
        />
      )}

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Logo className={classes.avatar} />

          <form noValidate onSubmit={handleFormSubmit} className={classes.form}>
            <TextField
              fullWidth
              variant="outlined"
              label="Senha"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              autoComplete="new-password"
              error={password.length > 0 && !passwordIsValid(password)}
            />

            <ul>
              <li className={classes.passwordHint}>
                Use pelo menos 8 caracteres.
              </li>
              <li className={classes.passwordHint}>
                Pelo menos um caracter precisa ser maiúsculo.
              </li>
              <li className={classes.passwordHint}>
                Pelo menos um caracter precisa ser minúsculo.
              </li>
              <li className={classes.passwordHint}>
                Inclua pelo menos um número e um símbolo (#?!@$%^&*-).
              </li>
            </ul>

            <TextField
              fullWidth
              variant="outlined"
              label="Confirmação de Senha"
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
              type="password"
              autoComplete="new-password"
              error={passwordConfirm.length > 0 && passwordConfirm !== password}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !(passwordIsValid(password) && passwordConfirm === password)
              }
            >
              Confirmar Cadastro
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}

export default ConfirmAccount;
