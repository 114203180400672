import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    position: 'relative',
  },
  textFieldRoot: {
    '& textarea': {
      marginBottom: theme.spacing(3),
    },
  },
  charCountLabel: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(2),
    userSelect: 'none',
  },
  charCountLabelError: {
    color: theme.palette.error.main,
  },
  saveBtn: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
}));
