import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TablePagination,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { Roles } from '../../../utils/roles';
import ConfirmAssociationDialog from '../ConfirmAssociationDialog';
import Professional from '../professional.interface';
import User from '../user.interface';

const rowsPerPage = 4;

interface AssociationDialogProps {
  open: boolean;
  handleClose: () => void;
  refreshUsers: () => void;
  user: User | null;
}

function AssociationDialog(props: AssociationDialogProps) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<Professional[]>([]);
  const [count, setCount] = useState(-1);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [role, setRole] = useState(Roles.Enfermeiro);
  const [loading, setLoading] = useState(true);
  const [searchName, setName] = useState('');
  const [
    selectedProfessional,
    setSelectedProfessional,
  ] = useState<Professional | null>(null);
  const message = useRef(useMessage());

  function openConfirmationDialog(professional: Professional) {
    setSelectedProfessional(professional);
    setConfirmationOpen(true);
  }

  function handleNameChange(name: string) {
    setName(name);
    setPage(0);
  }

  useEffect(() => {
    if (!props.open) {
      return;
    }

    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.PROFESSIONALS, {
          cancelToken: source.token,
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            nome_prof__icontains: searchName || undefined,
          },
        });

        setRows(
          response.data.results.map(
            (professional: any): Professional => ({
              id: professional.id,
              name: professional.nome_prof,
              cpf: professional.cpf_prof,
              cns: professional.cod_cns,
              cbo: professional.cod_cbo,
            }),
          ),
        );
        setCount(response.data.count);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        console.log(error);

        setRows([]);
        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.detail ||
            'Ocorreu um erro ao carregar a lista de profissionais.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, props.open, searchName]);

  return (
    <>
      <ConfirmAssociationDialog
        open={confirmationOpen}
        handleClose={() => {
          setConfirmationOpen(false);
        }}
        handleConfirmation={() => {
          setConfirmationOpen(false);
          props.refreshUsers();
          props.handleClose();
        }}
        user={props.user}
        professional={selectedProfessional}
        role={role}
      />

      <Dialog open={props.open} fullWidth maxWidth="md">
        <DialogTitle>
          Selecione um profissional para associar ao usuário.
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginBottom: '1em',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span>Associar como:</span>
              <Select
                value={role}
                onChange={(e) => {
                  setRole(e.target.value as Roles);
                }}
              >
                <MenuItem value={Roles.Enfermeiro}>Enfermeiro</MenuItem>
                <MenuItem value={Roles.Medico}>Médico</MenuItem>
              </Select>
            </div>

            <TextField
              label="Filtrar por nome"
              value={searchName}
              onChange={(e) => {
                handleNameChange(e.target.value);
              }}
            />
          </div>
          <TableContainer component={Paper} style={{ minHeight: '381px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">CPF</TableCell>
                  <TableCell align="center">CNS</TableCell>
                  <TableCell align="center">CBO</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="center">{row.cpf}</TableCell>
                      <TableCell align="center">{row.cns}</TableCell>
                      <TableCell align="center">{row.cbo}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => openConfirmationDialog(row)}>
                          <LinkIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(_e: any, page: number) => {
              setPage(page);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AssociationDialog;
