interface ExactFilter {
  title: string;
  key?: string;
  options: { label: string; value?: any }[];
}

interface TextFilter {
  title: string;
  key: string;
  mask?: string;
  transform?: (v: string) => string;
}

const filters: ExactFilter[] = [
  {
    title: 'Sexo',
    options: [
      { label: 'Masculino', value: 'M' },
      { label: 'Feminino', value: 'F' },
    ],
  },
  {
    title: 'Diabete',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Gestante',
    key: 'ges',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Hipertensão Arterial',
    key: 'hipertensao_arterial',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Hanseniase',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Tuberculose',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Deficiente',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Fumante',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Álcool',
    key: 'alcool',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Acamado',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
  {
    title: 'Morador de rua',
    key: 'morador_rua',
    options: [
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' },
    ],
  },
];

export const extraFilters: TextFilter[] = [
  { title: 'Nome', key: 'cidadao__unaccent__icontains' },
  { title: 'Nome Social', key: 'nome_social__unaccent__icontains' },
  { title: 'Nome da Mãe', key: 'no_mae__unaccent__icontains' },
  {
    title: 'CPF',
    key: 'nu_cpf__icontains',
    mask: '###.###.###-##',
    transform: (v) => v.replace(/\D/g, ''),
  },
  {
    title: 'CNS',
    key: 'nu_cns__icontains',
    mask: '### #### #### ####',
    transform: (v) => v.replace(/\D/g, ''),
  },
];

export default filters;
