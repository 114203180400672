import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Tooltip,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PageLink from './PageLink';

// TODO: fix page type
interface NestedPageLinkProps {
  page: any;
  nest: any[];
  drawerOpen: boolean;
  setDrawerOpen: (val: boolean) => void;
}

function NestedPageLink({
  page,
  nest,
  drawerOpen,
  setDrawerOpen,
}: NestedPageLinkProps) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const currentPage = location.pathname.startsWith(page.path);

  useEffect(() => {
    if (currentPage) {
      setOpen(true);
    }
  }, [currentPage]);

  function getClass() {
    if (currentPage) {
      if ((open && !drawerOpen) || !open) {
        return 'Mui-selected';
      }
    }

    return '';
  }

  return (
    <>
      <Tooltip title={page.hint || page.title} placement="right" arrow>
        <ListItem
          button
          onClick={() => {
            setDrawerOpen(true);
            if (!drawerOpen && open) {
              return;
            }
            setOpen((open) => !open);
          }}
          className={getClass()}
        >
          <ListItemIcon style={{ paddingLeft: '8px' }}>
            <page.icon />
          </ListItemIcon>
          <ListItemText primary={page.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse
        in={open && drawerOpen}
        timeout="auto"
        unmountOnExit
        style={{ paddingLeft: '48px' }}
      >
        <List component="div" disablePadding>
          {nest.map((nestedPage) => {
            return (
              <PageLink
                key={nestedPage.title}
                page={nestedPage}
                setDrawerOpen={setDrawerOpen}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default NestedPageLink;
