import {
  Event,
  Dashboard as DashboardIcon,
  Assignment,
  Group,
  LocalHospital,
  Business,
  SupervisedUserCircle,
  Settings,
  Image,
  Duo,
  House,
  RecordVoiceOver,
  Description,
} from '@material-ui/icons';
import {
  VideoCall,
  Dashboard,
  Solicitations,
  Users,
  Diseases,
  Establishments,
  Professionals,
  CalendarVaccine,
  Logos,
  InformativeText,
} from '../../../screens';
import { APP_ROUTES, PrivateRoute } from '..';

const routes: PrivateRoute[] = [
  {
    title: 'Início',
    path: APP_ROUTES.DASHBOARD,
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    path: APP_ROUTES.VIDEO_CALL,
    showLayout: false,
    component: VideoCall,
    permissions: ['portal.video_call'],
  },
  {
    title: 'Portal de Atendimento',
    path: APP_ROUTES.PORTAL_ATTENDANCES,
    exact: true,
    icon: Assignment,
    permissions: ['portal.attendance'],
    nestedPages: [
      {
        title: 'Solicitações',
        path: APP_ROUTES.SOLICITATIONS,
        component: Solicitations,
        icon: Duo,
        permissions: ['portal.attendance.solicitations'],
      },
      {
        title: 'Usuários',
        path: APP_ROUTES.USERS,
        component: Users,
        icon: Group,
        permissions: ['portal.attendance.users'],
      },
      {
        title: 'Enfermidades',
        path: APP_ROUTES.DISEASES,
        component: Diseases,
        icon: LocalHospital,
        permissions: ['portal.attendance.diseases'],
      },
      {
        title: 'Estabelecimentos',
        path: APP_ROUTES.ESTABLISHMENTS,
        component: Establishments,
        icon: Business,
        permissions: ['portal.attendance.establishments'],
      },
      {
        title: 'Profissionais',
        path: APP_ROUTES.PROFESSIONALS,
        component: Professionals,
        icon: SupervisedUserCircle,
        permissions: ['portal.attendance.professionals'],
      },
    ],
  },
  {
    title: 'Ouvidoria',
    path: APP_ROUTES.OUVIDORIA,
    component: null,
    icon: RecordVoiceOver,
    permissions: ['portal.ombudsman'],
  },
  {
    title: 'Pedidos de Visitas ACS',
    path: APP_ROUTES.VISITS,
    component: null,
    icon: House,
    permissions: ['portal.visit_requests'],
  },
  {
    title: 'Calendário de Vacina',
    path: APP_ROUTES.CALENDAR_VACCINE,
    component: CalendarVaccine,
    icon: Event,
    permissions: ['portal.vaccine_calendar'],
  },
  {
    title: 'Configurações',
    path: APP_ROUTES.SETTINGS,
    exact: true,
    icon: Settings,
    permissions: ['portal.configurations'],
    nestedPages: [
      {
        title: 'Logos',
        path: APP_ROUTES.SETTINGS_LOGOS,
        component: Logos,
        exact: true,
        icon: Image,
        permissions: ['portal.configurations.logos'],
      },
      {
        title: 'Descrição da Rede',
        hint: 'Descrição da Rede de Saúde',
        path: APP_ROUTES.SETTINGS_INFORMATIVE_TEXT,
        component: InformativeText,
        exact: true,
        icon: Description,
        permissions: ['portal.configurations.description'],
      },
    ],
  },
];

export default routes;
