import { useState } from 'react';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import { useMessage } from '../../context/message';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import maskNumber from '../../utils/maskNumber';

export interface ExportProps {
  fileurl: string;
  btnText: string;
}

function ExportGeneric(props: ExportProps) {
  const [filename, setFilename] = useState('');
  const [competence, setCompetence] = useState('');
  const message = useMessage();

  function getExtension() {
    const err =
      'Certifique-se de que você preencheu a competência corretamente.';

    if (competence.length !== 7) {
      throw new Error(err);
    }

    const month = +competence.split('/')[0];
    const extensions = [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ];

    const extension = extensions[month - 1];

    if (!extension) {
      throw Error(err);
    }

    return extension;
  }

  function handleDownloadFile() {
    try {
      if (!filename || filename.match(/\W/)) {
        throw new Error(
          'Certifique-se de que você preencheu o nome do arquivo corretamente. Só são permitidos letras e números.',
        );
      }

      const extension = getExtension();
      const downloadName = `${filename}.${extension}`;

      const a = document.createElement('a');
      a.href = props.fileurl;
      a.download = downloadName;
      a.click();
    } catch (error: any) {
      message.setMessage({
        type: 'warning',
        text: error?.message || 'Ocorreu um erro ao exportar o arquivo.',
      });
    }
  }

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <TextField
        variant="outlined"
        label="Nome do Arquivo"
        value={filename}
        onChange={(e) => setFilename(e.target.value?.toUpperCase())}
      />

      <TextField
        variant="outlined"
        label="Competência"
        placeholder="mm/aaaa"
        value={competence}
        onChange={(e) => {
          setCompetence(maskNumber(e.target.value, '##/####'));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
      />

      <Button variant="outlined" color="primary" onClick={handleDownloadFile}>
        {props.btnText}
      </Button>
    </div>
  );
}

export default ExportGeneric;
