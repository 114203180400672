import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../../api';
import { useMessage } from '../../../../context/message';

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  newPassword?: string;
  new2fa?: boolean;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const [password, setPassword] = useState('');
  const message = useMessage();

  useEffect(() => {
    setPassword('');
  }, [props.open]);

  async function handleConfirmation() {
    try {
      if (props.newPassword) {
        await axiosInstance.post(API_ROUTES.PASSWORD_CHANGE, {
          password,
          new_password: props.newPassword,
        });
      }

      if (typeof props.new2fa === 'boolean') {
        await axiosInstance.put(API_ROUTES.PROFILE, {
          use_2fa: props.new2fa,
        });
      }

      message.setMessage({
        type: 'success',
        text: 'Informações alteradas com sucesso.',
      });

      props.handleSuccess();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          'Ocorreu um erro ao salvar as informações',
      });
    }
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Confirmar alterações</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para sua segurança, você precisa confirmar a sua senha atual antes de
          salvar as alterações.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Senha atual"
          type="password"
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={handleConfirmation}
          disabled={password.length === 0}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
