import { floatToInt, intToSimOrNao, Indicator } from '../indicatorsList';

const gestantesOdonto: Indicator = {
  title: 'Gestantes com atendimento odontológico realizado',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Idade', key: 'idade', transform: floatToInt },
    { title: 'Consultas', key: 'consultas' },
    { title: 'Celular', key: 'celular' },
    {
      title: 'Consistente',
      key: 'st_cidadao_consistente',
      transform: intToSimOrNao,
    },
    { title: 'Válido', key: 'valido', transform: intToSimOrNao },
  ],
};

export default gestantesOdonto;
