import axios, { AxiosResponse } from 'axios';
import { useState, useRef, useEffect } from 'react';
import { axiosInstance, API_ROUTES } from '../api';
import { useAuth } from '../context/auth';
import { useMessage } from '../context/message';

export interface Establishment {
  id: number;
  unidade_id: string;
  cnes: string;
  cnpj_mant: string;
  pfpj_ind: string;
  nivel_dep: string;
  r_social: string;
  nome_fanta: string;
  logradouro: string;
  numero: string;
  complement: string;
  bairro: string;
  cod_cep: string;
  reg_saude: string;
  micro_reg: string;
  dist_sanit: string;
  dist_admin: string;
  telefone: string;
  fax: string;
  e_mail: string;
  cpf: string;
  cnpj: string;
  co_natureza_jur: string;
  cod_ativ: string;
  cod_client: string;
  num_alvara: string;
  data_exped: string;
  ind_orgexp: string;
  tp_unid_id: string;
  cod_turnat: string;
  sigestgest: string;
  codmungest: string;
  statusmov: string;
  geolocation: {
    latitude: string;
    longitude: string;
  } | null;
  city: number;
}

function useEstablishments() {
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefresh] = useState(0);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);

  const message = useRef(useMessage());
  const auth = useAuth();

  function refresh() {
    setRefresh((refresh) => refresh + 1);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setEstablishments([]);

    (async () => {
      try {
        const request: AxiosResponse<Establishment[]> = await axiosInstance.get(
          API_ROUTES.ESTABLISHMENTS,
          {
            cancelToken: source.token,
          },
        );

        const filtered = request.data.filter(
          (establishment) =>
            establishment.city === auth.user?.profile.living_city.id,
        );

        setEstablishments(filtered);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.detail ||
            'Ocorreu um erro ao carregar a lista de estabelecimentos.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [auth.user?.profile.living_city.id, refreshCount]);

  return { establishments, loading, refresh };
}

export default useEstablishments;
