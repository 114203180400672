import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TablePagination,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { ImportData } from './importData.interface';
import ImportEstablishmentModal from './ImportEstablishmentModal';
import ImportGeolocationModal from './ImportGeolocationModal';
import { useStyles } from './imports.styles';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ImportOnlineEstablishmentModal from './ImportOnlineEstablishmentModal';
import ImportSigtapModal from './ImportSigtapModal';
import PageHeader from '../../../components/PageHeader';

const rowsPerPage = 15;

function Imports() {
  const classes = useStyles();
  const message = useRef(useMessage());
  const [importEstablishmentOpen, setImportEstablishmentOpen] = useState(false);
  const [importEstabOnlineOpen, setImportEstabOnlineOpen] = useState(false);
  const [importSigtapOpen, setImportSigtapOpen] = useState(false);
  const [importGeoOpen, setImportGeoOpen] = useState(false);
  const [data, setData] = useState<ImportData[]>([]);
  const [refreshCount, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    function pad(num: number) {
      return num.toString().padStart(2, '0');
    }

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hour = pad(date.getHours());
    const minute = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${day}/${month}/${year} - ${hour}:${minute}:${seconds}`;
  }

  function refreshList() {
    setRefresh((refresh) => (refresh += 1));
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.IMPORTS_LIST, {
          cancelToken: source.token,
          params: {
            page: page + 1,
          },
        });

        setCount(response.data.count);
        setData(response.data.results);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);
        setData([]);

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar o histórico de importações',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, refreshCount]);

  return (
    <>
      <ImportEstablishmentModal
        open={importEstablishmentOpen}
        setOpen={setImportEstablishmentOpen}
        refreshList={refreshList}
      />

      <ImportOnlineEstablishmentModal
        open={importEstabOnlineOpen}
        setOpen={setImportEstabOnlineOpen}
        refreshList={refreshList}
      />

      <ImportGeolocationModal
        open={importGeoOpen}
        setOpen={setImportGeoOpen}
        refreshList={refreshList}
      />

      <ImportSigtapModal
        open={importSigtapOpen}
        setOpen={setImportSigtapOpen}
        refreshList={refreshList}
      />

      <div className={classes.header}>
        <PageHeader title="Importações" />

        <Button
          variant="outlined"
          color="primary"
          endIcon={<ArrowDropDownIcon />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Importar
        </Button>
      </div>

      <Menu
        classes={{
          paper: classes.importerMenu,
        }}
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setImportEstablishmentOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          CNES Local (XML)
        </MenuItem>
        <MenuItem
          onClick={() => {
            setImportEstabOnlineOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          CNES Online (XML)
        </MenuItem>
        <MenuItem
          onClick={() => {
            setImportGeoOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <PublicIcon />
          </ListItemIcon>
          Geolocalização (CSV)
        </MenuItem>
        <MenuItem
          onClick={() => {
            setImportSigtapOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          Sigtap (ZIP)
        </MenuItem>
      </Menu>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 200px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Versão</TableCell>
              <TableCell>Operador</TableCell>
              <TableCell>Data de Importação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.import_type}</TableCell>
                  <TableCell>{row.version}</TableCell>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell>{formatDate(row.created_at)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default Imports;
