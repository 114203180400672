import axios, { AxiosResponse } from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import { alphabeticalCompare } from '../../utils/sort';
import headers from './headers';

interface UFResponse {
  [x: string]: string;
}

interface UF {
  id: number;
  name: string;
}

export default function useUfs() {
  const [ufs, setUfs] = useState<UF[]>([]);
  const [loading, setLoading] = useState(false);
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        setLoading(true);
        const response: AxiosResponse<UFResponse> = await axiosInstance(
          API_ROUTES.PROXY,
          {
            cancelToken: source.token,
            headers,
            params: {
              url: API_ROUTES.CNES_UFS,
            },
          },
        );
        const ufs: UF[] = Object.entries(response.data).map((uf) => ({
          id: +uf[0],
          name: uf[1],
        }));

        const sorted = ufs.sort((a, b) =>
          alphabeticalCompare(Object.values(a)[1], Object.values(b)[1]),
        );

        setUfs(sorted);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar ao buscar as UFS do CNES.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return { ufs, loading };
}
