import { useEffect, useState } from 'react';

/**
 *
 * @param value The value that will be set after the debounce, or the initial value
 * @param delay How long it will take to update the debounced value
 * @returns The value after the debounce is done
 */
function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
