import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../api';
import { useMessage } from '../context/message';

export interface Team {
  co_seq_dim_equipe: number;
  nu_ine: string;
  no_equipe: string;
  st_registro_valido: number;
  ds_filtro: string;
}

export default function useTeams() {
  const [teams, setTeams] = useState<Team[]>([]);
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance(API_ROUTES.ESUS_TEAMS, {
          cancelToken: source.token,
        });
        const teams = response.data.results as Team[];

        setTeams(teams);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar o filtro de equipes',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return teams;
}
