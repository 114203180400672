import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '32px',
  },
  icon: {
    fontSize: theme.typography.pxToRem(48),
  },
  loading: {
    color: 'transparent',
    pointerEvents: 'none',
    userSelect: 'none',
    borderRadius: 4,
    opacity: 0.2,
    background: 'linear-gradient(to right, gray, darkgray)',
    backgroundSize: '400%',
    animation: '$skeletonLoading 0.7s linear infinite alternate;',
  },
  '@keyframes skeletonLoading': {
    '0%': {
      backgroundPosition: '0%',
    },
    '100%': {
      backgroundPosition: '100%',
    },
  },
}));
