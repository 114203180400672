import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  ageRange: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  ageField: {
    flex: 1,
  },
}));
