import { convertDateFromISO8601ToPtBR } from '../../../../../../utils/date_converter';

interface TableData {
  title: string;
  key: string;
  transform?: (data: any) => string;
}

const tableData: TableData[] = [
  { title: 'Equipe', key: 'equipe' },
  { title: 'MA', key: 'ma' },
  { title: 'Nome', key: 'cidadao' },
  { title: 'Nome Social', key: 'nome_social' },
  { title: 'Nome da Mãe', key: 'no_mae' },
  { title: 'Profissional', key: 'profissional_nome' },
  { title: 'CNS', key: 'nu_cns' },
  { title: 'CPF', key: 'nu_cpf' },
  { title: 'Idade', key: 'idade', transform: (val) => (+val).toString() },
  {
    title: 'Data de nascimento',
    key: 'dt_nascimento',
    transform: convertDateFromISO8601ToPtBR,
  },
  {
    title: 'Data de atualização',
    key: 'dt_atualizado',
    transform: (val) => convertDateFromISO8601ToPtBR(val.split(' ')[0]),
  },
  { title: 'Sexo', key: 'sexo' },
  { title: 'Celular', key: 'celular' },
  { title: 'NIS', key: 'nis' },
  { title: 'Gestante', key: 'ges' },
  { title: 'Álcool', key: 'alcool' },
  { title: 'Deficiente', key: 'deficiente' },
  { title: 'Diabete', key: 'diabete' },
  { title: 'Fumante', key: 'fumante' },
  { title: 'Hanseniase', key: 'hanseniase' },
  { title: 'Hipertensão Arterial', key: 'hipertensao_arterial' },
  { title: 'Morador de rua', key: 'morador_rua' },
  { title: 'Tuberculose', key: 'tuberculose' },
];

function formatTable(data: any[]) {
  const colsList = tableData.map((data) => ({
    key: data.key,
    transform: data.transform,
  }));

  const rows = data.map((result) => {
    const cols: string[] = [];
    colsList.forEach((col) => {
      const val = result[col.key];
      cols.push(String((col.transform ? col.transform(val) : val) || '--'));
    });
    return cols;
  });

  return { rows, columns: tableData.map((data) => data.title) };
}

export default formatTable;
