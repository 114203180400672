export default function cpfIsValid(cpf: string) {
  // CPF Check
  // https://nadikun.com/how-to-validate-cpf-number-using-custom-method-in-jquery-validate-plugin/

  if (cpf.length !== 11) return false;

  let sum = 0,
    firstCN,
    secondCN,
    checkResult,
    i;

  firstCN = parseInt(cpf.substring(9, 10), 10);
  secondCN = parseInt(cpf.substring(10, 11), 10);

  checkResult = (sum: number, cn: number) => {
    let result = (sum * 10) % 11;
    if (result === 10 || result === 11) {
      result = 0;
    }
    return result === cn;
  };

  // Checking for dump data
  if (
    cpf === '' ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  // Step 1 - using first Check Number:
  for (i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
  if (checkResult(sum, firstCN)) {
    sum = 0;
    for (i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    return checkResult(sum, secondCN);
  }
  return false;
}
