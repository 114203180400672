import { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import EstablishmentModal from './EstablishmentModal';
import useEstablishments, {
  Establishment,
} from '../../hooks/useEstablishments';
import PageHeader from '../../components/PageHeader';

const rowsPerPage = 15;

function CenteredCell({ children }: any) {
  return (
    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
      {children}
    </TableCell>
  );
}

function Establishments() {
  const { establishments, loading, refresh } = useEstablishments();

  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [
    selectedEstablishment,
    setSelectedEstablishment,
  ] = useState<Establishment | null>(null);

  const filteredRows = establishments.filter(
    (establishment) =>
      establishment.cnes === searchFilter ||
      establishment.nome_fanta
        .toUpperCase()
        .includes(searchFilter.toUpperCase()),
  );

  return (
    <>
      <EstablishmentModal
        establishment={selectedEstablishment}
        refresh={() => {
          setSelectedEstablishment(null);
          setPage(0);
          refresh();
        }}
        onClose={() => {
          setSelectedEstablishment(null);
        }}
      />

      <PageHeader title="Estabelecimentos" />

      <div style={{ marginBottom: '24px' }}>
        <TextField
          label="Filtrar por nome ou CNES"
          variant="outlined"
          value={searchFilter}
          onChange={(e) => {
            setPage(0);
            setSearchFilter(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <CenteredCell></CenteredCell>
              <CenteredCell>CNES</CenteredCell>
              <CenteredCell>Razão Social</CenteredCell>
              <CenteredCell>Nome Fantasia</CenteredCell>
              <CenteredCell>Logradouro</CenteredCell>
              <CenteredCell>Número</CenteredCell>
              <CenteredCell>Complemento</CenteredCell>
              <CenteredCell>Bairro</CenteredCell>
              <CenteredCell>CEP</CenteredCell>
              <CenteredCell>Telefone</CenteredCell>
              <CenteredCell>Fax</CenteredCell>
              <CenteredCell>E-mail</CenteredCell>
              <CenteredCell>CPF</CenteredCell>
              <CenteredCell>CNPJ</CenteredCell>
              <CenteredCell>Latitude</CenteredCell>
              <CenteredCell>Longitude</CenteredCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((establishment) => (
                <TableRow key={establishment.id}>
                  <CenteredCell>
                    <IconButton
                      onClick={() => {
                        setSelectedEstablishment(establishment);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </CenteredCell>
                  <CenteredCell>{establishment.cnes}</CenteredCell>
                  <CenteredCell>{establishment.r_social}</CenteredCell>
                  <CenteredCell>{establishment.nome_fanta}</CenteredCell>
                  <CenteredCell>{establishment.logradouro}</CenteredCell>
                  <CenteredCell>{establishment.numero}</CenteredCell>
                  <CenteredCell>{establishment.complement}</CenteredCell>
                  <CenteredCell>{establishment.bairro}</CenteredCell>
                  <CenteredCell>{establishment.cod_cep}</CenteredCell>
                  <CenteredCell>{establishment.telefone}</CenteredCell>
                  <CenteredCell>{establishment.fax}</CenteredCell>
                  <CenteredCell>{establishment.e_mail}</CenteredCell>
                  <CenteredCell>{establishment.cpf}</CenteredCell>
                  <CenteredCell>{establishment.cnpj}</CenteredCell>
                  <CenteredCell>
                    {establishment.geolocation?.latitude || ''}
                  </CenteredCell>
                  <CenteredCell>
                    {establishment.geolocation?.longitude || ''}
                  </CenteredCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {(loading || filteredRows.length === 0) && (
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            filteredRows.length === 0 && 'Nenhum estabelecimento encontrado'
          )}
        </div>
      )}

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default Establishments;
