import { floatToInt, intToSimOrNao, Indicator } from '../indicatorsList';

const gestantesSifHiv: Indicator = {
  title: 'Gestantes com realização de exames para sífilis e HIV',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'Micro Area', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Sífilis', key: 'sifilis', transform: intToSimOrNao },
    { title: 'HIV', key: 'hiv', transform: intToSimOrNao },
    { title: 'Idade', key: 'idade', transform: floatToInt },
    { title: 'Celular', key: 'celular' },
    {
      title: 'Consistente',
      key: 'st_cidadao_consistente',
      transform: intToSimOrNao,
    },
    { title: 'Válido', key: 'valido', transform: intToSimOrNao },
  ],
};

export default gestantesSifHiv;
