import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import maskNumber from '../../../utils/maskNumber';
import axios from 'axios';
import { useMessage } from '../../../context/message';
import { API_ROUTES, axiosInstance } from '../../../api';
import {
  ImportedLinkProfessional,
  ImportedOnlineLinkProfessional,
} from './interfaces';
import useDebounce from '../../../hooks/useDebounce';
import PageHeader from '../../../components/PageHeader';

const rowsPerPage = 15;

function ImportedProfessionals() {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [competence, setCompetence] = useState('');
  const [cboFilter, setCboFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [importType, setImportType] = useState('local');
  const [links, setLinks] = useState<
    ImportedLinkProfessional[] | ImportedOnlineLinkProfessional[]
  >([]);

  const message = useRef(useMessage());

  const debouncedFilter = useDebounce(searchFilter, 500);
  const debouncedCbo = useDebounce(cboFilter, 500);

  function getFilterType(isLocal: boolean, searchFilter: string) {
    if (!isLocal) {
      return searchFilter.length === 11
        ? 'profissionais__cpf_prof'
        : searchFilter.length === 15
        ? 'profissionais__co_cns'
        : 'profissionais__nm_prof__icontains';
    }

    return searchFilter.length === 11
      ? 'profissional__cpf_prof'
      : searchFilter.length === 15
      ? 'profissional__cod_cns'
      : 'profissional__nome_prof__icontains';
  }

  function getCboFilterType(isLocal: boolean, cbo: string) {
    if (!isLocal) {
      return 'co_cbo';
    }
    return isNaN(+cbo) ? 'ds_cbo__icontains' : 'cod_cbo';
  }

  useEffect(() => {
    const comp = competence.split('/').reverse().join('').replace(/[\D]/g, '');

    if (comp.length > 0 && comp.length !== 6) {
      return;
    }

    const source = axios.CancelToken.source();
    setLoading(true);
    setLinks([]);

    (async () => {
      try {
        const isLocal = importType === 'local';
        const response = await axiosInstance.get(
          isLocal
            ? API_ROUTES.IMPORTS_PROFESSIONAL_LINKS
            : API_ROUTES.IMPORTS_PROFESSIONAL_LINKS_ONLINE,
          {
            cancelToken: source.token,
            params: {
              page: page + 1,
              competence: comp || undefined,
              [getCboFilterType(isLocal, debouncedCbo)]:
                debouncedCbo || undefined,
              [getFilterType(isLocal, debouncedFilter)]:
                debouncedFilter || undefined,
            },
          },
        );

        setLinks(response.data.results);
        setCount(response.data.count);

        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.message ||
            'Ocorreu um erro ao carregar a lista de profissionais',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, competence, debouncedCbo, debouncedFilter, importType]);

  return (
    <>
      <PageHeader title="Profissionais Importados" />

      <div style={{ marginBottom: '24px', display: 'flex', gap: '8px' }}>
        <FormControl variant="outlined">
          <InputLabel>Tipo de Importação</InputLabel>
          <Select
            label="Tipo de Importação"
            style={{ minWidth: 144 }}
            value={importType}
            onChange={(e) => {
              setPage(0);
              setImportType(e.target.value as string);
            }}
          >
            <MenuItem value="local">XML Local</MenuItem>
            <MenuItem value="online">XML Online</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label="Competência"
          placeholder="mm/aaaa"
          style={{ width: 180 }}
          value={competence}
          onChange={(e) => {
            setPage(0);
            setCompetence(maskNumber(e.target.value, '##/####'));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Filtrar por Nome/CPF/CNS"
          variant="outlined"
          value={searchFilter}
          onChange={(e) => {
            setPage(0);
            setSearchFilter(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Filtrar por CBO"
          variant="outlined"
          value={cboFilter}
          onChange={(e) => {
            setPage(0);
            setCboFilter(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">CPF</TableCell>
              <TableCell align="center">CNS</TableCell>
              <TableCell align="center">CBO</TableCell>
              <TableCell>Descrição CBO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {importType === 'local'
              ? (links as ImportedLinkProfessional[]).map((link) => (
                  <TableRow key={link.id}>
                    <TableCell>{link.profissional?.nome_prof}</TableCell>
                    <TableCell align="center">
                      {link.profissional?.cpf_prof}
                    </TableCell>
                    <TableCell align="center">
                      {link.profissional?.cod_cns}
                    </TableCell>
                    <TableCell align="center">{link.cod_cbo}</TableCell>
                    <TableCell>{link.ds_cbo}</TableCell>
                  </TableRow>
                ))
              : (links as ImportedOnlineLinkProfessional[]).map((link) => (
                  <TableRow key={link.id}>
                    <TableCell>{link.nm_prof}</TableCell>
                    <TableCell align="center">{link.cpf_prof}</TableCell>
                    <TableCell align="center">{link.co_cns}</TableCell>
                    <TableCell align="center">{link.co_cbo}</TableCell>
                    <TableCell>{link.ds_cbo}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      {loading && (
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default ImportedProfessionals;
