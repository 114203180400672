import { Card, CardContent, Typography } from '@material-ui/core';
import { cloneElement } from 'react';
import { useStyles } from './styles';

export interface SolicitationCardProps {
  title: string;
  value: number;
  icon: any;
}

function SolicitationCard(props: SolicitationCardProps) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardBody}>
        <div>
          <Typography variant="body2" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="h5" component="h2">
            {props.value}
          </Typography>
        </div>
        {cloneElement(props.icon, {
          className: classes.icon,
        })}
      </CardContent>
    </Card>
  );
}

export default SolicitationCard;
