import { CSSProperties } from 'react';
import { useProvideTheme } from '../../context/theme';
import LightLogo from '../../assets/logo_light.png';
import DarkLogo from '../../assets/logo.png';
import SmallLogo from '../../assets/logo_small.png';

interface LogoProps {
  mode?: 'default' | 'light' | 'dark' | 'small';
  height?: number;
  width?: number;
  style?: CSSProperties;
  className?: string;
  logoOverride?: string;
}

function Logo(props: LogoProps) {
  const provideTheme = useProvideTheme();

  function getLogo() {
    return (
      props.logoOverride ||
      (() => {
        const light = process.env.REACT_APP_LOGO_LIGHT || LightLogo;
        const dark = process.env.REACT_APP_LOGO_DARK || DarkLogo;

        switch (props.mode) {
          case 'light':
            return light;
          case 'dark':
            return dark;
          case 'small':
            return process.env.REACT_APP_LOGO_DARK || SmallLogo;
          default:
            return provideTheme.darkMode ? light : dark;
        }
      })()
    );
  }

  return (
    <img
      src={getLogo()}
      style={props.style}
      className={props.className}
      height={props.height}
      width={props.width}
      alt="Logo"
    />
  );
}

export default Logo;
