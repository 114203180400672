import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance, API_ROUTES } from '../../../../api';
import { useMessage } from '../../../../context/message';
import Professional from '../../professional.interface';

export default function useProfessionals() {
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance.get(API_ROUTES.PROFESSIONALS, {
          cancelToken: source.token,
          params: {
            no_page: true,
          },
        });

        setProfessionals(
          response.data.map(
            (professional: any): Professional => ({
              id: professional.id,
              name: professional.nome_prof,
              cpf: professional.cpf_prof,
              cns: professional.cod_cns,
              cbo: professional.cod_cbo,
            }),
          ),
        );
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setProfessionals([]);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.detail ||
            'Ocorreu um erro ao carregar a lista de profissionais.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return professionals;
}
