import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
    },
    details: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    icon: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  }),
);

export default useStyles;
