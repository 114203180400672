import { Typography } from '@material-ui/core';
import { useAuth } from 'context/auth';
import { mapRole } from 'utils/roles';

function UserProfileName() {
  const auth = useAuth();

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>
        {(() => {
          if (!auth.user?.profile.name) {
            return 'Usuário';
          }

          const splitName = auth.user.profile.name.split(' ').slice(0, 3);

          const parts =
            splitName.length > 1 && splitName[splitName.length - 1].length <= 3
              ? splitName.filter((el) => el.length > 3)
              : splitName;

          return parts.join(' ');
        })()}
      </div>
      <Typography variant="caption">
        {(() => {
          const role = mapRole(auth.user?.role);
          const city = auth.user?.profile.living_city.name;

          return `${role} - ${city}`;
        })()}
      </Typography>
    </div>
  );
}

export default UserProfileName;
