import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import AllCitizens from './Tables/AllCitizens';
import DuplicatedCitizens from './Tables/DuplicatedCitizens';

function Citizens() {
  const [listType, setListType] = useState<'all' | 'duplicated'>('all');

  return (
    <>
      <PageHeader title="Cidadãos" />

      <FormControl
        variant="outlined"
        style={{ marginBottom: 16, marginRight: 16 }}
      >
        <InputLabel>Selecione o tipo de listagem</InputLabel>
        <Select
          label="Selecione o tipo de listagem"
          value={listType}
          onChange={(e) => {
            setListType(e.target.value as 'all' | 'duplicated');
          }}
          style={{ minWidth: '224px' }}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="duplicated">Duplicados</MenuItem>
        </Select>
      </FormControl>

      {listType === 'all' ? <AllCitizens /> : <DuplicatedCitizens />}
    </>
  );
}

export default Citizens;
