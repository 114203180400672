import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import { useStyles } from './CardWithCircle.styles';

interface CardWithCircleProps {
  data: {
    label: string[];
    value: number;
  } | null;
}

function CardWithCircle({ data }: CardWithCircleProps) {
  const classes = useStyles();

  return (
    <div className={classes.dataItem}>
      <div className={classes.dataLabels}>
        {data && data.label.map((val, idx) => <span key={idx}>{val}</span>)}
      </div>
      <div>{data && <CircularProgressWithLabel value={data.value} />}</div>
    </div>
  );
}

export default CardWithCircle;
