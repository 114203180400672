import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  userPictureCanvas: {
    maxWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  picture: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    transition: 'all 0.1s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '1px 2px 1px #555;',
    },
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: theme.spacing(15),
    height: theme.spacing(15),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
  imageDescription: {
    color: theme.palette.text.hint,
    fontSize: '12px',
    textAlign: 'center',
  },
}));

export default useStyles;
