// TODO: test this in all browsers
function downloadBlob(blob: Blob, filename: string) {
  // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
  const isWebView = !!navigator.userAgent.match(/\(.+ +wv\)/);

  // This code should be removed after we build the native Previne Brasil App
  // WebViews can't download blobs, so we need to convert it to base64 and pass to the native JSInterface
  if (isWebView) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const result = reader.result;
      if (result) {
        const base64data = result.toString().replace(/^data:.*;base64,/, '');
        (window as any).JSInterface.downloadBase64(filename, base64data);
      }
    };
  } else {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

export default downloadBlob;
