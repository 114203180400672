import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dataItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: '4px',
    boxShadow: theme.shadows[3],
  },
  dataLabels: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
