import { Typography, makeStyles } from '@material-ui/core';

interface Props {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
  },
}));

function PageHeader(props: Props) {
  const classes = useStyles();

  return (
    <Typography
      variant="h4"
      component="h1"
      className={classes.header}
      paragraph
    >
      {props.title}
    </Typography>
  );
}

export default PageHeader;
