import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { API_ROUTES, axiosInstance } from '../api';
import { useMessage } from '../context/message';
import { Team } from './useTeams';

export interface Professional {
  co_seq_dim_profissional: number;
  nu_cns: string;
  no_profissional: string;
  st_registro_valido: number;
  ds_filtro: string;
}

export default function useProfessionals() {
  const [team, setTeam] = useState<Team | null>(null);
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      try {
        const response = await axiosInstance(API_ROUTES.ESUS_PROFESSIONALS, {
          cancelToken: source.token,
          params: {
            equipe: team?.co_seq_dim_equipe || undefined,
          },
        });

        const professionals = (response.data.results as Professional[]).map(
          (professional) => ({
            ...professional,
            no_profissional: professional?.no_profissional.toUpperCase(),
          }),
        );

        setProfessionals(professionals);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar o filtro de professionais',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [team]);

  return { professionals, setTeam };
}
