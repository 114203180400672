import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import AddIcon from '@material-ui/icons/Add';
import DiseaseCard from './DiseaseCard';
import useStyles from './diseases.styles';
import DiseaseModal from './DiseaseModal';
import axios from 'axios';
import DeleteDiseaseModal from './DeleteDiseaseModal';
import PageHeader from '../../components/PageHeader';

export interface Disease {
  id: number;
  title: string;
  icon: string;
  priority: number;
}

function Diseases() {
  const classes = useStyles();
  const message = useRef(useMessage());
  const [tab, setTab] = useState(0);
  const [diseases, setDiseases] = useState<Disease[]>([]);
  const [diseaseModalOpen, setDiseaseModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDisease, setSelectedDisease] = useState<Disease | undefined>();
  const [refreshCount, setRefreshCount] = useState(0);

  function handleTabChange(_: any, newValue: number) {
    setTab(newValue);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setDiseases([]);

    (async () => {
      try {
        const url = tab === 0 ? API_ROUTES.SYMPTOMS : API_ROUTES.CONDITIONS;
        const response = await axiosInstance.get(url, {
          cancelToken: source.token,
        });

        const diseases: Disease[] = [];
        response.data.forEach((data: any) => {
          diseases.push({
            id: data.id,
            title: data.title,
            icon: data.icone || data.icon,
            priority: data.nivel || data.level,
          });
        });

        setDiseases(diseases);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        message.current.setMessage({
          type: 'error',
          text: 'Ocorreu um erro ao carregar as enfermidades',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [tab, refreshCount]);

  function getCurrentType() {
    return tab === 0 ? 'symptom' : 'disease';
  }

  function openDiseaseModal() {
    setDiseaseModalOpen(true);
  }

  function refreshList() {
    setDiseaseModalOpen(false);
    setDeleteModalOpen(false);
    setSelectedDisease(undefined);
    setRefreshCount((refresh) => refresh + 1);
  }

  return (
    <>
      <PageHeader title="Enfermidades" />

      <DiseaseModal
        type={getCurrentType()}
        open={diseaseModalOpen}
        disease={selectedDisease}
        handleSuccess={() => {
          refreshList();
        }}
        handleClose={() => {
          setDiseaseModalOpen(false);
          setSelectedDisease(undefined);
        }}
      />

      <DeleteDiseaseModal
        open={deleteModalOpen}
        disease={selectedDisease}
        type={getCurrentType()}
        handleSuccess={() => {
          refreshList();
        }}
        handleClose={() => {
          setDeleteModalOpen(false);
          setSelectedDisease(undefined);
        }}
      />

      <div className={classes.root}>
        <Paper square className={classes.tabsContainer}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Sintomas" />
            <Tab label="Condições" />
          </Tabs>
          <Button
            onClick={() => {
              openDiseaseModal();
            }}
          >
            <AddIcon />
          </Button>
        </Paper>

        <div className={classes.diseasesContainer}>
          {diseases.map((disease, idx) => (
            <DiseaseCard
              key={idx}
              disease={disease}
              handleEditClick={() => {
                setSelectedDisease(disease);
                setDiseaseModalOpen(true);
              }}
              handleDeleteClick={() => {
                setSelectedDisease(disease);
                setDeleteModalOpen(true);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Diseases;
