import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from '../CardWithCircle/CardWithCircle.styles';

function CardWithCircleSkeleton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const skeletonRectWidth = isMobile ? 150 : 300;

  return (
    <div className={classes.dataItem}>
      <div className={classes.dataLabels} style={{ gap: '10px' }}>
        <Skeleton variant="rect" width={skeletonRectWidth} height={25} />
        <Skeleton variant="rect" width={skeletonRectWidth} height={15} />
        <Skeleton variant="rect" width={skeletonRectWidth} height={15} />
      </div>
      <div>
        <Skeleton variant="circle" width={80} height={80} />
      </div>
    </div>
  );
}

export default CardWithCircleSkeleton;
