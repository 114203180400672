import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  fieldset: {
    borderRadius: '5px',
    borderColor: theme.palette.text.hint,
    marginBottom: theme.spacing(2),
    '& > legend': {
      color: theme.palette.text.hint,
    },
  },
  userPicture: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  photoCanvas: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

export default useStyles;
