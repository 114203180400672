import {
  TableCell,
  TableRow,
} from '@material-ui/core';
import City from '../city.interface';

interface CityTableRowProps {
  city: City;
}

function CitiesTableRow ({ city }: CityTableRowProps) {
  return (
    <TableRow>
      <TableCell align="center">{city.id}</TableCell>
      <TableCell align="center">{city.name}</TableCell>
      <TableCell align="center">{city.connection_name}</TableCell>
      <TableCell align="center">{city.sigus_connection_name}</TableCell>
    </TableRow>
  );
}

export default CitiesTableRow;
