import axios from 'axios';
import { getStorage, TOKEN_KEY } from '../context/auth';
import { API_URL } from './constants';

const instance = axios.create({ baseURL: API_URL });

instance.interceptors.request.use(
  (config) => {
    const token = getStorage().getItem(TOKEN_KEY);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { instance };
