import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { Roles } from '../../../utils/roles';
import Professional from '../professional.interface';
import User from '../user.interface';

interface ConfirmAssociationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirmation: () => void;
  user: User | null;
  professional: Professional | null;
  role: Roles;
}

function ConfirmAssociationDialog(props: ConfirmAssociationDialogProps) {
  const message = useMessage();

  async function confirmAssociation() {
    try {
      await axiosInstance.put(API_ROUTES.USERS_UPDATE_ROLE, {
        cpf: props.user?.cpf,
        professional_id: props.professional?.id,
        role: props.role,
      });

      message.setMessage({
        type: 'success',
        text: 'Usuário associado com sucesso.',
      });

      props.handleConfirmation();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          'Ocorreu um erro ao associar o usuário ao profissional.',
      });
    }
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Confirmar associação</DialogTitle>
      <DialogContent>
        Deseja mesmo associar o usuário <b>{props.user?.name || ''} </b>
        com o profissional
        <b> {props.professional?.name || ''}</b>?
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Não
        </Button>
        <Button color="primary" onClick={confirmAssociation}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmAssociationDialog;
