import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import useEstablishments from '../../../hooks/useEstablishments';
import { Icon } from 'leaflet';
import MarkerIcon from '../../../assets/hospital-marker.png';
import AvatarImage from '../../../assets/avatar.png';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';

function MapComponent() {
  const auth = useAuth();
  const { establishments } = useEstablishments();
  const [
    userCoordinates,
    setUserCoordinates,
  ] = useState<GeolocationCoordinates | null>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCoordinates(position.coords);
        },
        () => {
          setUserCoordinates(null);
        },
      );
    }
  }, []);

  return (
    <div
      style={{ marginTop: '2em', marginBottom: '2em', overflow: 'hidden' }}
      className="MuiPaper-elevation1 MuiPaper-rounded"
    >
      <MapContainer
        center={[-5.7999146, -35.2922843]}
        zoom={10}
        style={{ minHeight: '300px', height: 'calc(100vh - 300px)' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {userCoordinates && (
          <Marker
            position={[userCoordinates.latitude, userCoordinates.longitude]}
            icon={
              new Icon({
                iconUrl: auth.user?.profile.image || AvatarImage,
                iconSize: [32, 32],
              })
            }
          >
            <Popup>
              <b>Você está aqui</b>
            </Popup>
          </Marker>
        )}
        {establishments
          .filter((e) => e.geolocation)
          .map((establishment) => (
            <Marker
              key={establishment.id}
              position={[
                +establishment.geolocation!.latitude,
                +establishment.geolocation!.longitude,
              ]}
              icon={
                new Icon({
                  iconUrl: MarkerIcon,
                  iconSize: [32, 32],
                })
              }
            >
              <Popup>
                <b>{establishment.nome_fanta}</b>
                <br />
                {establishment.logradouro}, {establishment.numero}
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  );
}

export default MapComponent;
