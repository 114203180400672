import axios, { AxiosResponse } from 'axios';
import { useState, useRef, useEffect } from 'react';
import { axiosInstance, API_ROUTES } from '../api';
import { useMessage } from '../context/message';

export interface City {
  id: number;
  name: string;
}

function useCities() {
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState<City[]>([]);

  const message = useRef(useMessage());

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setCities([]);

    (async () => {
      try {
        const request: AxiosResponse<City[]> = await axiosInstance.get(
          API_ROUTES.ADMINISTRATION_CITIES_SELECT,
          {
            cancelToken: source.token,
          },
        );

        setCities(request.data);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.detail ||
            'Ocorreu um erro ao carregar a lista de cidades.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  return { cities, loading };
}

export default useCities;
