import { REMEMBER_ME_KEY } from './constants';

/**
 * This function returns the correct storage for things related to authentication.
 *
 * If user checked "remember me" option on login it will return localStorage, otherwise it will return sessionStorage.
 */
export function getStorage(): Storage {
  const keepLogged = localStorage.getItem(REMEMBER_ME_KEY) === 'true';

  return keepLogged ? localStorage : sessionStorage;
}
