import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { convertDateFromPtBRToISO8601 } from '../../../utils/date_converter';
import maskNumber from '../../../utils/maskNumber';
import { Roles } from '../../../utils/roles';
import Professional from '../professional.interface';
import useEstablishments from '../../../hooks/useEstablishments';
import useProfessionals from './hooks/useProfessionals';

interface Props {
  open: boolean;
  onClose: () => void;
}

// TODO: refactor this component
// I feel like it's too big, so maybe split into multiple files?
// Or handle the form in a better way
function CreateUserDialog(props: Props) {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [susCard, setSusCard] = useState('');
  const [birthCity, setBirthCity] = useState('');
  const [mothersName, setMothersName] = useState('');
  const [establishment, setEstablishment] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState<Roles>(Roles.Enfermeiro);
  const [professional, setProfessional] = useState<Professional | null>(null);

  const { establishments } = useEstablishments();
  const professionals = useProfessionals();
  const message = useMessage();

  function getErrorType(key: string) {
    switch (key) {
      case 'name':
        return 'Nome: ';
      case 'cpf':
        return 'CPF: ';
      case 'email':
        return 'E-mail: ';
      case 'birth_date':
        return 'Data de Nascimento: ';
      case 'birth_city':
        return 'Município de Nascimento: ';
      case 'card_sus':
        return 'Cartão do SUS: ';
      case 'establishment':
        return 'Estabelecimento: ';
      case 'professional':
        return 'Profissional: ';
      case 'phone':
        return 'Telefone: ';
      default:
        return '';
    }
  }

  async function onSubmit() {
    try {
      await axiosInstance.post(API_ROUTES.USERS_CREATE, {
        name,
        cpf: cpf.replace(/\D/g, ''),
        email,
        birth_date: convertDateFromPtBRToISO8601(birthDate),
        birth_city: birthCity,
        phone: phone.replace(/\D/g, ''),
        mother_name: mothersName || null,
        card_sus: susCard?.replace(/\D/g, '') || undefined,
        establishment,
        role,
        professional: professional?.id,
      });

      message.setMessage({
        type: 'success',
        text: `E-mail de confirmação enviado para ${email}`,
      });

      props.onClose();
    } catch (error: any) {
      // TODO refactor this, maybe do validations in the frontend instead of only using the backend
      let msg = 'Ocorreu um erro ao criar o usuário';
      if (error.response?.data) {
        const err = Object.entries(error.response.data)[0];
        const begin = getErrorType(err[0]);
        if (begin) {
          msg = `${begin} ${(err[1] as string)[0]}`;
        }
      }
      message.setMessage({
        type: 'error',
        text: msg,
      });
    }
  }

  useEffect(() => {
    setName('');
    setCpf('');
    setEmail('');
    setBirthCity('');
    setBirthDate('');
    setSusCard('');
    setMothersName('');
    setEstablishment('');
    setPhone('');
    setProfessional(null);
  }, [props.open]);

  return (
    <Dialog open={props.open}>
      <DialogTitle>Cadastrar Usuário</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 1000 }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <TextField
            variant="outlined"
            label="Nome"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <div style={{ display: 'flex', gap: 8 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="CPF"
              value={maskNumber(cpf, '###.###.###-##')}
              onChange={(e) => {
                setCpf(e.target.value.replace(/\D/g, '').substring(0, 11));
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Cartão do SUS"
              value={maskNumber(susCard, '### #### #### ####')}
              onChange={(e) => {
                setSusCard(e.target.value.substring(0, 18));
              }}
            />
          </div>

          <div style={{ display: 'flex', gap: 8 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="E-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Data de Nascimento"
              value={maskNumber(birthDate, '##/##/####')}
              onChange={(e) => {
                setBirthDate(e.target.value.substring(0, 10));
              }}
            />
          </div>

          <TextField
            fullWidth
            variant="outlined"
            label="Telefone"
            value={maskNumber(phone, '(##) # ####-####')}
            onChange={(e) => {
              setPhone(e.target.value.substring(0, 16));
            }}
          />

          <div style={{ display: 'flex', gap: 8 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Município de Nascimento"
              value={birthCity}
              onChange={(e) => {
                setBirthCity(e.target.value);
              }}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Nome da mãe"
              value={mothersName}
              onChange={(e) => {
                setMothersName(e.target.value);
              }}
            />
          </div>
          <FormControl variant="outlined">
            <InputLabel>Estabelecimento</InputLabel>
            <Select
              label="Estabelecimento"
              value={establishment}
              onChange={(e) => {
                setEstablishment(e.target.value as string);
              }}
            >
              <MenuItem value="">Selecione</MenuItem>
              {establishments.map((establishment) => (
                <MenuItem
                  key={establishment.id}
                  value={establishment.id.toString()}
                >
                  {establishment.nome_fanta}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ display: 'flex', gap: 8 }}>
            <FormControl variant="outlined" style={{ minWidth: 150 }}>
              <InputLabel>Tipo</InputLabel>
              <Select
                label="Tipo"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value as Roles);
                }}
              >
                <MenuItem value={Roles.Enfermeiro}>Enfermeiro</MenuItem>
                <MenuItem value={Roles.Medico}>Médico</MenuItem>
              </Select>
            </FormControl>

            <Autocomplete
              fullWidth
              autoHighlight
              value={professional}
              options={professionals}
              onChange={(_e, val) => {
                setProfessional(val);
              }}
              noOptionsText="Nenhum profissional encontrado"
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Profissional"
                  variant="outlined"
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button variant="outlined" color="primary" onClick={onSubmit}>
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateUserDialog;
