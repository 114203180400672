import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import Vaccine from '../interfaces';

interface Props {
  open: boolean;
  selectedVaccine: Vaccine | null;
  handleClose: () => void;
  handleRefresh: () => void;
}

function DeleteVaccine(props: Props) {
  const message = useMessage();

  async function confirmDelete() {
    try {
      await axiosInstance.delete(
        API_ROUTES.VACCINES_DELETE.replace(
          ':id',
          props.selectedVaccine?.id.toString() || '-1',
        ),
      );
      message.setMessage({
        type: 'success',
        text: 'Vacina deletada com sucesso',
      });
      props.handleRefresh();
      props.handleClose();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text: 'Ocorreu um erro ao deletar a vacina',
      });
    }
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Deletar Vacina</DialogTitle>
      <DialogContent dividers>
        Você tem certeza que deseja deletar a vacina{' '}
        <b>{props.selectedVaccine?.name}</b>?
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.handleClose}
        >
          Cancelar
        </Button>
        <Button variant="outlined" color="primary" onClick={confirmDelete}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteVaccine;
