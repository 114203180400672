import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  PropTypes,
  SvgIconTypeMap,
  Typography,
  Box,
} from '@material-ui/core';
import {
  CloseReason,
  OpenReason,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@material-ui/lab';
import CallIcon from '@material-ui/icons/Call';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import DoneIcon from '@material-ui/icons/Done';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import { useRef, useState } from 'react';
import ProfilePicture from '../../../assets/avatar.png';
import { useStyles } from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useMessage } from '../../../context/message';
import { APP_ROUTES } from '../../../components/Routes';
import Solicitation from '../utils/solicitation.interface';
import FinishSolicitationModal from '../FinishSolicitationModal';
import { useAuth } from '../../../context/auth';
import { Roles } from '../../../utils/roles';
import ForwardSolicitationModal from '../ForwardSolicitationModal';
import PrescribeModal from '../PrescribeModal';

interface SolicitationModalProps {
  handleClose: () => void;
  handleExited: () => void;
  forceRefresh: () => void;
  open: boolean;
  solicitation: Solicitation | null;
}

interface Action {
  title: string;
  color: PropTypes.Color;
  icon: OverridableComponent<SvgIconTypeMap>;
  click?: () => void;
}

function SolicitationModal(props: SolicitationModalProps) {
  const classes = useStyles();
  const auth = useAuth();
  const message = useRef(useMessage());
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);
  const [prescribeModalOpen, setPrescribeModalOpen] = useState(false);

  async function handleOpenCall() {
    if (!props.solicitation?.patient.fcmToken) {
      message.current.setMessage({
        type: 'error',
        text: 'Paciente não tem celular cadastrado no sistema',
      });
      return;
    }

    window.open(
      APP_ROUTES.VIDEO_CALL.replace(':id', String(props.solicitation.id)),
      'Video Call',
      'menubar=0,toolbar=0,location=0,status=0',
    );
  }

  function shouldShowActions() {
    return (
      !props.solicitation?.finalization &&
      [Roles.Medico, Roles.Enfermeiro].includes(auth.user!.role as Roles)
    );
  }

  const actions: Action[] = [
    {
      title: 'Atender',
      color: 'primary',
      icon: CallIcon,
      click: handleOpenCall,
    },
    {
      title: 'Encaminhar',
      color: 'primary',
      icon: PhoneForwardedIcon,
      click: () => {
        setForwardModalOpen(true);
      },
    },
    {
      title: 'Receitar',
      color: 'primary',
      icon: ReceiptIcon,
      click: () => {
        setPrescribeModalOpen(true);
      },
    },
    {
      title: 'Finalizar',
      color: 'primary',
      icon: DoneIcon,
      click: () => {
        setFinishModalOpen(true);
      },
    },
  ];

  // TODO: responsive layout
  return (
    <>
      {props.solicitation && (
        <>
          <FinishSolicitationModal
            open={finishModalOpen}
            solicitation={props.solicitation}
            handleClose={() => {
              setFinishModalOpen(false);
            }}
            handleSuccess={() => {
              setFinishModalOpen(false);
              props.forceRefresh();
              props.handleClose();
            }}
          />

          <ForwardSolicitationModal
            open={forwardModalOpen}
            solicitation={props.solicitation}
            handleClose={() => {
              setForwardModalOpen(false);
            }}
            handleSuccess={() => {
              setForwardModalOpen(false);
              props.forceRefresh();
              props.handleClose();
            }}
          />

          <PrescribeModal
            open={prescribeModalOpen}
            solicitation={props.solicitation}
            handleClose={() => {
              setPrescribeModalOpen(false);
            }}
            handleSuccess={() => {
              setPrescribeModalOpen(false);
              props.forceRefresh();
              props.handleClose();
            }}
          />
        </>
      )}

      <Dialog
        open={props.open}
        onClose={props.handleClose}
        onExited={props.handleExited}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="h6">Detalhes da solicitação</Typography>
          {props.solicitation && (
            <>
              <Chip
                label={props.solicitation.status}
                color="primary"
                size="small"
                className={classes.chip}
              />
              <Chip
                label={props.solicitation.priority}
                color="secondary"
                size="small"
                className={classes.chip}
              />
            </>
          )}
          <Button className={classes.closeButton} onClick={props.handleClose}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        {!props.solicitation ? (
          <DialogContent dividers>
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <DialogContent dividers>
            <div className={classes.solicitationContainer}>
              <div className={classes.userDetails}>
                <Avatar
                  className={classes.userPicture}
                  src={props.solicitation.patient.picture || ProfilePicture}
                />
                <Typography variant="h6">
                  {props.solicitation.patient.name}
                </Typography>
                <Typography variant="subtitle1">{`${props.solicitation.patient.age} anos`}</Typography>
              </div>
              <Divider orientation="vertical" flexItem />
              <div className={classes.detailsContainer}>
                <div className={classes.flex}>
                  {[
                    [
                      'Data da Solicitação',
                      props.solicitation.solicitationDate,
                    ],
                    [
                      'Data do Início dos Sintomas',
                      props.solicitation.symptomsDate,
                    ],
                  ].map((el) => (
                    <div key={el[0]} className={classes.flex1}>
                      <Typography>{el[0]}</Typography>
                      <Typography style={{ paddingLeft: '16px' }}>
                        {el[1]}
                      </Typography>
                    </div>
                  ))}
                </div>

                <Divider />

                <div className={classes.flex}>
                  {[
                    ['Sintomas', props.solicitation.symptoms],
                    ['Condições', props.solicitation.conditions],
                  ].map((el, idx) => (
                    <div key={idx} className={classes.flex1}>
                      <Typography>{el[0]}</Typography>
                      <List disablePadding={true}>
                        {(el[1] as string[]).map((item, idx) => (
                          <ListItem key={idx} className={classes.listItem}>
                            <ListItemText primary={item} />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  ))}
                </div>

                <Divider />

                <div>
                  <Typography>Observações do paciente</Typography>
                  <Typography className={classes.observations}>
                    {props.solicitation.observations ||
                      'Nenhuma informação adicional fornecida.'}
                  </Typography>
                </div>

                {props.solicitation.finalization && (
                  <>
                    <Divider />
                    <div>
                      <Typography>Observações de Finalização</Typography>
                      <Typography className={classes.observations}>
                        {props.solicitation.finalization.reason}
                      </Typography>
                    </div>
                  </>
                )}

                {shouldShowActions() ? (
                  <>
                    <Divider />

                    <div className={classes.speedDialContainer}>
                      <SpeedDial
                        ariaLabel="Ações"
                        icon={<SpeedDialIcon />}
                        onClose={(_: any, reason: CloseReason) => {
                          if (reason === 'toggle') {
                            setSpeedDialOpen(false);
                          }
                        }}
                        onOpen={(_: any, reason: OpenReason) => {
                          if (reason === 'toggle') {
                            if (!auth.user!.association) {
                              message.current.setMessage({
                                type: 'error',
                                text:
                                  'Você precisa estar associado a um profissional',
                              });
                            } else {
                              setSpeedDialOpen(true);
                            }
                          }
                        }}
                        open={speedDialOpen}
                        direction="left"
                        className={classes.speedDial}
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.title}
                            icon={<action.icon />}
                            tooltipTitle={action.title}
                            onClick={action.click}
                          />
                        ))}
                      </SpeedDial>
                    </div>
                  </>
                ) : (
                  // The box gives some space at the bottom
                  <Box />
                )}
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default SolicitationModal;
