import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableCellTitle: {
    width: 0,
    whiteSpace: 'nowrap',
  },
  detailsTableContainer: {
    maxHeight: theme.spacing(50),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
