import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../context/auth';
import LogoPicker from './LogoPicker';
import { useStyles } from './logos.styles';

function Logos() {
  const auth = useAuth();
  const classes = useStyles();

  if (!auth.user) {
    return null;
  }

  return (
    <>
      <PageHeader title="Alterar Logos" />

      <div className={classes.logoPickerContainer}>
        <LogoPicker
          title="Portal"
          imageName="website"
          currentImage={auth.user.profile.living_city.logo_website}
        />
        <LogoPicker
          title="Aplicativo"
          imageName="app"
          currentImage={auth.user.profile.living_city.logo_app}
        />
        <LogoPicker
          title="Receitas"
          imageName="prescription"
          currentImage={auth.user.profile.living_city.logo_prescription}
        />
      </div>
    </>
  );
}

export default Logos;
