import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
