import React, { useEffect, useRef, useState } from 'react';
import { Avatar, CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import useStyles from './styles';
import AvatarImage from '../../../../assets/avatar.png';
import { API_ROUTES, axiosInstance } from '../../../../api';
import { useAuth } from '../../../../context/auth';
import { useMessage } from '../../../../context/message';

async function uploadPicture(image: File | null) {
  const form = new FormData();
  const arrayBuffer = (await image?.arrayBuffer()) as ArrayBufferLike;
  const blob = new Blob([new Uint8Array(arrayBuffer)], { type: image?.type });
  form.append('picture', blob, image?.name || '');
  await axiosInstance.put(API_ROUTES.PROFILE_PICTURE, form);
}

function AccountPicture() {
  const classes = useStyles();
  const auth = useAuth();
  const message = useRef(useMessage());

  const [image, setImage] = useState<File | null>(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    maxSize: 1024 * 1024 * 10, // 10mb
    onDropAccepted: async (acceptedFiles) => {
      setImage(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (image) {
      (async () => {
        try {
          await uploadPicture(image);
          setImage(null);
          await auth.refreshProfile();
          message.current.setMessage({
            type: 'success',
            text: 'Foto de perfil atualizada com sucesso',
          });
        } catch (error: any) {
          setImage(null);
          message.current.setMessage({
            type: 'error',
            text:
              error.response?.data?.message ??
              'Erro ao atualizar a foto de perfil',
          });
        }
      })();
    }
  }, [auth, image]);

  return (
    <div
      {...getRootProps({
        className: `dropzone ${classes.userPictureCanvas} ${classes.pointer}`,
      })}
    >
      <div style={{ position: 'relative' }}>
        <Avatar
          src={auth.user?.profile.image ?? AvatarImage}
          alt="Account Picture"
          title="Click ou Arraste para trocar sua foto"
          className={`${classes.picture}`}
        />
        {image && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
      <input {...getInputProps()} />
      <p className={classes.imageDescription}>
        Click ou Arraste para trocar sua foto
      </p>
    </div>
  );
}

export default AccountPicture;
