import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import maskNumber from '../../../utils/maskNumber';
import Professional from '../professional.interface';

interface Props {
  professional: Professional | null;
  onClose: () => void;
  refresh: () => void;
}

function EditProfessional(props: Props) {
  const [name, setName] = useState('');
  const [cns, setCns] = useState('');
  const [cbo, setCbo] = useState('');
  const message = useMessage();

  async function onSubmit() {
    try {
      const url = API_ROUTES.PROFESSIONALS_EDIT.replace(
        ':id',
        props.professional?.id.toString() || '',
      );

      await axiosInstance.patch(url, {
        nome_prof: name,
        cns,
        cbo,
      });

      message.setMessage({
        type: 'success',
        text: 'Profissional salvo com sucesso',
      });

      props.refresh();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text: error.data?.message || 'Ocorreu um erro ao salvar o profissional',
      });
    }
  }

  useEffect(() => {
    setName(props.professional?.name || '');
    setCns(props.professional?.cns || '');
    setCbo(props.professional?.cbo || '');
  }, [props.professional]);

  return (
    <Dialog open={!!props.professional} maxWidth="xs">
      <DialogTitle>Editar Profissional</DialogTitle>
      <DialogContent dividers>
        {/* 
            for some reason the width 100% doesn't work, 
            so this empty div keeps the width at the max 
        */}
        <div style={{ width: 600 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '100%',
          }}
        >
          <TextField
            variant="outlined"
            label="CPF"
            fullWidth
            disabled
            value={maskNumber(props.professional?.cpf || '', '###.###.###-##')}
          />
          <TextField
            variant="outlined"
            label="Nome"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            label="CNS"
            fullWidth
            value={cns}
            onChange={(e) => {
              setCns(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            label="CBO"
            fullWidth
            value={cbo}
            onChange={(e) => {
              setCbo(e.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProfessional;
