import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  tabBgColor: {
    backgroundColor: theme.palette.background.default,
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
    },
    boxShadow: theme.shadows[1],
  },
}));
