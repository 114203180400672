import { Card, IconButton, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Disease } from '../Diseases';
import useStyles from './diseaseCard.styles';

interface DiseaseCardProps {
  disease: Disease;
  handleEditClick: () => void;
  handleDeleteClick: () => void;
}

function DiseaseCard(props: DiseaseCardProps) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <img className={classes.icon} src={props.disease.icon} alt=" " />
        <div>
          <Typography variant="body1">{props.disease.title}</Typography>

          <Typography variant="body2" color="textSecondary">
            {`Prioridade ${props.disease.priority}`}
          </Typography>
        </div>
      </div>

      <div className={classes.actions}>
        <IconButton size="small" onClick={props.handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" onClick={props.handleDeleteClick}>
          <DeleteForeverIcon />
        </IconButton>
      </div>
    </Card>
  );
}

export default DiseaseCard;
