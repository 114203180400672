import { Indicator, floatToInt, intToSimOrNao } from '../indicatorsList';

const exameCito: Indicator = {
  title: 'Exame citopatológico',
  tableData: [
    { title: 'Equipe', key: 'equipe' },
    { title: 'MA', key: 'ma' },
    { title: 'Nome', key: 'cidadao' },
    { title: 'Idade', key: 'idade', transform: floatToInt },
    { title: 'CNS/CPF', key: 'cns_cpf' },
    { title: 'Celular', key: 'celular' },
    { title: 'Exames', key: 'exames' },
    { title: 'Consistente', key: 'consistente' },
    { title: 'Válido', key: 'valido', transform: intToSimOrNao },
  ],
};

export default exameCito;
