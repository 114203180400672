import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useMessage } from '../../../../context/message';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

// TODO: fix page type
interface PageLinkProps {
  page: any;
  setDrawerOpen: (val: boolean) => void;
}

function PageLink({ page, setDrawerOpen }: PageLinkProps) {
  const dimensions = useWindowDimensions();
  const message = useMessage();

  // these component props are only needed if the page has a component
  const props = page.component
    ? {
        component: NavLink,
        to: page.path,
        exact: false,
        activeClassName: 'Mui-selected',
        onClick: () => {
          // close the drawer if the window is small
          if (dimensions.width <= 960) {
            setDrawerOpen(false);
          }
        },
      }
    : {
        onClick: () =>
          message.setMessage({
            type: 'warning',
            text:
              'Essa funcionalidade precisa ser habilitada pelo Administrador',
          }),
      };

  return (
    <Tooltip title={page.hint || page.title} placement="right" arrow>
      <ListItem button {...props}>
        <ListItemIcon style={{ paddingLeft: '8px' }}>
          <page.icon />
        </ListItemIcon>
        <ListItemText primary={page.title} />
      </ListItem>
    </Tooltip>
  );
}

export default PageLink;
