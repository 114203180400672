import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import User from '../user.interface';

interface RemoveAssociationDialogProps {
  open: boolean;
  handleClose: () => void;
  user: User | null;
  refreshUsers: () => void;
}

function RemoveAssociationDialog(props: RemoveAssociationDialogProps) {
  const message = useMessage();

  async function removeAssociation() {
    try {
      await axiosInstance.put(API_ROUTES.USERS_UPDATE_ROLE, {
        cpf: props.user?.cpf,
      });

      message.setMessage({
        type: 'success',
        text: 'Associação removida com sucesso.',
      });

      props.refreshUsers();
      props.handleClose();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.response?.data?.message ||
          'Erro ao remover associação do usuário.',
      });
    }
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Remover associação</DialogTitle>
      <DialogContent>
        Deseja mesmo remover a associação do usuário <b>{props.user?.name} </b>
        com o profissional <b>{props.user?.association?.name}</b>?
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          Não
        </Button>
        <Button color="primary" onClick={removeAssociation}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveAssociationDialog;
