import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: '2rem',
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
  },
  statusSelect: {
    minWidth: theme.spacing(22),
  },
  prioritySelect: {
    minWidth: theme.spacing(24),
  },
}));
