import AttendancesTypes from './attendanceTypes';

function formatRows(rows: string[][], type: AttendancesTypes) {
  const formattedRows = [...rows];

  switch (type) {
    case AttendancesTypes.DaysWorked:
      return formattedRows.map((row) => {
        const [first, ...rest] = row;
        return [
          first
            .split(/<b>|<\/b>/)
            .filter((s) => s)[0]
            .toUpperCase(),
          ...rest,
        ];
      });
  }

  return formattedRows;
}

function formatHeaders(headers: string[], type: AttendancesTypes) {
  const formattedHeaders = [...headers];

  switch (type) {
    case AttendancesTypes.Procedures:
      return formattedHeaders.map((header) =>
        header
          .replace('co_proced', 'Cod. Procedimento')
          .replace('ds_proced', 'Procedimento')
          .replace('total', 'Total'),
      );
  }

  return formattedHeaders;
}

export { formatRows, formatHeaders };
