import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff, Person, Lock } from '@material-ui/icons';
import { useStyles } from './styles';
import { FormEvent, useState } from 'react';
import { REMEMBER_ME_KEY, useAuth } from '../../../context/auth';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../../components/Routes';
import maskNumber from '../../../utils/maskNumber';
import Logo from '../../../components/Logo';

function getInitialKeepLoggedState() {
  const rememberMe = localStorage.getItem(REMEMBER_ME_KEY);

  return rememberMe === 'true' || rememberMe === null;
}

function Login() {
  const classes = useStyles();
  const location = useLocation();
  const auth = useAuth();
  const history = useHistory();
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [keepLogged, setKeepLogged] = useState(getInitialKeepLoggedState());
  const [open2faModal, setOpen2faModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const cleanCPF = cpf.replace(/[.-]/g, '');
  const { from } = (location.state as any) || {
    from: { pathname: APP_ROUTES.DASHBOARD },
  };

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    setLoading(true);
    const result = await auth.login(cleanCPF, password, keepLogged);

    if (result === '2fa') {
      setOpen2faModal(true);
      setLoading(false);
    } else if (!result) {
      setLoading(false);
    }
  }

  async function handle2faLogin() {
    setLoading(true);
    const success = await auth.submitToken(cleanCPF, token, keepLogged);
    if (!success) {
      setLoading(false);
    }
  }

  function handleClose2faModal() {
    setOpen2faModal(false);
  }

  return (
    <>
      {auth.user && (
        <Redirect
          to={{
            pathname: from.pathname,
            state: { from: APP_ROUTES.LOGIN },
          }}
        />
      )}

      {loading && <LinearProgress className={classes.loadingIndicator} />}

      <Dialog open={open2faModal} onClose={handleClose2faModal}>
        <DialogTitle>Autenticação em duas etapas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite o código de autenticação que foi enviado para o seu email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={token}
            disabled={loading}
            onChange={(e) => setToken(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose2faModal}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handle2faLogin}
            disabled={token.length === 0 || loading}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Card className={classes.header}>
            <div className={classes.headerText}>
              <Typography variant="caption">Sistema de Gestão e</Typography>
              <br />
              <Typography variant="caption">
                Regulação da Saúde Pública
              </Typography>
            </div>
            <div className={classes.headerLogo}>
              <Logo mode="light" className={classes.avatar} />
            </div>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h6" className={classes.centerText}>
                Acesse sua conta
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleFormSubmit}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="CPF"
                  value={cpf}
                  disabled={loading}
                  onChange={(e) => {
                    setCpf(maskNumber(e.target.value, '###.###.###-##'));
                  }}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  disabled={loading}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={keepLogged}
                      disabled={loading}
                      onChange={(e) => {
                        setKeepLogged(e.target.checked);
                      }}
                    />
                  }
                  label="Lembrar-me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  classes={{
                    containedPrimary: classes.submitButtonPrimary,
                  }}
                >
                  ENTRAR NO SISTEMA
                </Button>
                <div className={classes.forgotPassword}>
                  <Typography>Esqueceu sua senha?</Typography>
                  <Link
                    href={APP_ROUTES.FORGOT_PASSWORD}
                    onClick={(e: any) => {
                      e.preventDefault();
                      history.push(APP_ROUTES.FORGOT_PASSWORD);
                    }}
                    variant="body2"
                  >
                    Recuperar senha
                  </Link>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default Login;
