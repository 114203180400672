import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStyles } from './CircularProgressWithLabel.styles';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  const classes = useStyles();
  // hue goes from 0(red) to 120(green)
  const ratio = Math.max(Math.min(props.value, 100), 0) / 100;
  const hue = Math.min(200 * ratio, 140) - 20;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        style={{ color: `hsl(${hue}, 100%, 50%)` }}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        variant="determinate"
        size={80}
        thickness={4}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography component="div" className={classes.value}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
