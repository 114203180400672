import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import { Establishment } from '../../../hooks/useEstablishments';

interface ValuesState {
  [x: string]: string;
}

// TODO add mask and validation
const fieldsList: { label: string; key: string }[] = [
  { label: 'CNES', key: 'cnes' },
  { label: 'Razão Social', key: 'r_social' },
  { label: 'Nome Fantasia', key: 'nome_fanta' },
  { label: 'Logradouro', key: 'logradouro' },
  { label: 'Número', key: 'numero' },
  { label: 'Complemento', key: 'complement' },
  { label: 'Bairro', key: 'bairro' },
  { label: 'CEP', key: 'cod_cep' },
  { label: 'Telefone', key: 'telefone' },
  { label: 'Fax', key: 'fax' },
  { label: 'E-mail', key: 'e_mail' },
  { label: 'CPF', key: 'cpf' },
  { label: 'CNPJ', key: 'cnpj' },
];

interface Props {
  establishment: Establishment | null;
  onClose: () => void;
  refresh: () => void;
}

function EstablishmentModal(props: Props) {
  const message = useMessage();
  const [values, setValues] = useState<ValuesState>({});
  const [geolocation, setGeolocation] = useState({
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    if (!props.establishment) {
      return;
    }

    const values: ValuesState = {};
    const keys = fieldsList.map((field) => field.key);

    Object.entries(props.establishment)
      .filter((entry) => keys.includes(entry[0]))
      .forEach((entry) => {
        values[entry[0]] = entry[1].toString();
      });

    setValues(values);
    setGeolocation(
      props.establishment.geolocation || { latitude: '', longitude: '' },
    );
  }, [props.establishment]);

  async function onSubmit() {
    if (!props.establishment) {
      return;
    }

    try {
      const url = API_ROUTES.ESTABLISHMENTS_EDIT.replace(
        ':id',
        props.establishment.id.toString(),
      );

      await axiosInstance.patch(url, { ...values, geolocation });
      message.setMessage({
        type: 'success',
        text: 'Estabelecimento atualizado com sucesso.',
      });

      props.refresh();
    } catch (error: any) {
      message.setMessage({
        type: 'error',
        text:
          error.data?.message || 'Ocorreu um erro ao salvar o estabelecimento.',
      });
    }
  }

  return (
    <Dialog open={!!props.establishment} maxWidth="lg">
      <DialogTitle>Editar Estabelecimento</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            columnGap: '16px',
            rowGap: '16px',
          }}
        >
          {fieldsList.map((field) => (
            <TextField
              key={field.key}
              variant="outlined"
              label={field.label}
              value={values[field.key] || ''}
              onChange={(e) => {
                setValues((old) => ({ ...old, [field.key]: e.target.value }));
              }}
            />
          ))}
          <TextField
            variant="outlined"
            label="Latitude"
            value={geolocation.latitude}
            onChange={(e) => {
              setGeolocation((old) => ({ ...old, latitude: e.target.value }));
            }}
          />
          <TextField
            variant="outlined"
            label="Longitude"
            value={geolocation.longitude}
            onChange={(e) => {
              setGeolocation((old) => ({ ...old, longitude: e.target.value }));
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EstablishmentModal;
