import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  filtersContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  roleFilterSelect: {
    minWidth: theme.spacing(22),
  },
  validatedFilterSelect: {
    minWidth: theme.spacing(23),
  },
}));
