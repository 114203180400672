import {
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { useState } from 'react';
import User from '../user.interface';
import { mapRole, Roles } from '../../../utils/roles';
import { API_ROUTES, axiosInstance } from '../../../api';
import { useMessage } from '../../../context/message';
import maskNumber from '../../../utils/maskNumber';

interface UsersTableRowProps {
  user: User;
  openAssociationDialog: (user: User) => void;
}

function UsersTableRow({ user, openAssociationDialog }: UsersTableRowProps) {
  const message = useMessage();

  const [role, setRole] = useState(user.role);
  const [validated, setValidated] = useState(user.validated);

  async function updateRole(role: string) {
    try {
      await axiosInstance.put(API_ROUTES.USERS_UPDATE_ROLE, {
        cpf: user.cpf,
        id_professional: user.association?.id,
        role,
      });
      setRole(role);
    } catch (error: any) {
      message.setMessage({
        text:
          error.response?.data.message ||
          'Não foi possível alterar as permissões',
        type: 'error',
      });
    }
  }

  async function updateValidated(validated: string) {
    try {
      const val = validated === 'true';
      await axiosInstance.put(
        API_ROUTES.USERS_UPDATE.replace(':id', user.id.toString()),
        {
          validated: val,
        },
      );
      setValidated(val);
    } catch (error: any) {
      message.setMessage({
        text:
          error.response?.data.message ||
          'Não foi possível salvar as alterações',
        type: 'error',
      });
    }
  }

  return (
    <TableRow>
      <TableCell>{user.name}</TableCell>
      <TableCell align="center">
        {maskNumber(user.cpf, '###.###.###-##')}
      </TableCell>
      <TableCell align="center">
        <Select
          value={role}
          onChange={(e) => {
            updateRole(e.target.value as string);
          }}
          style={{ textAlign: 'left', width: 110 }}
        >
          {Object.values(Roles).map((role) => (
            <MenuItem key={role} value={role}>
              {mapRole(role)}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="center">
        <Select
          value={validated ? 'true' : 'false'}
          onChange={(e) => {
            updateValidated(e.target.value as string);
          }}
          style={{ textAlign: 'left', width: 60 }}
        >
          <MenuItem value="true">SIM</MenuItem>
          <MenuItem value="false">NÃO</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="center">{user.email}</TableCell>
      <TableCell align="center">
        {maskNumber(
          user.phone,
          user.phone.length === 10 ? '(##) ####-####' : '(##) # ####-####',
        )}
      </TableCell>
      <TableCell>
        {user.association ? (
          <Tooltip title="Clique aqui para remover a associação desse usuário">
            <IconButton
              onClick={() => {
                openAssociationDialog(user);
              }}
            >
              <LinkOffIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Clique aqui para associar um profissional da saúde a este usuário">
            <IconButton
              onClick={() => {
                openAssociationDialog(user);
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

export default UsersTableRow;
