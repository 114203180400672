import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { useAuth, User } from '../../context/auth';
import MainLayout from '../MainLayout';
import { publicRoutes } from './publicRoutes';
import { APP_ROUTES, privateRoutes, MaxWidth, PrivateRoute } from '.';
import { useState } from 'react';

const routes = (() => {
  const routes: PrivateRoute[] = [];

  privateRoutes.forEach((route) => {
    const tmp = route.nestedPages || [route];
    routes.push(...tmp);
  });

  return routes.filter((route) => route);
})();

export function userCanAccessRoute (user: User, route: PrivateRoute) {
  if (!user) return false

  if (!route.permissions?.length) return true

  return user.permissions.some(p => route.permissions?.includes(p))
}

function Routes() {
  const auth = useAuth();
  const location = useLocation();
  const [showLayout, setShowLayout] = useState(false);
  const [maxWidth, setMaxWidth] = useState<MaxWidth>();

  const availablePrivateRoutes = routes
    .filter((route) => route.component)
    .filter((route) => userCanAccessRoute(auth.user, route));

  const defaultRoute = availablePrivateRoutes[0]?.path || APP_ROUTES.DASHBOARD;

  if (auth.user) {
    const currentRoute = routes.find(
      (route) => route.path === location.pathname,
    );

    if (currentRoute) {
      if (showLayout && currentRoute.showLayout === false) {
        setShowLayout(false);
      } else if (
        !showLayout &&
        (currentRoute.showLayout === undefined || currentRoute.showLayout)
      ) {
        setShowLayout(true);
      }

      if (currentRoute.maxWidth !== maxWidth) {
        setMaxWidth(currentRoute.maxWidth);
      }
    }
  }

  const RouterSwitch = (
    <Switch>
      {publicRoutes.map((route, idx) => (
        <Route key={idx} path={route.path} exact={route.exact}>
          <route.component />
        </Route>
      ))}

      {auth.user &&
        availablePrivateRoutes.map((route, idx) => {
          return (
            <Route key={idx} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          );
        })}

      <Route>
        <Redirect
          to={{
            pathname: auth.user ? defaultRoute : APP_ROUTES.LOGIN,
            state: { from: !auth.user ? location : undefined },
          }}
        />
      </Route>
    </Switch>
  );

  return auth.user ? (
    <MainLayout renderLayout={showLayout} maxWidth={maxWidth}>
      {RouterSwitch}
    </MainLayout>
  ) : (
    RouterSwitch
  );
}

export default Routes;
