import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TablePagination,
  TextField,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SearchIcon from '@material-ui/icons/Search';
import maskNumber from '../../../utils/maskNumber';
import { useMessage } from '../../../context/message';
import axios from 'axios';
import { API_ROUTES, axiosInstance } from '../../../api';
import {
  ImportedLocalEstablishment,
  ImportedOnlineEstablishment,
} from './importedEstablishment.interface';
import useDebounce from '../../../hooks/useDebounce';
import { useStyles } from './importedEstablishments.styles';
import LocalEstablishments from './Tables/LocalEstablishments';
import OnlineEstablishments from './Tables/OnlineEstablishments';
import PageHeader from '../../../components/PageHeader';

const rowsPerPage = 15;

export function CenteredCell({ children }: any) {
  return (
    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
      {children}
    </TableCell>
  );
}

function ImportedEstablishments() {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [competence, setCompetence] = useState('');
  const [cnesCnpj, setCnesCnpj] = useState('');
  const [nomeFanta, setNomeFanta] = useState('');
  const [importType, setImportType] = useState('local');
  const [establishments, setEstablishments] = useState<
    ImportedLocalEstablishment[] | ImportedOnlineEstablishment[]
  >([]);

  const debouncedCnesCnpj = useDebounce(cnesCnpj, 500);
  const debouncedNomeFanta = useDebounce(nomeFanta, 500);

  const message = useRef(useMessage());
  const classes = useStyles();

  useEffect(() => {
    const comp = competence.split('/').reverse().join('').replace(/[\D]/g, '');

    if (comp.length > 0 && comp.length !== 6) {
      return;
    }

    const source = axios.CancelToken.source();
    setLoading(true);
    setEstablishments([]);

    (async () => {
      const isLocal = importType === 'local';
      try {
        const response = await axiosInstance.get(
          isLocal
            ? API_ROUTES.IMPORTS_ESTABLISHMENTS
            : API_ROUTES.IMPORTS_ESTABLISHMENTS_ONLINE,
          {
            cancelToken: source.token,
            params: {
              page: page + 1,
              competence: comp || undefined,
              [isLocal ? 'nome_fanta__icontains' : 'nm_fanta_icontains']:
                debouncedNomeFanta || undefined,
              [debouncedCnesCnpj.length === 14 ? 'cnpj' : 'cnes']:
                debouncedCnesCnpj || undefined,
            },
          },
        );

        setEstablishments(response.data.results);
        setCount(response.data.count);

        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data?.message ||
            'Ocorreu um erro ao carregar a lista de estabelecimentos',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, competence, debouncedNomeFanta, debouncedCnesCnpj, importType]);

  return (
    <>
      <PageHeader title="Estabelecimentos Importados" />

      <div className={classes.filtersContainer}>
        <FormControl variant="outlined">
          <InputLabel>Tipo de Importação</InputLabel>
          <Select
            label="Tipo de Importação"
            className={classes.importTypeFilter}
            value={importType}
            onChange={(e) => setImportType(e.target.value as string)}
          >
            <MenuItem value="local">XML Local</MenuItem>
            <MenuItem value="online">XML Online</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label="Competência"
          placeholder="mm/aaaa"
          className={classes.competenceFilter}
          value={competence}
          onChange={(e) => {
            setCompetence(maskNumber(e.target.value, '##/####'));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Filtrar por CNES ou CNPJ"
          variant="outlined"
          value={cnesCnpj}
          onChange={(e) => setCnesCnpj(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Filtrar por nome fantasia"
          variant="outlined"
          value={nomeFanta}
          onChange={(e) => setNomeFanta(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      {importType === 'local' ? (
        <LocalEstablishments
          establishments={establishments as ImportedLocalEstablishment[]}
        />
      ) : (
        <OnlineEstablishments
          establishments={establishments as ImportedOnlineEstablishment[]}
        />
      )}

      {(loading || establishments.length === 0) && (
        <div className={classes.loadingContainer}>
          {loading ? <CircularProgress /> : 'Nenhum estabelecimento encontrado'}
        </div>
      )}

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default ImportedEstablishments;
