import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(1),
  },
  competenceFilter: {
    width: 180,
  },
  importTypeFilter: {
    minWidth: theme.spacing(20),
  },
  loadingContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
