import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES, axiosInstance } from '../../api';
import { useMessage } from '../../context/message';
import { mapRole, Roles } from '../../utils/roles';
import User from './user.interface';
import { useStyles } from './users.styles';
import UsersTableRow from './UsersTableRow';
import CreateUserDialog from './CreateUserDialog';
import PageHeader from '../../components/PageHeader';

const rowsPerPage = 10;

function UsersAdministration () {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rows, setRows] = useState<User[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [validatedFilter, setValidatedFilter] = useState('');
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const message = useRef(useMessage());

  function refreshUsers () {
    setRefreshCount((refresh) => refresh + 1);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);

    (async () => {
      try {
        // Checks if the input is part of a valid cpf(contains numbers, dots, and dashes)
        // And then checks if there are 11 numbers in the input
        const isFilteringByName =
          !!searchFilter.match(/[^.\-0-9]/) ||
          searchFilter.replace(/\D/g, '').length !== 11;

        const response = await axiosInstance.get(API_ROUTES.ADMINISTRATION_USERS, {
          cancelToken: source.token,
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            role: roleFilter || undefined,
            validated: validatedFilter || undefined,
            // Remove anything that is not a char if the user is filtering by cpf
            // Or set the value to undefined if it's empty
            [isFilteringByName ? 'name__icontains' : 'cpf']:
              (isFilteringByName
                ? searchFilter
                : searchFilter.replace(/\D/g, '')) || undefined,
          },
        });

        const users: User[] = response.data.results.map((data: any) => ({
          id: data.id,
          cpf: data.cpf,
          name: data.name,
          role: data.role,
          city: {
            id: data.city.id,
            name: data.city.name,
            connection_name: data.city.connection_name,
            sigus_connection_name: data.city.sigus_connection_name,
          }
        }));

        setRows(users);
        setCount(response.data.count);
        setLoading(false);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          return;
        }

        setRows([]);
        setLoading(false);

        message.current.setMessage({
          type: 'error',
          text:
            error.response?.data.detail ||
            'Ocorreu um erro ao carregar a lista de usuários.',
        });
      }
    })();

    return () => {
      source.cancel();
    };
  }, [page, searchFilter, roleFilter, refreshCount, validatedFilter]);

  return (
    <>
      <CreateUserDialog
        open={createUserOpen}
        onClose={() => {
          setCreateUserOpen(false);
          refreshUsers();
        }}
      />

      <PageHeader title="Usuários" />

      <div className={classes.actionsContainer}>
        <div className={classes.filtersContainer}>
          <TextField
            label="Filtrar por nome ou CPF"
            variant="outlined"
            value={searchFilter}
            onChange={(e) => {
              setPage(0);
              setSearchFilter(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl variant="outlined">
            <InputLabel id="role-filter-label">Filtrar por função</InputLabel>
            <Select
              labelId="role-filter-label"
              label="Filtrar por função"
              value={roleFilter}
              onChange={(e) => {
                setPage(0);
                setRoleFilter(e.target.value as string);
              }}
              className={classes.roleFilterSelect}
            >
              <MenuItem value="">Nenhuma</MenuItem>
              {Object.values(Roles).map((role) => (
                <MenuItem key={role} value={role}>
                  {mapRole(role)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel>Filtrar por validade</InputLabel>
            <Select
              label="Filtrar por validade"
              value={validatedFilter}
              onChange={(e) => {
                setPage(0);
                setValidatedFilter(e.target.value as string);
              }}
              className={classes.validatedFilterSelect}
            >
              <MenuItem value="">Nenhum</MenuItem>
              <MenuItem value="true">Validado</MenuItem>
              <MenuItem value="false">Não validado</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Button
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setCreateUserOpen(true);
          }}
        >
          Novo Usuário
        </Button>
      </div>

      {/* TODO fix the width of the cells */}
      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100vh - 280px)' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">CPF</TableCell>
              <TableCell align="center">Permissão</TableCell>
              <TableCell align="center">ID Cidade</TableCell>
              <TableCell align="center">Nome Cidade</TableCell>
              <TableCell align="center">Conexão ESUS</TableCell>
              <TableCell align="center">Conexão SIGUS</TableCell>
              <TableCell style={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((user) => (
                <UsersTableRow
                  key={user.id}
                  user={user}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body2">
                    Nenhum usuário encontrado
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e: any, page: number) => {
          setPage(page);
        }}
      />
    </>
  );
}

export default UsersAdministration;
