import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  passwordRequirements: {
    '& li': {
      color: theme.palette.text.hint,
    },
  },
}));
