import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appBarSpacer: { ...theme.mixins.toolbar },
  drawer: {
    flexShrink: 0,
    width: theme.spacing(38),
    whiteSpace: 'nowrap',
    position: 'relative',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  cityLogoSpacer: {
    height: '100%',
  },
  cityLogoContainer: {
    marginBottom: 24,
    marginTop: 24,
    minWidth: 72,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cityLogo: {
    objectFit: 'contain',
    width: 60,
    height: 32,
  },
}));
